import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import reduxReset from 'redux-reset'
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import UsersReducer from './reducers/UsersReducer';
import alertReducer from './reducers/alertReducer';
import ClientReducer from './reducers/ClientReducer';
import NoteReducer from './reducers/NoteReducer';
import RoomReducer from './reducers/RoomReducer';
import ServiceReducer from './reducers/ServiceReducer';
import InvoiceReducer from './reducers/InvoiceReducer';
import profileReducer from './reducers/profileReducer';
import postReducer from './reducers/postReducer';
import statesLGAReducer from './reducers/statesLGAReducer';


//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
		todoReducers,
		alertReducer,
        ClientReducer,
        UsersReducer,
        NoteReducer,
        RoomReducer,
        ServiceReducer,
        InvoiceReducer,  
        profileReducer,
        postReducer,
        statesLGAReducer,
	//form: reduxFormReducer,	
	
});

//const store = createStore(rootReducers);

export const store = createStore(reducers,  composeEnhancers(middleware));

import axiosInstance from './AxiosInstance';

export function getInvoices() {
    return axiosInstance.get(`/invoices`);
}
export function getInvoice(invoiceId) {
    return axiosInstance.get(`invoices/${invoiceId}`);
}

export function createInvoice(invoiceData) {
    return axiosInstance.post(`/invoices`, invoiceData);
}

export function updateInvoice(invoiceData) {
    return axiosInstance.put(`/invoices`, invoiceData);
}

export function updateInvoiceName(invoiceData) {
    return axiosInstance.patch(`/invoices/invoicenames`, invoiceData);
}

export function deleteInvoice(invoiceId) {
    return axiosInstance.delete(`/invoices/invoicenames/${invoiceId}`);
}

export function formatUsers(usersData) {
    let users = [];
    for (let key in usersData) {
        users.push({ ...usersData[key], id: key });
        console.log(users);
    }

    return users;
}

import React, { Fragment } from "react";

const StepTwo = ({
      formData,
      onChange,
     

}) => {

   return (
      <section>
    
    <div className="row">   

        <br />

        <div className="col-lg-4 mb-2">
          <div className="form-group mb-3">
                    

												<label className="text-black font-w500">Purpose of Credit</label>
													<div className="contact-name">
												<select name="purpose" value={formData.purpose} className="form-control" onChange={onChange}  >
												<option value="">* Select Purpose</option>
												
												<option  value='validate'>To Validate Phone</option>
												<option  value='declare'>To Declare Phone Missing</option>
	
         									 </select>
													<span className="validation-text"></span>
  {/* <small className="form-text">
               Enter Purpose of credit
             </small> */}
                          
											
											</div>

          </div>
             
          <div className="form-group mb-3">
        	<label className="text-black font-w500">Enter Number of unit to buy</label>
													<div className="contact-name">

                              <input
            type="number"  className="form-control"
            placeholder="Enter Number of unit to buy"
            name="unit"
            value={formData.unit}
            onChange={onChange}
            autoComplete="No"
          />
            {/* <small className="form-text">
            Enter Number of unit to buy
             </small> */}
                          </div>
        
             
             </div>



        <div className="form-group mb-3">
        <label className="text-black font-w500"> Credit Amount</label>
        <div className="contact-name">
            <input
              type="text"  className="form-control"
              placeholder="Credit Amount"
              name="credit"
              value={formData.credit}
             
              readOnly
            />
          <small className="form-text">
            Credit Amount
          </small>
          </div>
        </div>
      
        

    

        <div className="form-group mb-3">
       
          {/* <input
            type="password"  className="form-control"
            placeholder="Shop Owner Password"
            name="password"
            value={formData.password}
            onChange={onChange}
          />
           <small className="form-text">
             Is this Transfer by the Shop Owner then, Password to be sure?
             </small> */}
             
             </div>

        <div className="form-group mb-3">
        {/* <label className="text-black font-w500"> Amount to Pay: {amountToPay}</label> */}
          {/* <input
            type="password"  className="form-control"
            placeholder="Shop Owner Password"
            name="password"
            value={formData.password}
            onChange={onChange}
          />
           <small className="form-text">
             Is this Transfer by the Shop Owner then, Password to be sure?
             </small> */}
             
             </div>

       
             </div>
   
      
     </div>

      
  </section>

   );
};

export default StepTwo;

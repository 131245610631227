
import {
    CONFIRMED_CREATE_CLIENT_ACTION,
    CONFIRMED_CREATE_CLIENT_ITEM_ACTION,
    CONFIRMED_DELETE_CLIENT_ACTION,
    CONFIRMED_EDIT_CLIENT_ACTION,
    CONFIRMED_GET_CLIENTS,
    CONFIRMED_GET_CLIENT,
    CLIENT_ERROR
} from '../actions/ClientType';
const initialData = {
	list :null,
	lists : [],
	errorMessage: '',
    successMessage: '',
    showLoading: true,
}
const clientReducer = (state = initialData, action) =>{

	switch(action.type){
		case CONFIRMED_EDIT_CLIENT_ACTION:

        const listscopy = [...state.lists];
        const tIndex = listscopy.findIndex(
            (list) => list._id === action.payload._id,
        );

        listscopy[tIndex] = action.payload;
        return {
            ...state,
            lists:listscopy,
        };


		case CONFIRMED_GET_CLIENT : 
		
		return{
	    	...state,
			list: action.payload,
			showLoading: false,
		}

		case CONFIRMED_GET_CLIENTS : 

		return {
            ...state,
            lists: action.payload,
            // activeUsers: (actions.payload).filter((user) => user.active),
            // inActiveUsers: (actions.payload).filter((user) => !user.active),
            showLoading: false,
        };

		case CONFIRMED_DELETE_CLIENT_ACTION : 

		 const lists = [...state.lists];
        const listIndex = lists.findIndex(
            (list) => list.id === action.payload,
        );

        lists.splice(listIndex, 1);

        return {
            ...state,
            lists
        };

		case CONFIRMED_CREATE_CLIENT_ACTION: 

		 const newlists = [...state.lists];
         newlists.push(action.payload);

        return {
            ...state,
            lists:newlists,
            showLoading: false, 
        };
     
		// case "REMOVE_CLIENT" :
        //      return{
		// 	...state,
		// 	list: null
		// }
		case "CLIENT_ERROR" : 
        
        return {
            ...state,
            errorMessage: action.payload,
        };
		default: return state;
           
	}
}
export default clientReducer;
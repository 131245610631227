
import {
    CONFIRMED_CREATE_SERVICE_ACTION,
    CONFIRMED_CREATE_SERVICE_ITEM_ACTION,
    CONFIRMED_DELETE_SERVICE_ACTION,
    CONFIRMED_EDIT_SERVICE_ACTION,
    CONFIRMED_GET_SERVICES,
    CONFIRMED_GET_SERVICE,
    LOADING_TOGGLE_ACTION
} from '../actions/ServiceType';
const initialData = {
	list :null,
	lists : [],
	errorMessage: '',
    successMessage: '',
    showLoading: true,
}
const ServiceReducer = (state = initialData, action) =>{

	switch(action.type){
		case CONFIRMED_EDIT_SERVICE_ACTION:
		case CONFIRMED_DELETE_SERVICE_ACTION:
		case CONFIRMED_GET_SERVICE : 
		case CONFIRMED_CREATE_SERVICE_ITEM_ACTION : 
	
		return{
	    	...state,
			list: action.payload,
			showLoading: false,
		}

		case CONFIRMED_GET_SERVICES : 

		return {
            ...state,
            lists: action.payload,
            // activeUsers: (actions.payload).filter((user) => user.active),
            // inActiveUsers: (actions.payload).filter((user) => !user.active),
            showLoading: false,
        };

		case CONFIRMED_CREATE_SERVICE_ACTION: 

		  const lists = [...state.lists];
        lists.push(action.payload);

        return {
            ...state,
            lists,
            showLoading: false, 
        };
     
		case "REMOVE_SERVICE" : return{
			...state,
			list: null
		}
		default: return state;
           
	}
}
export default ServiceReducer;
import axios from "axios";
import { store } from '../store/store';

let apicall = ''



if(process.env.REACT_APP_ENV === 'development') {
  apicall = process.env.REACT_APP_ENV_API_LC
}else{
  apicall = process.env.REACT_APP_ENV_API
}


const api = axios.create({
  baseURL: apicall,
  headers: { "Content-Type": "application/json" },
});


api.interceptors.request.use((config) => {
   const state = store.getState();
   const token = state.auth.auth.idToken;
   // config.params = config.params || {};
   // config.params['auth'] = token;
  // console.log(config.params['auth']);

   if (token) {
       config.headers['Authorization'] = `Bearer ${token}`;
   }

   return config;
});


//api.interceptors.response.use(res=>res,err=>{})

// api.interceptors.request.use((config) => {
//   const state = store.getState();
//   const token = state.authReducer.token;
//   // config.params = config.params || {};
//   // config.params['auth'] = token;
//  // console.log(config.params['auth']);

//   if (token) {
//       config.headers['Authorization'] = `${token}`;
//   }

//   return config;
// });


export default api;

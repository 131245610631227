import axios from 'axios';
import { store } from '../store/store';

let api = ''

if(process.env.REACT_APP_ENV === 'development') {
   api = process.env.REACT_APP_ENV_API_LC
}else{
  api = process.env.REACT_APP_ENV_API
}

const axiosInstance = axios.create({
    baseURL: api,
   // baseURL: `http://localhost:5005`,
    
});

axiosInstance.interceptors.request.use((config) => {
    const state = store.getState();
    const token = state.auth.auth.idToken;
    // config.params = config.params || {};
    // config.params['auth'] = token;
   // console.log(config.params['auth']);

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
});

export default axiosInstance;

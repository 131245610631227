import React from 'react';

const Editable = ({editFormData, onActiveChanged,handleEditFormChange, handleCancelClick}) =>{
    return(
        <>
            <>
			
                 <td>
                    <input type="text" required = "required" placeholder = "Enter Guest purpose of visit..." name="text" 
                        value={editFormData.text}
                        onChange={handleEditFormChange}
                    />
                </td>   
                
                <td>
                    <input type="text" required = "required" placeholder = "Enter Intended Services ..." name="services" 
                        value={editFormData.services}
                        onChange={handleEditFormChange}
                    />
                </td>
                <td><input
                      type="checkbox"
                      // defaultChecked
                      className="form-check-input"
                    
                      name="completed"
                      value={editFormData.completed}
                      checked ={ editFormData.completed && 'checked' }
                      // onChange={(e) =>
                      //   setActive(e.target.value)
                      // }
                      onChange={onActiveChanged}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="active"
                    >
                      Closed?
                    </label></td>
                
                {/* <td>
                    <input type="text" required = "required" placeholder = "Enter Enter Surname ..." name="cust_sname" 
                        value={editFormData.cust_sname}
                        onChange={handleEditFormChange}
                    />
                </td>   
                
                <td>
                    <input type="text" required = "required" placeholder = "Enter Firstname ..." name="cust_fname" 
                        value={editFormData.cust_fname}
                        onChange={handleEditFormChange}
                    />
                </td>

                <td>
                    <input type="text" required = "required" placeholder = "Enter Phone ..." name="cust_phone" 
                        value={editFormData.cust_phone}
                        onChange={handleEditFormChange}
                    />
                </td>
                <td>
                    <input type="text" required = "required" placeholder = "Enter Emailc ..." name="cust_email" 
                        value={editFormData.cust_email}
                        onChange={handleEditFormChange}
                    />
                </td>
                <td>
                    <input type="text" required = "required" placeholder = "Enter Address ..." name="cust_address" 
                        value={editFormData.cust_address}
                        onChange={handleEditFormChange}
                    />
                </td> */}
              
                <td>	
					<div className="d-flex">
						<button className="btn btn-warning shadow btn-xs sharp me-1" type="submit"><i className="las la-check-circle scale5"></i></button>
						<button className="btn btn-danger shadow btn-xs sharp " type="button" onClick={handleCancelClick}>
							<i className="las la-times-circle scale5"></i>
						</button>
					</div>
                </td>
            </>
        </>
    )
}
export default Editable;
import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';

export const COLUMNS = [
	
	{
		Header : 'Name',
		Footer : 'Name',
		accessor: 'yourname',
		Filter: ColumnFilter,
	},
	{
		Header : 'sex',
		Footer : 'sex',
		accessor: 'sex',
		Filter: ColumnFilter,
	},
	{
		Header : 'Country',
		Footer : 'Country',
		accessor: 'country',
		Filter: ColumnFilter,
	},
	{
		Header : 'State',
		Footer : 'State',
		accessor: 'state',
		Filter: ColumnFilter,
	},{
		Header : 'Local Govt',
		Footer : 'Local Govt',
		accessor: 'lga',
		Filter: ColumnFilter,
	},{
		Header : 'Phone',
		Footer : 'Phone',
		accessor: 'phoneno',
		Filter: ColumnFilter,
	},
	{
		Header : 'Date Registered',
		Footer : 'Date Registered',
		accessor: 'date',
		Cell: ({ value }) => {return format(new Date(value), 'do MMM, yyyy')},
		Filter: ColumnFilter,
	},{
		Header : 'Profile Status',
		Footer : 'Profile Status',
		accessor: 'profileStatus',
		Filter: ColumnFilter,
		disableFilters: false,
	},
	
	
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]
import {
    createNote,
    getNote,
    getNotes,
    getGuestByPhone,
    updateNote,
    updateNoteName,
    deleteNote,
    getOpenNoteClients
} from '../../services/NoteApi';
import {
    CONFIRMED_CREATE_NOTE_ACTION,
    CONFIRMED_CREATE_NOTE_ITEM_ACTION,
    CONFIRMED_DELETE_NOTE_ACTION,
    CONFIRMED_EDIT_NOTE_ACTION,
    CONFIRMED_GET_NOTES,
    CONFIRMED_GET_NOTE,
    NOTE_ERROR,
    CONFIRMED_GET_GUEST_BY_PHONE,
    CONFIRMED_GET_NOTE_CLIENTS,
    NOTE_CLEAR
} from './NoteType';

import {
    setAlert 
    } from './alertAction';




export function deleteNoteAction(noteId, history) {
    return (dispatch, getState) => {
        deleteNote(noteId).then((response) => {
            dispatch(confirmedDeletetNoteAction(noteId));
            //history.push('/Notes');
        });
    };
}

export function confirmedDeletetNoteAction(noteId) {
    return {
        type: CONFIRMED_DELETE_NOTE_ACTION,
        payload: noteId,
    };
}




export function createNoteAction(noteData) {
 
	return (dispatch, getState) => {
        try {
            console.log(noteData);
            createNote(noteData).then((response) => {


              dispatch(
                setAlert('note Created', 'success')
              );

                dispatch(confirmedCreateNoteAction(response.data));

            }).catch(function (error) { 
                
                dispatch(
                setAlert(error.response.data.errors[0].msg, 'error') 
                );
                
                dispatch({
                    type: NOTE_ERROR,
                    payload: { msg: error.response.statusText, status: error.response.status }
                  });
            
            });
            
        } catch (err) {
            dispatch({
                type: NOTE_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
              });
              dispatch(
                setAlert(err.response.statusText, 'error')
              );

       }
       
    };
}
export function createNoteItemAction(noteData) {
 
	return (dispatch, getState) => {
        try {
        
           updateNoteName(noteData).then((response) => {


              dispatch(
                setAlert('Note Item Created', 'success')
              );

                dispatch(confirmedCreateNoteItemAction(response.data));
            });
            
        } catch (err) {
            dispatch({
                type: NOTE_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
              });
             

        }
       
    };
}

export function getNotesAction() {
    return (dispatch, getState) => {
        getNotes().then((response) => {
      
        
            dispatch(confirmedGetNotesAction(response.data));
          
        });
    };
}

export function getCurrentNoteAction(noteId) {
    return (dispatch, getState) => {
        getNote(noteId).then((response) => {

            dispatch(confirmedGetNoteAction(response.data));
          
        });
    };
}
export function getGuestByPhoneAction(phone) {
    return (dispatch, getState) => {
       
        dispatch({
            type: NOTE_CLEAR
          });
        getGuestByPhone(phone).then((response) => {

          
            dispatch(confirmedGetGuestByPhoneAction(response.data));
           
            dispatch({
                type: NOTE_ERROR,
                payload: { msg: 'success', status: 200 }
              });
          
        }).catch(function (error) { 
                
            // dispatch(
            // setAlert(error.response.data.errors[0].msg, 'error') 
            // );
            
            dispatch({
                type: NOTE_ERROR,
                payload: { msg: error.response.statusText, status: error.response.status }
              });
        
        })
        ;
    };
}
export function getOpenNoteClientsAction() {
    return (dispatch, getState) => {

            getOpenNoteClients().then((response) => {
            dispatch(confirmedGetOpenNoteClientsAction(response.data));
 
        }).catch(function (error) { 
                
            dispatch(
            setAlert(error.response.data.errors[0].msg, 'error') 
            );
            
            dispatch({
                type: NOTE_ERROR,
                payload: { msg: error.response.statusText, status: error.response.status }
              });
        
        })
        ;
    };
}

export function confirmedCreateNoteAction(singleNote) {
	
    return {
        type: CONFIRMED_CREATE_NOTE_ACTION,
        payload: singleNote,
    };
}

export function confirmedCreateNoteItemAction(singleNote) {
	
    return {
        type: CONFIRMED_CREATE_NOTE_ITEM_ACTION,
        payload: singleNote,
    };
}

export function confirmedGetNotesAction(notes) {
    return {
        type: CONFIRMED_GET_NOTES,
        payload: notes,
    };
}

export function confirmedGetNoteAction(note) {
    return {
        type: CONFIRMED_GET_NOTE,
        payload: note,
    };
}

export function confirmedGetGuestByPhoneAction(guestData) {
    return {
        type: CONFIRMED_GET_GUEST_BY_PHONE,
        payload: guestData,
    };
}
export function confirmedGetOpenNoteClientsAction(openNoteClientsData) {
    return {
        type: CONFIRMED_GET_NOTE_CLIENTS,
        payload: openNoteClientsData,
    };
}

export function confirmedUpdateNoteAction(note) {

    return {
        type: CONFIRMED_EDIT_NOTE_ACTION,
        payload: note,
    };
}

export function updateNoteAction(note, history) {
    return (dispatch, getState) => {
        updateNote(note).then((response) => {
            dispatch(confirmedUpdateNoteAction(response.data));
            dispatch(
                setAlert('Note Status Updated Successfully', 'success')
              );
        });
			
    };
}
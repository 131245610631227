import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate}  from "react-router-dom";


import swal from "sweetalert";
import PageTitle from "../layouts/PageTitle";
import pic1 from './../../images/profile/small/pic1.jpg';

import { connect } from 'react-redux';

  import PropTypes from "prop-types";
  import Spinner from '../layouts/Spinner';
  //import useAuth from '../../config/hooks/useAuth';
  import {
	deletePhoneAction,
	getCurrentProfile,
	
  } from "../../store/actions/profileAction";


  const initialState ={
	
	password: '',
	phoneid: '',
	prof_id:null


}
const DeletePhone = ({
	
	deletePhoneAction,
	profile: { profile, loading },
	
	getCurrentProfile,

  
  }) =>{
	

	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialState);

	// delete data  
    const handleDelete = () => {

        swal({
			title: "Are you sure?",
			text:
			  "Once deleted, the details of the deleted phone changes to unregistered.",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {
			 
			  deletePhoneAction(formData, navigate).then((res) => {
				if(res !=='Invalid Credentials'){
						swal("Phone has been deleted!", {
					icon: "success",
					});
					navigate("/my-phones")
				}else{
					swal("Invalid Credentials!");
				}
				
			});
			} else {
			  swal("Phone is not deleted!");
			}
		  })

    }
	
	const {	password,	phoneid} = formData;
	let errorsObj = {
		phoneid:'',
		password:'',
	   };

	useEffect(() => {
		// if there is no profile, attempt to fetch one
		!profile && getCurrentProfile();
		if(profile && !loading ) setFormData({ ...formData, prof_id: profile._id });
	  }, [loading, profile, getCurrentProfile]);

	  const [errors, setErrors] = useState(errorsObj);
	  const onChange = (e) =>
	  setFormData({ ...formData, [e.target.name]: e.target.value });
	  
   
    
     //Add Submit data
    const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
       
		 if (phoneid === '') {
            errorObj.phoneid = 'Phone is Required';
            error = true;
			swal('Oops', errorObj.phoneid, "error");
        }
		 if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
			swal('Oops', errorObj.password, "error");
        }
		

        setErrors(errorObj);
        if (error) return;
		handleDelete()
    };
	

	return(
		<>
			{/* <PageTitle activeMenu="" motherMenu="Services" /> */}
		
				
			<div className="col-12">
					<div className='col-lg-4 mb-2'>
							<button className="btn btn-warning" onClick={() => navigate(-1)}>go back</button>
					</div>
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">Delete Phone</h4>
					</div>
					<div className="card-body">
					
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Delete Phone from M-Right Security</h4>
									
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											
											<div className="form-group mb-3">
												<label className="text-black font-w500">Phone</label>
												<div className="contact-name">
												<select name="phoneid" value={formData.phoneid} className="form-control" onChange={onChange}  >
												<option>* Select Phone</option>
													{ 
									
									
											   profile?.phone_detail  && profile?.phone_detail?.map(
												({
												_id,
												phoneName,
												phonesno,
												phoneStatus
													}) => (
												<option key={_id} value={_id}>{`${phoneName}- ${phoneStatus}\n Sno.:${phonesno}`}</option>
												))
										
													} 

										
          
         									 </select>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Login Password</label>
												<div className="contact-name">
													<input type="password"  className="form-control"  autoComplete="off"
														name="password" required="required"
														onChange={onChange}
														placeholder="Password is required"
													/>
													
													<span className="validation-text"></span>
												</div>
											</div>
											
											
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="submit" className="btn btn-secondary" onClick={handleAddFormSubmit}>Delete</button>   
									<button type="button" className="btn btn-warning" onClick={() => navigate(-1)}> <i className="flaticon-delete-1"></i> Discard</button>   
								</div>
							</form>
							
						</div>
					</div>
			
					</div>
				</div>
			</div>
		</>
	)
}


DeletePhone.propTypes = {
	deletePhoneAction: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	
	
  };
  const mapStateToProps = (state) => ({
	profile: state.profileReducer,



  });
  const mapDispatchToProps = { deletePhoneAction,getCurrentProfile };
  
  export default connect(mapStateToProps, mapDispatchToProps)(
	DeletePhone
  );
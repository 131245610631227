import React, {useEffect, useState} from "react";
import {Link,useNavigate}  from "react-router-dom";
import DownloadPage from "./DownloadContent";
import { connect, useDispatch } from 'react-redux';
import PropTypes from "prop-types";
import {Modal, Button} from 'react-bootstrap';
import swal from "sweetalert";

import {

	getCurrentProfile,
   changeTnc
	
  } from "../../store/actions/profileAction";

  const initialState = {
		tnc: true,
	  };

const Error404 = ({
	name,
	email,
   profile,
	getCurrentProfile,
   changeTnc

  
  }) => {

   const [addCard, setAddCard] = useState(false);
   const [formData, setFormData] = useState(initialState);
   const navigate = useNavigate();
   const {	tnc } = formData;
   let errorsObj = {
		tnc:''
	   };
      const [errors, setErrors] = useState(errorsObj);
      const [regStatusValied, setRegStatusValid] = useState({});

   useEffect(() => {
      getCurrentProfile();
    }, []);


   useEffect(()=>{
    let  phone_ = {}
         if (profile.tnc_download) setAddCard(true)
            if (profile){
               phone_ = profile?.phone_detail.find((element) => {
               return element.regStatus === true;
               })

               setRegStatusValid(phone_)
            }
               

   }, [profile])


   function handleTnc() {
     
      changeTnc(formData).then(() =>  ''	);

   }

   const handleAddFormSubmit = (e)=> {
      e.preventDefault();
      let error = false;
      alert('yes')
       const errorObj = { ...errorsObj };
     

      if (!tnc) {
            errorObj.tnc = 'Terms & Conditions is Required';
            error = true;
         swal('Oops', errorObj.tnc, "error");
       }
    

      setErrors(errorObj);
      if (error) return;

      handleTnc()
    
 
    
  };

  const ontncChanged = () => 
	{
	  	  setFormData({ ...formData, tnc:formData.tnc?false:true});
	}

   const recheck = async()=>{

		const phone_ = profile?.phone_detail.find((element) => {
		  return element.regStatus === true;
		  })
		  return phone_
		//   if (phone_.regStatus) return true
      //   else return false
       

	  
	  }
	  const alert = async()=>{
	
				navigate('/phone-validate')
		
			
		
	  }

   return (
      <div className="authincation">
         <div className="container">
         <Modal className="modal fade"  show={addCard} onHide={setAddCard} size='lg'>
					<div className="" role="document">
						<div className="">
							<form onSubmit={handleAddFormSubmit}>
								<div className="modal-header">
									<h4 className="modal-title fs-20">TERMS AND CONDITIONS</h4>
									<button type="button" className="btn-close" onClick={()=> setAddCard(false)} data-dismiss="modal"><span></span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											<p><strong>
                                 1. The best solution for phone stealing is to prevent the chances of happening in the first place. M-right Digitaltech  centered it's solution on prevention first, then recoverability effort.<br /><br />
 2. Study revealed that 65% of phones stolen in Nigeria are in open places such as wedding, market, public transport, religion gathering etc. 25% in places such as office, home, restaurant. The remaining 10% happens due to misplacement, robbery or other destined natural circumstances.<br /><br />

3. Conventional tracking method yield little or no result despite its  unprepared costly expenses. The chances of recovery through tracking is still low due to limitations of manufacturers that give room for resetting, formatting, changing of EMEI number etc.<br /><br />
4. M-right designed its System to prevent stealing by 80% first, if stolen, track it by yourself instantly. If all the above 2 efforts persist, even after the thief reset or format it, there is still chances of detecting it by our system on process of selling to others. It’s very simple to use M-right phone Anti theft.<br /><br /> 

5. Users can go to www.mright.com.ng to create account and add their phones’ details, install the preventive apps and follow the configurations setting guidelines in the site. Seek assistance of Mright phone shop agents for accuracy. In case if the phone is stolen, quickly login to M-right user account to declare the particular phone missing, and or  track it instantly in the site. The detection of stolen phone happens on the process of selling the phone to others. <br /><br />

6. The logic is, M-right already mobilized fairly used phone buying public to search phone they want to buy in www.mright.com.ng, using the phone’s  serial number that displays when user dial *#06# in every phone.  This is to know it's status so as not to buy crime implicated phones. On process of searching, the system detects stolen phones that already declared missing to alert the actual owner via email. The email alert contains vital information for easy and amicable recovery.<br /><br />

7. Now that a stolen phone could be detected. The motive of  thieves to steal and sell to get money will be reduced to minimum with the help of this system.<br /><br />
 8. When dialed *#06#, there are some phones that display Emei number instead of the serial number, in either case, M-right system uses the last string of digits to search or register.  The phone serial number could not be changed often and even if changed, it would be reversed when the phone is restarted. In any case, it would still be detected. <br /><br />
 9. The disassembled stolen phones to sell in pieces is also subject to detection with the help of our partner AMPAT (National GSM Markets Association) as the serial numbers are there written on the hardare battery, sim slot, motherboard etc depending on the phone type. With Mright, reset, flashed, changed EMEI or formatted phones might not have gone finally but its just a matter of time.
 <br /><br /> 10. This download page you are  about to access containing full of updated and researched apps, files, tips and information to protect or minimize chances of phone stealing or speeding up recovery if stolen. Remember! Every phone has different version or make. Sharing the directly downloaded files or referred apps with other phone may not WORK accurately as the System uses the phone version and make to refer for relevant apps to downloads.
 <br /><br />
	ATTENTION!
NOTE that M-right is only referring you, the M-right portal  user/member  to the  researched and tested resources in good faith. 
<br /><br />
Therefore M-right WILL not be liable  for any contrary behaviour, data privacy, security issues, financial transaction issues or other related matters that may turn to problem with third party apps or sites as their apps, sites, updates and mode of operations are changing over time. 
<br /><br />It is users’ responsibility to check third party’s terms and conditions, data privacy policy etc in the referred apps or sites in consideration the sensitivity of data or information content of user’s device before installing or granting different permissions to the different apps or sites referred to you in this portal as M-right will not be liable for user negligence that may turn to problem.
<br /><br />
By clicking the below check box, you read the information under every referred link and  agree with the Mright’s terms and conditions, the terms in this page and other relevant linking pages.


                                 </strong></p>
											<div className="form-group mb-3">
												<label className="text-black font-w500"></label>
												<div className="contact-name">
                                    <div className="form-group ">
              
              <input
                  type="checkbox"
                 
                  className="form-check-input p-4 "
                  id="customCheckBox9"
                  required='required'

                  name={formData.tnc}
                  value={formData.tnc}
                  checked ={ formData.tnc && 'checked' }
                  // onChange={(e) =>
                  //   setActive(e.target.value)
                  // }
                  onChange={ontncChanged}


                />
                <label
                  className="form-check-label"
                  htmlFor="active"
                >
                 <h4><strong> <Button
              variant="primary"
              className=" p-3"
              onClick={''}
            >
              Terms & Conditions
            </Button></strong> </h4>
                </label>
              </div>
                                    </div>
											</div>
										
										
											
										</div>
									</div>
								</div>
								 <div className="modal-footer">
									<button type="submit" className="btn btn-warning" disabled={!formData.tnc} >Submit</button>   
									<button type="button" onClick={()=> setAddCard(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
								</div> 
							</form>
							
						</div>
					</div>
				</Modal>
            
               <div className="col-md-7">
                  <div className="form-input-content text-center error-page">
                   

                    { (!addCard && !profile.tnc_download ) && regStatusValied? <button className="btn btn-warning" onClick={()=> setAddCard(true)}>READ TERMS AND CONDITIONS {`${regStatusValied?'yes':'no'}`}</button> :<button className="btn btn-warning" onClick={alert}><strong>CLICK HERE TO VALIDATE AT LEAST ONE ADDED PHONE</strong></button> }
                    {/* { !addCard && (profile.tnc_download? <DownloadPage></DownloadPage>: <button className="btn btn-warning" onClick={()=> setAddCard(true)}>READ TERMS AND CONDITIONS</button>) } */}
                  </div>
               
            </div>
         </div>
      </div>
   );
};

Error404.propTypes = {

	getCurrentProfile: PropTypes.func.isRequired,
	changeTnc: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,

  };

const mapStateToProps = (state) => ({
 
     name: state.auth.auth.name,
     email: state.auth.auth.email,
   
    profile: state.profileReducer.profile
    });
    const mapDispatchToProps = { getCurrentProfile, changeTnc};
    export default connect(mapStateToProps, mapDispatchToProps)(Error404);



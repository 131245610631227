import React, { Fragment } from "react";

const StepTwo = ({
      formData,
      onChange,
      onReferredChanged,
      nokOption,
      setNokOption,
      displayPhoneInputs,
      togglePhoneInputs

}) => {

   return (
      <section>
    
    <div className="row">   
     <div className="my-2">
          <button
            onClick={() => togglePhoneInputs(!displayPhoneInputs)}
            type="button"
            className="btn btn-dark"
          >
            Phone Details
          </button>
          <span className="mx-4">Optional</span>
        </div>
        <br />
        <div className="col-lg-12 mb-3  mt-4 form-check custom-checkbox  checkbox-success">
              <div className="form-group ">
                    {/* <input
                      type="checkbox"
                    
                      className="form-check-input"
                      id="active"
                      name={formData.referred}
                      value={formData.referred}
                      checked ={ formData.referred && 'checked' }
                     
                      onChange={onReferredChanged}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="active"
                    >
                     <h4><strong>Have referral no? </strong> </h4>
                    </label> */}
                  </div>
                  </div>
        {formData.referred &&  (  
          <Fragment>
            {/* <div className="col-lg-4 mb-2"><div className="form-group mb-3">
         
                <input
                  type="text"  className="form-control"
                  placeholder="Referral no."
                  name="referralno"
                  value={formData.referralno}
                  onChange={onChange}
                />
                <small className="form-text">
                  Referral no is required, only if you are reffered by someone to the platform
                  </small>
            </div></div> */}
             </Fragment>
             )}
  
        {/* <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          <i className="fab fa-phone fa-2x" />
          <input
            type="text"  className="form-control"
            placeholder="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={onChange}
          />


          
           <small className="form-text">
             Add phone number used as a username. Once registered can only be changed at a fee
         </small></div></div> */}
        {/* <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          <i className="fab fa-phone fa-2x" />
          <input
            type="text"  className="form-control"
            placeholder="Phone Model"
            name="phoneModel"
            value={formData.phoneModel}
            onChange={onChange}
          />
           <small className="form-text">
             Add phone model e.g Samsung Galaxy
             </small></div></div> */}

        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-facebook fa-2x" /> */}
          <input
            type="text"  className="form-control"
            placeholder="Phone Name"
            name="phoneName"
            value={formData.phoneName}
            onChange={onChange}
          />
  <small className="form-text">
             Add Phone name and version no e.g Infinix smart 5
             </small></div></div>
<div className="col-lg-4 mb-2"><div className="form-group mb-3">
        
        <input
          type="text"  className="form-control"
          placeholder="Phone color"
          name="phonecolor"
          value={formData.phonecolor}
          onChange={onChange}
        />
         <small className="form-text">
         Phone color
         </small>
      </div>
      </div>
        

             <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-youtube fa-2x" /> 
          <input
            type="text"  className="form-control"
            placeholder="Phone condition e.g new or old"
            name="phonecondition"
            value={formData.phonecodition}
            onChange={onChange}
          />*/}
            <select name="phonecondition"  value={formData.phonecondition}  onChange={onChange} className="form-control">
    <option value=''>Select Phone Condition</option>
    <option value='NEW'>NEW</option>
    <option value='OLD'>OLD</option>

  
    
    </select>
          <small className="form-text">
             Add phone condition e.g Old or new phone
             </small></div></div>


             {/* <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          <i className="fab fa-youtube fa-2x" />
          <input
            type="text"  className="form-control"
            placeholder="Phone status"
            name="phoneStatus"
            value={formData.phoneStatus}
            onChange={onChange}
          />
          <small className="form-text">
             Add phone status e.g missing/stolen, open for sale
             </small></div></div> */}




      

        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-instagram fa-2x" /> */}
          <input
            type="text"  className="form-control"
            placeholder="Phone serial no."
            name="phonesno"
            value={formData.phonesno}
            onChange={onChange}
          />
           <small className="form-text">
             Phone Serial No. is unique to a phone and sensitive
             </small></div></div>

             <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-linkedin fa-2x" /> */}
          <input
            type="text"  className="form-control"
            placeholder="Confirm Phone Serial No"
            name="emei"
            value={formData.emei}
            onChange={onChange}
          />
           {/* <small className="form-text">
             Phone EMEI No. is unique to a phone and sensitive
             </small> */}
           <small className="form-text">
            Confirm Phone Serial No. is unique to a phone and sensitive
             </small>
             
             </div></div> 
                  {/* {errors.active && <div>{errors.active}</div>} */}

      
       
       

        <div className="lead">
            <h3>
              Emergency Details for phone recovery  
            </h3>
            <div>
               <small className="form-text">
                  e.g using friends or relative phone no in case of loss or stolen.
                  
                  </small>
            </div>
            <div>
              
            </div>
       
        </div>
       
    
        <p className="lead">  1.  </p>
       
       
       
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-instagram fa-2x" /> */}
          <input
            type="text"  className="form-control"
            placeholder="NOK Fullname"
            name="nok"
            value={formData.nok}
            onChange={onChange}
          />
           <small className="form-text">
           Next of kin, NOK fullname
           </small>
        </div></div>
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-instagram fa-2x" /> */}
          <input
            type="text"  className="form-control"
            placeholder="NOK Reletionship"
            name="nokrel"
            value={formData.nokrel}
            onChange={onChange}
          />
           <small className="form-text">
           Next of kin, NOK relationship
           </small></div></div>
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-instagram fa-2x" /> */}
          <input
            type="text"  className="form-control"
            placeholder="NOK 1st Phone"
            name="nokphone1"
            value={formData.nokphone1}
            onChange={onChange}
          />
           <small className="form-text">
           Next of kin, NOK 1st Phone to contact in case of emergency or phone theft
           </small></div></div>
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-instagram fa-2x" /> */}
          <input
            type="text"  className="form-control"
            placeholder="NOK 2nd Phone"
            name="nokphone2"
            value={formData.nokphone2}
            onChange={onChange}
          />
           <small className="form-text">
           Next of kin, NOK 2nd Phone to contact in case of emergency or phone theft
           </small></div></div>
          
        {/* <div className="col-lg-8 mb-2"><div className="form-group mb-3">
          <i className="fab fa-instagram fa-2x" />
          <input
            type="text"  className="form-control"
            placeholder="NOK email"
            name="nokemail"
            value={formData.nokemail}
            onChange={onChange}
          />
           <small className="form-text">
           Next of kin, NOK email address
           </small></div></div> */}


           <div className="my-2">
          <button
            onClick={() => setNokOption(!nokOption)}
            type="button"
            className="btn btn-dark"
          >
            Add more NOK
          </button>
          <span className="mx-4">Optional</span>
        </div>
          

           {nokOption &&  (  
          <Fragment>
           <br/> <p className="lead"> 2. </p>
              <div className="col-lg-4 mb-2"><div className="form-group mb-3">
                {/* <i className="fab fa-instagram fa-2x" /> */}
                <input
                  type="text"  className="form-control"
                  placeholder="NOK Fullname"
                  name="nok2"
                  value={formData.nok2}
                  onChange={onChange}
                />
                <small className="form-text">
                Next of kin, NOK fullname
                </small>
              </div></div>
              <div className="col-lg-4 mb-2"><div className="form-group mb-3">
                {/* <i className="fab fa-instagram fa-2x" /> */}
                <input
                  type="text"  className="form-control"
                  placeholder="NOK Reletionship"
                  name="nok2rel"
                  value={formData.nok2rel}
                  onChange={onChange}
                />
                <small className="form-text">
                Next of kin, NOK relationship
                </small></div></div>
              <div className="col-lg-4 mb-2"><div className="form-group mb-3">
                {/* <i className="fab fa-instagram fa-2x" /> */}
                <input
                  type="text"  className="form-control"
                  placeholder="NOK Primary Phone"
                  name="nok2phone1"
                  value={formData.nok2phone1}
                  onChange={onChange}
                />
                <small className="form-text">
                Next of kin, NOK Primary Phone to contact in case of emergency or phone theft
                </small></div></div>
              <div className="col-lg-4 mb-2"><div className="form-group mb-3">
                {/* <i className="fab fa-instagram fa-2x" /> */}
                <input
                  type="text"  className="form-control"
                  placeholder="NOK Secondary Phone"
                  name="nok2phone2"
                  value={formData.nok2phone2}
                  onChange={onChange}
                />
                <small className="form-text">
                Next of kin, NOK Secondary Phone to contact in case of emergency or phone theft
                </small></div></div>

              {/* <div className="col-lg-4 mb-2">
                <div className="form-group mb-3">
              /
                <input
                  type="text"  className="form-control"
                  placeholder="NOK email"
                  name="nok2email"
                  value={formData.nok2email}
                  onChange={onChange}
                />
                <small className="form-text">
                Next of kin, NOK email address
                </small></div>
                </div> */}
           </Fragment>
             )}
      
  </div>

      
      </section>
   );
};

export default StepTwo;

import axiosInstance from './AxiosInstance';

export function getUsers() {
    return axiosInstance.get(`/userOther`);
}
export function getUser(userID) {
    return axiosInstance.get(`users/${userID}`);
}

export function createUser(userData) {
    return axiosInstance.post(`/userOther`, userData);
}

export function updateUser(user, userId) {
    return axiosInstance.patch(`/users`, user);
}

export function deleteUser(user,userId) {
    return axiosInstance.delete(`/users`);
}

export function formatUsers(usersData) {
    let users = [];
    for (let key in usersData) {
        users.push({ ...usersData[key], id: key });
        console.log(users);
    }

    return users;
}

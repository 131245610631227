import React from 'react';
//import { useNavigate } from "react-router-dom";
import api from "../../utils/api";
import {
    CONFIRMED_CREATE_USER_ACTION,
    CONFIRMED_DELETE_USER_ACTION,
    CONFIRMED_EDIT_USER_ACTION,
    CONFIRMED_GET_USERS,
    CONFIRMED_GET_USER,
    LOADING_TOGGLE_ACTION,
    USER_ERROR,
    CREATE_STATUS,
    CLEAR_USER
} from './UserTypes';

import {
setAlert 
} from './alertAction';

import {
    createUser,
    formatUsers,
    getUsers,
    getUser,
    deleteUser,
    updateUser,
} from '../../services/UsersService';

export function deleteUserAction(userId, history) {
    return (dispatch, getState) => {
        deleteUser(userId).then((response) => {
            dispatch(confirmedDeletetUserAction(userId));
           // history.push('/UserList');
        });
    };
}

export function confirmedDeletetUserAction(userId) {
    return {
        type: CONFIRMED_DELETE_USER_ACTION,
        payload: userId,
    };
}


export function clear_user() {
    return {
        type: CLEAR_USER
    };
}

export function createUserAction(UserData, navigate,edit) {
 
	return  (dispatch, getState) => {
        try {
           // console.log(UserData);
            //console.log('i am here');
           const response =  api.post("/userOther",UserData);

            dispatch(
                setAlert(edit ? 'User Updated' : 'User Created', 'success')
              );
              
              dispatch(confirmedCreateUserAction(response.data));

          navigate('/staff-list');

            // createUser(UserData).then((response) => {
   
            //  dispatch(
            //     setAlert(edit ? 'User Updated' : 'User Created', 'success')
            //   );

              
            //   dispatch(confirmedCreateUserAction(response.data));
  
                
            // });
            
        } catch (err) {
            dispatch({
                type: USER_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
              });

        

        }
       
    };
}

export function getUsersAction() {
    return (dispatch, getState) => {
        getUsers().then((response) => {
          //  let users = formatUsers(response.data);
        
            dispatch(confirmedGetUsersAction(response.data));
          
        });
    };
}

export function getCurrentUserAction(userID) {
    return (dispatch, getState) => {
        getUser(userID).then((response) => {
         //   let user = formatUsers(response.data);
 //            console.log("US ARMY"+response.data);
            dispatch(confirmedGetUserAction(response.data));
          
        });
    };
}

export function confirmedCreateUserAction(singleUser) {
	
    return {
        type: CONFIRMED_CREATE_USER_ACTION,
        payload: singleUser,
    };
}

export function confirmedGetUsersAction(users) {
    return {
        type: CONFIRMED_GET_USERS,
        payload: users,
    };
}

export function confirmedGetUserAction(user) {
    return {
        type: CONFIRMED_GET_USER,
        payload: user,
    };
}

export function confirmedUpdateUserAction(user) {

    return {
        type: CONFIRMED_EDIT_USER_ACTION,
        payload: user,
    };
}

export function updateUserAction(user, history) {
    return (dispatch, getState) => {
        updateUser(user, user.id).then((reponse) => {
            dispatch(confirmedUpdateUserAction(user));
            history.push('/Userlist');
        });
			
    };
}
export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

import api from "../../utils/api";
import {
  STATE_LGA,
  STATELGA_ERROR,
  SERVICE_FEE,
  SERVICE_FEE_ERROR
} from "../types";

import sData from "../../states.json"
import tData from "../../transaction_types.json"



export const getStatesLGA = () => async (dispatch) => {

  try {
  

    dispatch({
      type: STATE_LGA,
      payload: sData,
    });
  } catch (err) {
    dispatch({
      type: STATELGA_ERROR,
      payload: { msg:'Could not load States/LGA', status: 404 },
    });
  }
};

export const getServiceFees = () => async (dispatch) => {

  try {
  

    dispatch({
      type: SERVICE_FEE,
      payload: tData,
    });
  } catch (err) {
    dispatch({
      type: SERVICE_FEE_ERROR,
      payload: { msg:'Could not load Service Fees', status: 404 },
    });
  }
};



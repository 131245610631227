import React, {  useEffect,Fragment, useState } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from 'react-form-stepper';
import { Link, useNavigate,useMatch, useParams } from "react-router-dom";
import StepOnePhone from "./StepOnePhone";
import StepTwoPhonePreview from "./StepTwoPhone";
import PropTypes from "prop-types";
import api from "../../../../utils/api";
// import {
// 	addPhone
//   } from "../../../../store/actions/profileAction";
// import StepThree from "./StepThree";
// import StepFour from "./StepFour";
import PageTitle from "../../../layouts/PageTitle";
import { usePaystackPayment, PaystackButton, PaystackConsumer } from '../../../../dist/index.es';
import { connect, useDispatch } from 'react-redux';
import swal from "sweetalert";
import {
	addPhone,


	getCurrentProfile,
	
  } from "../../../../store/actions/profileAction";
const initialState ={
	_id:'',
	phoneNumber: '',
	phoneModel: '',
	phoneName: '',
	phonecolor:'',
	phonecondition:'',
	emei: '',
	phonesno: '',
	phoneStatus: 'IN USE',
	referralno:'',
	referred:false,

	nok: '',
	nokrel: '',
	nokphone1: '',
	nokphone2: '',
	nokemail: '',
	nok2: '',
	nok2rel: '',
	nok2phone1: '',
	nok2phone2: '',
	nok2email: '',

}
const RegisterPhone = ({

	addPhone,
	profile: { profile, loading },
	
	getCurrentProfile,
	phone,
	name,
	email
  
  }) => {
	const [goSteps, setGoSteps] = useState(0);
	const [amount, setAmount] = useState(0);
	const [ref, setRef] = useState('');
	const [feeFlag, setFeeFlag] = useState(false);
	const [profile_Status,setProfileStatus] = useState("")
	const [serial,setSerial] = useState(false)
	const [nokOption,setNokOption] = useState(false)
	const navigate = useNavigate();
	const [validationFlag,setValidationFlag] = useState(false)
	const creatingPhone = useMatch("/add-phone");
	const { id } = useParams()
	const [formData, setFormData] = useState(initialState);
	const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  var sno=false
  var sno2=true
   
	
	const {
		
	phoneNumber,
	phonecolor,
	phonecondition,
	phoneModel,
	phoneName,
	emei,
	phonesno,
	referralno,
	referred,
	phoneStatus,

	nok,
	nokrel,
	nokphone1,
	nokphone2,
	nokemail,
	nok2,
	nok2rel,
	nok2phone1,
	nok2phone2,
	nok2email,
	updated_at,
	created_at,
	date

		} = formData;
  
	let errorsObj = {		
	
	  phoneModel:'',
	  phoneNumber:'',
	  phonecolor:'',
	  phonecondition:'',
	  phoneName:'',
	  phoneStatus:'IN USE',
	  emei:'',
	 phonesno:'',
	 referralno:'',
	 referred:'',
	
	 nok:'',
	 nokrel:'',
	 nokphone1:'',
	 nokphone2:'',
	 nokemail:'',
	 nok2:'',
	 nok2rel:'',
	 nok2phone1:'',
	 nok2phone2:'',
	 nok2email:''
	 };


	 useEffect(() => {

		const userData = { ...initialState };

		// if there is no profile, attempt to fetch one
		if (!profile) getCurrentProfile();
		if (!loading && profile?.phone_detail && !creatingPhone) {
	   
		 
			const user = profile?.phone_detail.find((element) => {
			return element._id === id;
			})
			//console.log(user);
		 
		  for (const key in user) {
			//console.log(key);
			if (key in userData) userData[key] = user[key];
		  }
	
		 
	
		  // set local state with the userData
		  setFormData(userData);
	  
		
		}
		
	  else{

		  setFormData(userData);
		}  
	  }, [loading,creatingPhone, profile, getCurrentProfile,id]);

	 const [errors, setErrors] = useState(errorsObj);
	const onChange = (e) =>
	  setFormData({ ...formData, [e.target.name]: e.target.value });
	

	  const onSuccess = (reference) => {
		// Implementation for whatever you want to do with reference and after success call.
		//setProfileStatus("MEMBER")
		setRef(reference)
		afterValidate()
		//console.log('reference',reference);
	};
	
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log('closed')
	}

	const onReferredChanged = () => 
	{
	  
	  setFormData({ ...formData, referred:formData.referred?false:true});
	}

	function afterValidate() {
		//preventDefault();
		//setFormData({ ...formData, profileStatus: "MEMBER" });
		
		addPhone({...formData,name,email,ref}, navigate,true).then(() => navigate("/my-phones"));

		//createProfile(formData, profile ? true : false).then(() =>	navigate("/dashboard")	);
	}

	
	
	const componentProps = {
		//   ...config,
		reference: (new Date()).getTime().toString(),
		email:email,
		// amount:amount*100,
		amount:amount*100,
		metadata: {
			name,
			phone,
		  },
		publicKey: publicKey,
	
		
	   //  lastname: 'story',
		   text: 'Proceed to CHECKOUT',
		   onSuccess,
		   onClose
	   };

	//    function checkReferral (e){
	// 	e.preventDefault();
	// 	let error = false;
    //     const errorObj = { ...errorsObj };
	// 	if (phoneName == '') {
    //         errorObj.phoneName = 'Phone name is Required';
    //         error = true;
	// 		swal('Oops', errorObj.phoneName, "error");
    //     }
	// 	 if (phonesno == '') {
    //         errorObj.phonesno = 'Phone Serial no is Required';
    //         error = true;
	// 		swal('Oops', errorObj.phonesno, "error");
    //     }
	// 	//  if (phoneNumber === '') {
    //     //     errorObj.phoneNumber = 'Phone no is Required';
    //     //     error = true;
	// 	// 	swal('Oops', errorObj.phoneNumber, "error");
    //     // }
	// 	 if (phonecolor == '') {
    //         errorObj.phonecolor = 'Phone color is Required';
    //         error = true;
	// 		swal('Oops', errorObj.phonecolor, "error");
    //     }

    //     setErrors(errorObj);
    //     if (error) return;

	// 	if(creatingPhone) {
	// 		//alert('yes')
	// 		api.put(`/profile/phones`,formData).then((res) => {
	// 		//console.log(res.data)
	// 			if(res.data.isError){
					
	// 				if(res.data.msg.errors[0].msg ===`another profile` ){
	// 					swal('Oops',"This phone has already been registered. Search it in phone page to confirm. If you genuinely buy it but not deleted by the owner, please call h(im)er to do so before paying for the phone..\n\n If persist, please send complain to M-right with the detail of the phone to resolve the matter", {
	// 					icon: "error",
	// 					});
	// 				}else{
	// 					swal('Oops', `${res.data.msg.errors[0].msg} Remove it or type in right referral code to proceed` , {
	// 						icon: "error",
	// 						});

	// 				}
	// 				 setValidationFlag(false);
					
	// 				setGoSteps(1)

	// 			}else{
	// 				//profile.default && setValidationFlag(true);
	// 				setAmount(900)
	// 				setFeeFlag(true)
	// 				setGoSteps(1)
				
				
	// 			}
		  
	// 		}) .catch((error) => {
	// 			console.error(error)
	// 		 });
			
		
		
	// 	}else setGoSteps(1)

	//    }
	   
	 
	   const checkReferral2 = async(e)=>{

		await api.put(`/profile/phones2`,formData).then((res) => {
			//console.log(res.data)
				if(res.data.isError){
					swal('Oops',"Wrong referral code or your Agentship Licence might have been revoked. Please Contact MRight Admin", {
						icon: "error",
						});
						//alert(sno2)
						sno2=false
					}else{
						//setAmount(900)
						setAmount(0)
					setFeeFlag(true)
					sno2=true
					}
					
			}) .catch((error) => {
				console.error(error)
			 });
	   }
	   const recheckReferral2 = async(e)=>{
		setValidationFlag(false)
		//setAmount(1200)
		setAmount(0)
		setGoSteps(0)
	   }


	   const  checkPhoneSerial = async ()=> {

		await api.put(`/profile/phones`,formData).then((res) => {
			console.log(res.data.isError)
			if(res.data.isError){
					if(res.data.msg.errors[0].msg ===`another profile` ){
						swal('Oops',"This phone has already been registered. Search it in phone page to confirm. If you genuinely buy it but not deleted by the owner, please call h(im)er to do so before paying for the phone..\n\n If persist, please send complain to M-right with the detail of the phone to resolve the matter", {
						icon: "error",
						});
						//alert('loo!')
						sno=true
						setSerial(true)
					}else{
						swal('Oops', `${res.data.msg.errors[0].msg} ` , {
							icon: "error",
							});
							sno=true
							setSerial(true)
					}
				}else  setSerial(false)
			}) .catch((error) => {
				console.error(error)
			 });
	   }

   const  onSubmit = async (e)=> {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
	
     	if (phoneName == '') {
            errorObj.phoneName = 'Phone name is Required';
            error = true;
			swal('Oops', errorObj.phoneName, "error");
        }
		 if (phonesno == '') {
            errorObj.phonesno = 'Phone Serial no is Required';
            error = true;
			swal('Oops', errorObj.phonesno, "error");
        }
		 if (phonecondition === '') {
            errorObj.phonecondition = 'Phone condition is Required';
            error = true;
			swal('Oops', errorObj.phonecondition, "error");
        }
		 if (phonecolor == '') {
            errorObj.phonecolor = 'Phone color is Required';
            error = true;
			swal('Oops', errorObj.phonecolor, "error");
        }

        setErrors(errorObj);
        if (error) return;
		 await checkPhoneSerial()
		referralno && await checkReferral2()
		if (sno)return;
		//if (sno2)return;
	
		if(creatingPhone && referralno !=="" )
			if(!sno2) return;

		if(creatingPhone && !sno) {
			//alert(sno)
					profile.default && setValidationFlag(true);
					if(!profile.default ){
						if(!serial)
						addPhone({...formData,name,email,ref}, navigate,true).then(() => navigate("/my-phones"));
					}
		}
		


		
		
		if(!creatingPhone){
			//alert('i am here')
			addPhone(formData, navigate, false).then(() => navigate("/my-phones"));
			
		}


    }
	   
	return (
		<Fragment>
			{/* <PageTitle activeMenu="Add Phone Service" motherMenu="Home" /> */}

			
			<div className='row'>
					<div className='col-lg-4 mb-2'>
					<button type="button" className="btn btn-warning" onClick={() => navigate(-1)}> <i className="flaticon-delete-1"></i> Discard</button> 
					</div>
				
					
				
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header">
							 <h4 className="card-title">Phone adding Service</h4>
							{/*<h6>New Phone Registration Fee</h6>  */}
							
						</div>
						<form onSubmit={(e) => onSubmit(e)}>
								<div className="card-body">
								<div className="text-center mb-3">
									
								{/* <h4 className="card-title">Phone Registration Service</h4> */}
								<h6>{creatingPhone ? "Add New Phone to Profile" : "Edit Phone"}</h6>
								{(referralno && feeFlag) &&  (  
								<Fragment>
									<div>&#8358;0.0</div> 
								</Fragment>
								)}
								
								{!referralno &&  (  
								<Fragment>
									<div>&#8358;0.00</div> 
								</Fragment>
								)}
						
								
                   		 </div>
							<div className="form-wizard ">
								{/* <Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} />
									<Step className="nav-link" onClick={() => setGoSteps(3)} />
								</Stepper> */}
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									{/* <Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} /> */}
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
								</Stepper>
							  {goSteps === 0 && (
								<>
									<StepOnePhone 
									formData={formData} 
									onChange={onChange} 
									onReferredChanged={onReferredChanged}
									nokOption={nokOption}
									setNokOption={setNokOption}
									/>	
									<div className="text-end toolbar toolbar-bottom p-2"> 
										<button  className="btn btn-warning sw-btn-next" onClick={(e) => setGoSteps(1) } >Next</button>
									</div>	
								</>
							  )}
{/* setGoSteps(1) */}
{/* onClick={(e) => checkReferral(e)} */}
							  {/* {goSteps === 1 && (
								<>
									<StepTwo />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1" onClick={() => setGoSteps(2)}>Next</button>
									</div>	
								</>
							  )}
							  {goSteps === 2 && (
								<>
									<StepThree />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(3)}>Next</button>
									</div>	
								</>
							  )} */}
							  {goSteps === 1 && (
								<>
									<StepTwoPhonePreview formData={formData} 	/>
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-warning sw-btn-prev me-1" onClick={() => recheckReferral2()}>Prev</button>
										

										{!validationFlag &&  (  
        									  <Fragment>						
												<button   className="btn btn-secondary sw-btn-next ms-1" >submit</button>
											</Fragment>
           									)}	
									</div>	
								</>	
							  )}
							  
							</div>
						</div>
						</form>
					{validationFlag &&  (  
						<Fragment>
						<PaystackButton className="btn btn-secondary my-1" {...componentProps} />
					</Fragment>
						)}	

					</div>
				</div>
			</div>
		</Fragment>
	);
};


RegisterPhone.propTypes = {

	addPhone: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	
	
  };
  const mapStateToProps = (state) => ({
	profile: state.profileReducer,
	name: state.auth.auth.name,
	email: state.auth.auth.email,
	phone: state.auth.auth.phone,


  });
  const mapDispatchToProps = { addPhone,getCurrentProfile };
  export default connect(mapStateToProps, mapDispatchToProps)(RegisterPhone);
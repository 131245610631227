export const CREATE_USER_ACTION = '[Post Action] Create user';
export const CONFIRMED_CREATE_USER_ACTION =
    '[Post Action] Confirmed Create user';
export const GET_USERS = '[User Action] Get Users';
export const CONFIRMED_GET_USERS = '[User Action] Confirmed Get Users';
export const CONFIRMED_GET_USER = '[User Action] Confirmed Get User';
export const EDIT_USER_ACTION = '[User Action] Edit User';
export const CONFIRMED_EDIT_USER_ACTION =
    '[User Action] Confirmed Edit User';
export const CONFIRMED_DELETE_USER_ACTION =
    '[User Action] Confirmed Delete User';
export const LOADING_TOGGLE_ACTION =
    '[User Action] Confirmed Loading status';
    export const USER_ERROR =
    '[User Action] User Errors';
    export const CREATE_STATUS =
    '[User Action] User Create Status';
    export const CLEAR_USER =
    '[User Action] User Clear Status';
import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate}  from "react-router-dom";
import api from "../../utils/api";
import {  Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import PageTitle from "../layouts/PageTitle";
import pic1 from './../../images/profile/small/pic1.jpg';
import {

  UPDATE_PROFILE,

} from "../../store/types";
import { useDispatch } from 'react-redux'
  import Spinner from '../layouts/Spinner';
  //import useAuth from '../../config/hooks/useAuth';



  const initialState ={
	

	comp_cert: false,
	birthday:null,
	profileStatus:'M-RIGHT REGISTRAR',
  file:null,


}
const Registrar = () =>{
	
  const dispatch = useDispatch()
	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialState);
  const [imgHolder, setImgHolder] = useState();


	const {	 comp_cert,birthday,profileStatus,file} = formData;
	let errorsObj = {
		
		comp_cert:false,
		birthday:'',
		profileStatus:'',
    file: null
	   };

     const imgChnage = (e) => {
      setImgHolder(e.target.files[0]);
     // setFormData({ ...formData, file: e.target.files[0] });

      console.log(e.target.files[0])
    };

    // handleUpload = () => {
    //   //event.preventDefault();
    //   const data = new FormData();
    //   data.append('birthday', formData.birthday, formData.birthday.name);
    //   data.append('prac_exp', formData.prac_exp);
    //   data.append('comp_cert', formData.comp_cert);
    //   profileChangeStatus
    //   // axios.post(endpoint, data).then((res) => {
    //   //   console.log(res.statusText);
    //   // });
    // };
    const handleUpload = () => {

      swal({
      title: "Are you sure?",
      text:
        "Your application will be submmited to Admin for review",
      icon: "warning",
      buttons: true,
      dangerMode: true,
        }).then((willDelete) => {

      if (willDelete) {
  
          const data2 = new FormData();
        
       //   data2.append('file', imgHolder);
      //   data2.append('fileName', imgHolder.name);
         data2.append('profileStatus', 'M-RIGHT REGISTRAR');

          data2.append('prac_exp', formData.prac_exp);
          data2.append('comp_cert', formData.comp_cert);
          
      //your application has been submitted successfully. If considered, you will be notified via email and you will see an agent link in your account dashboard. You would also see your refferal code under settings in your account.\n Thank you for the application
        
      api.post(`/profile/mrr-apply`,data2).then((res) => {
           // console.log(res.data)
            if(!res.data.isError){
              
              swal("Your application has been submitted successfully. If you are considered, you will be notified via email and you will see your approval status on your dashboard.\n\n Thank you for the application", {
              icon: "success",

            });
    

         

            
          navigate("/dashboard")


      }else{
        swal("Error in file type!. Only PDF / JPEG / JPG /PNG accepted. Application not submitted!");
      }
      
    });
    } else {
      swal("Application as a registrar is not submited");
    }
    })

  }

	// useEffect(() => {
	// 	// if there is no profile, attempt to fetch one
	// 	!profile && getCurrentProfile();
	// 	if(profile && !loading ) {
	// 		setFormData({ ...formData, prof_id: profile._id ,profileStatus:'M-RIGHT REGISTRAR'});

	// 	}
	//   }, [loading, profile, getCurrentProfile]);

	  const [errors, setErrors] = useState(errorsObj);

	  const onPracExpChanged = () => 
	{
	  
	  setFormData({ ...formData, prac_exp:formData.prac_exp?false:true});
	}
	  const onComCertChanged = () => 
	{
	  
	  setFormData({ ...formData, comp_cert:formData.comp_cert?false:true});
	}
	  
   
    
     //Add Submit data
    const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
       
		//  if (profileStatus === '') {
    //         errorObj.profileStatus = 'Profile Status is Required';
    //         error = true;
		// 	swal('Oops', errorObj.profileStatus, "error");
    //     }
        
		//  else if (prac_exp === '') {
    //         errorObj.prac_exp = 'Practical Experience is Required';
    //         error = true;
		// 	swal('Oops', errorObj.prac_exp, "error");
    //     }

		//  else 
     if (!comp_cert ) {
            errorObj.comp_cert = 'Check the Phone shop / Agent box ';
            error = true;
			swal('Oops', errorObj.comp_cert, "error");
        }

		//  if (birthday === '') {
    //         errorObj.birthday = 'Birthday certificate is Required';
    //         error = true;
		// 	swal('Oops', errorObj.birthday, "error");
    //     }
		
      // else  if (!file ) {
      //     errorObj.file = 'Please attach your birthday certificate';
      //     error = true;
      // swal('Oops', errorObj.file, "error");
      // }

        setErrors(errorObj);
        if (error) return;
        setFormData({ ...formData,file:imgHolder});
       // console.log(formData)
       handleUpload()
    };
	


	return(
		<>
			{/* <PageTitle activeMenu="" motherMenu="Services" /> */}
			<section>
         <div className="row">
        
            <div className="col-lg-12 mb-4">
			<div className="card">
					<div className="card-header">
						<h2 className="card-title">Application for Phone shop/Agent application</h2>
					</div>
				
            <div  className="basic-form">
              <form
               className="d-flex align-items-center"
              >
               {/* <div className="form-group mb-3">
			   <label
                      className="form-check-label text-black font-w500 px-3"
                      htmlFor="active"
                    >
                     <h3>Do you have practical experience to operate  phone or computer connected to internet?</h3>
                    </label>
					
					 <input
                      type="checkbox"
                      
                      className="form-check p-3"
                      id="customCheckBox9"
                      required

                      name={formData.prac_exp}
                      value={formData.prac_exp}
                      checked ={ formData.prac_exp && 'checked' }
                  
                       onChange={onPracExpChanged}


                    />
                   
               </div> */}
              
                <div className=' mb-3'>
                  <label
                      className="form-check-label text-black font-w500 "
                      htmlFor="active"
                    >
                     <h3>Phone shop / Agent?</h3>
                    </label>
                </div>
		          	   
					
					      <div className=" mx-sm-3 mb-3">
                  <input
                      type="checkbox"
                      
                      className=" form-check  "
                      id="customCheckBox9"
                      required

                      name={formData.comp_cert}
                      value={formData.comp_cert}
                      checked ={ formData.comp_cert && 'checked' }
                      // onChange={(e) =>
                      //   setActive(e.target.value)
                      // }
                      onChange={onComCertChanged}


                    />
                  </div> 
                   
             
			   {/* <div className="form-group mb-3">
			   <label
                      className="form-check-label text-black font-w500 px-3"
                      htmlFor="active"
                    >
                     <h3>Upload your Birth Certificate </h3>
                    </label>
                 
                
                  <input
               type="file"
               className="form-control"
               id="inputGroupPrepend2"
               aria-describedby="inputGroupPrepend2"
            placeholder="Upload your Birth Certificate"
            name="file"
           
           onChange={(imgChnage)} 
          />
         
            </div>  */}
            </form>
           
            
     </div>
	 <div className="modal-footer">
									<button type="submit" className="btn btn-secondary" onClick={handleAddFormSubmit}>Apply</button>   
                  <button type="button" className="btn btn-warning" onClick={() => navigate(-1)}> <i className="flaticon-delete-1"></i> Discard</button>  
								</div>
     </div>
     </div>
        
       
           
           
         </div>
      </section>
				
		</>
	)
}



  
  export default (
	Registrar
  );
import axiosInstance from './AxiosInstance';

export function getRooms() {
    return axiosInstance.get(`/rooms`);
}
export function getRoom(roomId) {
    return axiosInstance.get(`rooms/${roomId}`);
}

export function createRoom(roomData) {
    return axiosInstance.post(`/rooms`, roomData);
}

export function updateRoom(roomData) {
    return axiosInstance.put(`/rooms`, roomData);
}

export function updateRoomName(roomData) {
    return axiosInstance.patch(`/rooms/roomnames`, roomData);
}

export function deleteRoom(roomId) {
    return axiosInstance.delete(`/rooms/roomnames/${roomId}`);
}

export function formatUsers(usersData) {
    let users = [];
    for (let key in usersData) {
        users.push({ ...usersData[key], id: key });
        console.log(users);
    }

    return users;
}

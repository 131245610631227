import api from "../../utils/api";
import {
  CLEAR_PROFILE,
  GET_PROFILE,
  GET_PROFILES,
  GET_REPOS,
  NO_REPOS,
  UPDATE_PROFILE,
  PROFILE_ERROR,
  STATE_LGA,
  STATELGA_ERROR,
  SET_PROFILE_STATUS,
  CHANGE_STATUS,
  GET_PROFILES2,
  GET_PROFILES3,
  GET_PROFILES4,
  TRANSFER_CREDIT,
  ADD_CREDIT,
  UPDATE_PROFILE_VALIDATE,
  CHANGE_TNC

} from "../types";

import { setAlert } from "./alertAction";
import sData from "../../states.json"
import {
  setProfileStatus,
} from '../../services/AuthService';


// Get Github repos
export const getGithubRepos = (username) => async (dispatch) => {
  try {
    const res = await api.get(`/profile/github/${username}`);

    dispatch({
      type: GET_REPOS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: NO_REPOS,
    });
  }
};

export const getProfiles = () => async (dispatch) => {
 // dispatch({ type: CLEAR_PROFILE });

  try {
    const res = await api.get("/profile");

    dispatch({
      type: GET_PROFILES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getProfiles_member = () => async (dispatch) => {
 // dispatch({ type: CLEAR_PROFILE });

  try {
    const res = await api.get("/profile/members");

    dispatch({
      type: GET_PROFILES4,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getProfiles_excos = () => async (dispatch) => {
 // dispatch({ type: CLEAR_PROFILE });

  try {
    const res = await api.get("/profile/excos");

    dispatch({
      type: GET_PROFILES2,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
export const getProfilesSearch = () => async (dispatch) => {
  //dispatch({ type: CLEAR_PROFILE });

  try {
    const res = await api.get("/profile/search");

    dispatch({
      type: GET_PROFILES3,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
export const getStatesLGA = () => async (dispatch) => {

  try {
  

    dispatch({
      type: STATE_LGA,
      payload: sData,
    });
  } catch (err) {
    dispatch({
      type: STATELGA_ERROR,
      payload: { msg:'Could not States/LGA', status: 404 },
    });
  }
};

export const getProfileById = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/profile/user/${userId}`);
    dispatch({ type: GET_PROFILE, payload: res.data });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.statusCode,
      },
    });
  }
};
export const getCurrentProfile = () => async (dispatch) => {
  try {
    const res = await api.get("/profile/me");
   // console.log("inside the getCurrentProfile method");
    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });

    
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.statusCode,
      },
    });
  }
};

export const createProfile =
  (formData, navigate, user, edit = false) =>
  async (dispatch) => {
    try {
      const res = await api.post("/profile", formData);
    // const  payload= {...user, profileStatus:'MEMBER'}
  //    console.log(payload)
      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });

      dispatch({
        type: SET_PROFILE_STATUS,
        payload: {...user, profileStatus:'MEMBER'},
      });

     // dispatch(setProfileStatus());
     setProfileStatus(
        dispatch,
        'MEMBER',
        navigate,
    );
      dispatch(
        setAlert(edit ? "Profile Updated" : "Profile Created", "success")
      );
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };
export const createProfileRegistrar =
  (formData, navigate, user, edit = false) =>
  async (dispatch) => {
    try {
      const res = await api.post("/profile/agent", formData);
 //const  payload= {...user, profileStatus:'MRR'}
      //console.log(payload)
      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });

      dispatch({
        type: SET_PROFILE_STATUS,
        payload: {...user, profileStatus:'MRR'},
      });

     // dispatch(setProfileStatus());
     setProfileStatus(
        dispatch,
        'MEMBER',
        navigate,
    );
      dispatch(
        setAlert(edit ? "Profile Updated" : "Profile Created", "success")
      );
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

  // Add Experience
export const addExperience = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put('/profile/experience', formData);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Experience Added', 'success'));

    navigate('/dashboard');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add Education
export const addEducation = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put('/profile/education', formData);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Education Added', 'success'));

    navigate('/dashboard');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add Phone
export const addPhone = (formData, navigate,edit) => async (dispatch) => {
  try {
  
    const res = await api.put('/profile/phone', formData);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert(edit?'Phone Added':'Phone Updated', 'success'));

    navigate('/my-phones');
  } catch (err) {
  
    const errors = err.response.data.errors;
   //console.log(errors)
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// Transfer Credit
export const transferCredit = (formData, navigate,edit) => async (dispatch) => {
  try {
   //console.log(formData)
    const res = await api.post('/credit', formData);

    dispatch({
      type: TRANSFER_CREDIT,
      payload: res.data
    });

    dispatch(setAlert('Credit Transferred', 'success'));

    navigate('/dashboard');
  } catch (err) {
  
    const errors = err.response.data.errors;
   console.log(errors)
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return {msg: err.response.data.errors[0].msg, isError: true}
  }
};
// Transfer Credit
export const AddFund = (formData, navigate,edit) => async (dispatch) => {
  try {
   //console.log(formData)
    const res = await api.put('/credit/add-credit', formData);

    dispatch({
      type: ADD_CREDIT,
      payload: res.data
    });

    dispatch(setAlert('Credit Added', 'success'));

    navigate('/dashboard');
  } catch (err) {
  
    const errors = err.response.data.errors;
   //console.log(errors)
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return err.response.data.errors[0].msg
  }
};

// Transfer Credit
export const BuyFund = (formData, navigate,edit) => async (dispatch) => {
  try {
   //console.log(formData)
    const res = await api.put('/credit/buy-credit', formData);

    dispatch({
      type: ADD_CREDIT,
      payload: res.data
    });

    dispatch(setAlert('Credit Added', 'success'));

    navigate('/dashboard');
  } catch (err) {
  
    const errors = err.response.data.errors;
   //console.log(errors)
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    return err.response.data.errors[0].msg
  }
};


// Delete experience
export const deleteExperience = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/profile/experience/${id}`);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Experience Removed', 'success'));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
//// Delete profile phone item
export const deletePhoneAction = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.patch(`/profile/phone`,formData);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Phone Removed', 'success'));
  } catch (err) {

    dispatch(
      setAlert(err.response.data.errors[0].msg, 'error')
      
  );
  return err.response.data.errors[0].msg
  }
};
//// Declare profile phone item missing
export const deletePhoneAction_missing = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put(`/profile/phone/missing`,formData);
console.log(res.data)
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Phone Status Changed to Stolen', 'success'));
  } catch (err) {

    dispatch(
      setAlert(err.response.data.errors[0].msg, 'error')
      
  );
  return err.response.data.errors[0].msg
  }
};
//// Declare profile phone item missing
export const changeStatus = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put(`/profile/changeStatus`,formData);

    dispatch({
      type: CHANGE_STATUS,
      payload: res.data
    });

    dispatch(setAlert('Member Status Change', 'success'));
  } catch (err) {

    dispatch(
      setAlert(err.response.data.errors[0].msg, 'error')
      
  );
  return err.response.data.errors[0].msg
  }
};
//// Declare profile phone item missing
export const changeStatus2 = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put(`/profile/changeStatus2`,formData);

    dispatch({
      type: CHANGE_STATUS,
      payload: res.data
    });

    dispatch(setAlert('Member Status Change', 'success'));
  } catch (err) {

    dispatch(
      setAlert(err.response.data.errors[0].msg, 'error')
      
  );
  return err.response.data.errors[0].msg
  }
};
//// Declare profile phone item forsale
export const deletePhoneAction_forsale= (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put(`/profile/phone/forsale`,formData);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Phone Status Changed to Forsale', 'success'));
  } catch (err) {

    dispatch(
      setAlert(err.response.data.errors[0].msg, 'error')
      
  );
  return err.response.data.errors[0].msg
  }
};


//// Change phone status
export const deletePhoneAction_ChangeStatus = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put(`/profile/phone/change-status`,formData);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Phone Status Changed', 'success'));
  } catch (err) {

    dispatch(
      setAlert(err.response.data.errors[0].msg, 'error')
      
  );
  return err.response.data.errors[0].msg
  }
};
//// Change phone status
export const deletePhoneAction_ChangeStatusValidated = (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put(`/profile/phone/change-status_validate`,formData);

    dispatch({
      type: UPDATE_PROFILE_VALIDATE,
      payload: res.data
    });

    dispatch(setAlert('Phone validated successfully', 'success'));
  } catch (err) {

    dispatch(
      setAlert(err.response.data.errors[0].msg, 'error')
      
  );
  return err.response.data.errors[0].msg
  }
};



//// Declare profile phone item missing
export const changeTnc= (formData, navigate) => async (dispatch) => {
  try {
    const res = await api.put(`/profile/changeTnc`,formData);

    dispatch({
      type: CHANGE_TNC,
      payload: res.data
    });

    dispatch(setAlert('Terms and conditions saved', 'success'));
  } catch (err) {

    dispatch(
      setAlert(err.response.data.errors[0].msg, 'error')
      
  );
  return err.response.data.errors[0].msg
  }
};


//// M-RIGHT MEMBER APPLY
export const profileChangeStatus = (formData, navigate) => async (dispatch) => {
  try {
    console.log(formData)
    // const res = await api.post(`/profile/mrr-apply`,formData,{
    //   'Content-Type': 'multipart/form-data'
    // });

    // dispatch({
    //   type: UPDATE_PROFILE,
    //   payload: res.data
    // });

    // dispatch(setAlert('Application Submitted, Admin shall you contact shortly', 'success'));
  } catch (err) {

    dispatch(
      setAlert(err.response.data.errors[0].msg, 'error')
      
  );
  return err.response.data.errors[0].msg
  }
};






// Delete education
export const deleteEducation = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/profile/education/${id}`);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Education Removed', 'success'));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
// export function setProfileStatus() {

//     return {
//         type: SET_PROFILE_STATUS,
//         payload: 'MEMBER',
//     };
// }
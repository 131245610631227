
import {
    CONFIRMED_CREATE_ROOM_ACTION,
    CONFIRMED_CREATE_ROOM_ITEM_ACTION,
    CONFIRMED_DELETE_ROOM_ACTION,
    CONFIRMED_EDIT_ROOM_ACTION,
    CONFIRMED_GET_ROOMS,
    CONFIRMED_GET_ROOM,
    ROOM_ERROR,
} from '../actions/RoomType';
const initialData = {
	list :null,
	lists : [],
	errorMessage: '',
    successMessage: '',
    showLoading: true,
}
const roomReducer = (state = initialData, action) =>{

	switch(action.type){
		case CONFIRMED_EDIT_ROOM_ACTION:
		case CONFIRMED_DELETE_ROOM_ACTION:
		case CONFIRMED_GET_ROOM : 
		case CONFIRMED_CREATE_ROOM_ITEM_ACTION : 
	
		return{
	    	...state,
			list: action.payload,
			showLoading: false,
		}

		case CONFIRMED_GET_ROOMS : 

		return {
            ...state,
            lists: action.payload,
            // activeUsers: (actions.payload).filter((user) => user.active),
            // inActiveUsers: (actions.payload).filter((user) => !user.active),
            showLoading: false,
        };

		case CONFIRMED_CREATE_ROOM_ACTION: 

		  const lists = [...state.lists];
        lists.push(action.payload);

        return {
            ...state,
            lists,
            showLoading: false, 
        };

		case ROOM_ERROR : 
		return {
            ...state,
            errorMessage: action.payload,
        };
     
		case "REMOVE_ROOM" : return{
			...state,
			list: null
		}
		default: return state;
           
	}
}
export default roomReducer;
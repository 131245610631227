import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import {  Route, Routes, useLocation , useNavigate , useParams } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated, profileStatus } from './store/selectors/AuthSelectors';

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import Alert from './jsx/components/AppsMenu/AppProfile/Alert'

import { disableReactDevTools } from '@fvilers/disable-react-devtools';
const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./jsx/pages/ResetPassword'));


if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}


const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
	
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}



function App (props) {
    const dispatch = useDispatch();
	  const navigate = useNavigate();
    const tokenDetailsString = localStorage.getItem('userDetails');
    const location = useLocation();
   
    useEffect(() => {
      if (tokenDetailsString) {
       // dispatch(Logout(navigate));
       //window.location.reload(true);
		checkAutoLogin(dispatch, navigate);
 
    }else{
      if (location.pathname == "/") {
       // alert(location.pathname)
        navigate("/login");
      }
      else {
       // alert(location.pathname)
        //navigate(location.pathname)
      
      };

    }
    //
    }, []);
    
   

    let routeblog = (         
      <Routes>
        
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/page-register' element={<SignUp />} />
        <Route exact path='/page-forgot-password' element={<ForgotPassword />} />
        
        <Route 
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
      </Routes> 
    );
    if (props.isAuthenticated) {
		return (
			<>
          <Suspense fallback={
              <div id="preloader">
                  <div className="sk-three-bounce">
                      <div className="sk-child sk-bounce1"></div>
                      <div className="sk-child sk-bounce2"></div>
                      <div className="sk-child sk-bounce3"></div>
                  </div>
              </div>  
              }
          >
          <Alert></Alert>
            <Index isAuth={props.isAuthenticated} status= {props.asg} /> 
          </Suspense>
         
      </>
  );
	
	}else{
		return (
			<div className="vh-100">
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
              }
            >
                {routeblog}
                <Alert></Alert>
            </Suspense>
			</div>
		);
	}
};


const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
        asg: profileStatus(state),
    };
};

//export default connect((mapStateToProps)(App)); 
export default withRouter(connect(mapStateToProps)(App)); 
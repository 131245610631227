import React from 'react';
//import { useNavigate } from "react-router-dom";

import { setAlert } from './alertAction';
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const SET_PROFILE_STATUS = '[profile action] profile status action';
export const CLEAR_PROFILE = '[profile action] clear profile status action';


export function signupAction(signupdata, navigate) {
	
    return (dispatch) => {
        signUp(signupdata)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                //history,
            );
            dispatch(confirmedSignupAction(response.data));
           
          // navigate('/dashboard');
           navigate('/welcome');     
			//history.push('/dashboard');
        })
        .catch((error) => {
           // const errorMessage = formatError(error?.response?.data);
           // dispatch(signupFailedAction(errorMessage));
           dispatch(
            setAlert(error.response.data.errors[0].msg, 'error')
            
        );

        dispatch(loadingToggleAction(false));

           console.error(error)
        });
    };
}

export function Logout(navigate) {
	localStorage.removeItem('userDetails');
    
	//history.push('/login');
    
	return (dispatch) => {
        dispatch({  type: CLEAR_PROFILE})
        dispatch({  type: LOGOUT_ACTION})
         dispatch({
            type: 'RESET'
        })
        navigate('/login');
      
    }
}

export function loginAction(username, password,navigate) {
    
    return (dispatch) => {
         login(username, password)
            .then((response) => { 
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    navigate,
                );
                // console.log(response.data);
               
               // dispatch(setProfileStatus());
               dispatch(loginConfirmedAction(response.data));
               dispatch(setAlert('Login Success', 'success'));
               
			   //console.log('kk------1');
			   //console.log(kk);
			  // console.log(response.data);
			   //console.log('kk------2');
			   //return response.data;
				//return 'success';
				//history.push('/dashboard');   
                
                // if(isProfileStatus)
				  //   navigate('/dashboard');   
                //  else
                  navigate('/welcome');   

            })
            .catch((error) => {
				//console.log('error'); 
				//console.log(error?.response?.data);
              //  const errorMessage = formatError(error?.response?.data);
                dispatch(loginFailedAction(error.response.data.errors[0].msg));
                dispatch(
                    setAlert(error.response.data.errors[0].msg, 'error'))
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}










export const     CONFIRMED_GET_NOTES= '[Note Action] Confirmed Get Notes';
export const     CONFIRMED_GET_NOTE= '[Note Action] Confirmed Get Note';
export const CONFIRMED_CREATE_NOTE_ACTION = '[Note Action] Confirmed Create Note';
export const CONFIRMED_CREATE_NOTE_ITEM_ACTION = '[Note Action] Confirmed Create Note Item';
export const CONFIRMED_EDIT_NOTE_ACTION =
    '[Note Action] Confirmed Edit Note';
    export const CONFIRMED_DELETE_NOTE_ACTION =    '[Note Action] Confirmed Delete Note';
    export const NOTE_ERROR =    '[Note Action] Confirmed Note Error';
    export const CONFIRMED_GET_GUEST_BY_PHONE =    '[Note Action] Confirmed Guest By Phone';
    export const NOTE_CLEAR =    '[Note Action] Confirmed Clear Client Detail';
    export const CONFIRMED_GET_NOTE_CLIENTS =    '[Note Action] Confirmed Get Note Client Detail';
import {
    createClient,
    getClient,
    getClients,
  
    updateClient,
    updateClientName,
    deleteClient
} from '../../services/ClientApi';
import {
    CONFIRMED_CREATE_CLIENT_ACTION,
    CONFIRMED_CREATE_CLIENT_ITEM_ACTION,
    CONFIRMED_DELETE_CLIENT_ACTION,
    CONFIRMED_EDIT_CLIENT_ACTION,
    CONFIRMED_GET_CLIENTS,
    CONFIRMED_GET_CLIENT,
    CLIENT_ERROR
} from './ClientType';

import {
    setAlert 
    } from './alertAction';

// // Create or update Client
// export const createClient =
//   (formData, navigate, edit = false) =>
//   async (dispatch) => {
//     try {
//       const res = await createClient('/profile', formData);

//       dispatch({
//         type: GET_CLIENT,
//         payload: res.data
//       });

//       dispatch(
//         setAlert(edit ? 'Profile Updated' : 'Profile Created', 'success')
//       );

//       if (!edit) {
//         navigate('/dashboard');
//       }
//     } catch (err) {
//       const errors = err.response.data.errors;

//       if (errors) {
//         errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
//       }

//       dispatch({
//         type: PROFILE_ERROR,
//         payload: { msg: err.response.statusText, status: err.response.status }
//       });
//     }
//   };

// // Add Experience
// export const addClientItem = (formData, navigate) => async (dispatch) => {
//   try {
//     const res = await api.put('/profile/experience', formData);

//     dispatch({
//       type: UPDATE_PROFILE,
//       payload: res.data
//     });

//     dispatch(setAlert('Experience Added', 'success'));

//     navigate('/dashboard');
//   } catch (err) {
//     const errors = err.response.data.errors;

//     if (errors) {
//       errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
//     }

//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };


export function deleteClientAction(clientId, history) {
    return (dispatch, getState) => {
        deleteClient(clientId).then((response) => {
            dispatch(confirmedDeletetClientAction(clientId));
            dispatch(
                setAlert('Client Deleted', 'success')
            );
           // history.push('/Clients');
        });
    };
}


export function confirmedDeletetClientAction(clientId) {
    return {
        type: CONFIRMED_DELETE_CLIENT_ACTION,
        payload: clientId,
    };
}




export function createClientAction(clientData) {
 
	return (dispatch, getState) => {
        try {
          //  console.log(UserData);
            createClient(clientData).then((response) => {


              dispatch(
                setAlert('client Created', 'success')
              );

                dispatch(confirmedCreateClientAction(response.data));
               // const status =   edit ? 'User Updated' : 'User Created'
             //   const msg_status = {successMessage: status}
                //   dispatch({
                //       type: CREATE_STATUS,
                //       payload: msg_status
                //     });
                //navigate('/Clients');
            }).catch(function (error) { 
                
                dispatch(
                setAlert(error.response.data.errors[0].msg, 'error') 
                );
                
                dispatch({
                    type: CLIENT_ERROR,
                    payload: { msg: error.response.statusText, status: error.response.status }
                  });
            
            });
            
        } catch (err) {
            dispatch({
                type: CLIENT_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
              });
             

       }
       
    };
}
export function createClientItemAction(clientData) {
 
	return (dispatch, getState) => {
        try {
        
           updateClientName(clientData).then((response) => {


              dispatch(
                setAlert('Client Item Created', 'success')
              );

                dispatch(confirmedCreateClientItemAction(response.data));
            });
            
        } catch (err) {
            dispatch({
                type: CLIENT_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
              });
             

        }
       
    };
}

export function getClientsAction() {
    return (dispatch, getState) => {
        getClients().then((response) => {
      
        
            dispatch(confirmedGetClientsAction(response.data));
          
        });
    };
}

export function getCurrentClientAction(clientId) {
    return (dispatch, getState) => {
        getClient(clientId).then((response) => {

            dispatch(confirmedGetClientAction(response.data));
          
        });
    };
}

export function confirmedCreateClientAction(singleClient) {
	
    return {
        type: CONFIRMED_CREATE_CLIENT_ACTION,
        payload: singleClient,
    };
}

export function confirmedCreateClientItemAction(singleClient) {
	
    return {
        type: CONFIRMED_CREATE_CLIENT_ITEM_ACTION,
        payload: singleClient,
    };
}

export function confirmedGetClientsAction(clients) {
    return {
        type: CONFIRMED_GET_CLIENTS,
        payload: clients,
    };
}

export function confirmedGetClientAction(client) {
    return {
        type: CONFIRMED_GET_CLIENT,
        payload: client,
    };
}



export function updateClientAction(client, history) {
    return (dispatch, getState) => {
        updateClient(client).then((response) => {
            dispatch(confirmedUpdateClientAction(response.data));
           // history.push('/Clients');
           dispatch(
            setAlert('Client Updated', 'success')
          );

        }).catch(function (error) { 
            
                    dispatch(
                    setAlert(error.response.data.errors[0].msg, 'error')
                );

                dispatch({
                    type: CLIENT_ERROR,
                    payload: { msg: error.response.statusText, status: error.response.status }
                  });

             }
         );
			
    };
}

export function confirmedUpdateClientAction(client) {

    return {
        type: CONFIRMED_EDIT_CLIENT_ACTION,
        payload: client,
    };
}


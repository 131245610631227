import React,{ Fragment,useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import {Modal,Button,Col,Dropdown, Tab, Nav} from 'react-bootstrap';
import { connect } from "react-redux";
import room4 from './../../../images/room/room4.jpg';
import room5 from './../../../images/room/room5.jpg';
import room6 from './../../../images/room/room6.jpg';
import room7 from './../../../images/room/room7.jpg';
//import GuestCarousel from './Room/GuestCarousel';
import Spinner from '../../layouts/Spinner';
import { createRoomAction,getRoomsAction } from "../../../store/actions/RoomActions";
import Available from './Room/Available';
import Booked from './Room/Booked';
import PropTypes from "prop-types";
import { compareAsc,format } from 'date-fns'
import swal from "sweetalert";

const DropdownBlog = ({setAddCard}) =>{
	return(
		<>
			<Dropdown className="dropdown">
				<Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu">
					<Dropdown.Item className="dropdown-item" as="button" >
                                <div onClick={()=> setAddCard(true)}>
                                    Sync CA
                                </div></Dropdown.Item>
					<Dropdown.Item className="dropdown-item">Delete</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}
const COMA_SEPARATED_REGEX = /^[0-9a-zA-Z]+(,[0-9a-zA-Z]+)*$/


const Rooms = ({ getRoomsAction, createRoomAction,room: { lists, showLoading,successMessage,errorMessage } }) =>{
	const [selectBtn, setSelectBtn] = useState("Newest");
	// const [id, setId] = useState();
	const [data, setData] = useState(
		document.querySelectorAll("#room_wrapper tbody tr")
	);
	const sort = 10;
	const activePag = useRef(0);
	const [test, settest] = useState(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
	const [addCard, setAddCard] = useState(false);
   // use effect
   useEffect(() => {
      setData(document.querySelectorAll("#room_wrapper tbody tr"));
      //chackboxFun();
	}, [test]);

	useEffect(() => {
		getRoomsAction();
	  }, [getRoomsAction]);


// Add Room function
const handleAddFormChange = (event) => {
	event.preventDefault();    
	const fieldName = event.target.getAttribute('name');
	const fieldValue = event.target.value;
	const newFormData = {...addFormData};
	newFormData[fieldName] = fieldValue;
	setAddFormData(newFormData);
};


const [addFormData, setAddFormData ] = useState({
	title:'',
	desc:'',
	maxPeople:'',
	price:'',
	room:'',
	
}); 


  //Add Submit data
    const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        var error = false;
		var errorMsg = '';
        if(addFormData.title === ""){
            error = true;
			errorMsg = 'Please fill  Room title';
        }else if(addFormData.price === ""){
            error = true;
			errorMsg = 'Please fill Room Rate/Price';
        }else if(addFormData.desc === ""){
			error = true;
			errorMsg = "please fill Room Desc";
		
        
        }else if(addFormData.maxPeople === ""){
			error = true;
			errorMsg = "please fill Room MaxPeople";
		
        }else if(addFormData.room === "" ){
			error = true;
			errorMsg = "please fill Room number";
		
        }else if(!COMA_SEPARATED_REGEX.test(addFormData.room)){
			error = true;
			errorMsg = "please fill in valid COMA separated Room number e.g 201, 201A";
		}
        if(!error){

			const roomNumberarr = addFormData.room.split(",").map((room) => ({ number: room }));
           
			const newContent = {
                // id: nanoid(),
		
                title: addFormData.title,
                price:  addFormData.price,
                desc:  addFormData.desc,
                maxPeople:  addFormData.maxPeople,

				roomNumbers:[...roomNumberarr]
             
			};
			
         // console.log(newContent)  
         
		 createRoomAction(newContent);
			//const newList = [...list, list];
            //setList(newList);
		//	addFormData.title  = addFormData.price = addFormData.maxPeople = addFormData.room = addFormData.desc='';      
            //setAddCard(false);
            swal('Good job!', 'Room Added', "success");
              
            return false
        }else{
			swal('Oops', errorMsg, "error");
		}
    };

   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};

	const isAvailable = (unavailableDates) => {
	
		let today = new Date().toLocaleDateString();
		
		const dates = unavailableDates.map( (date1)=> new Date(date1).getTime())
	//	console.log(dates);
	//	console.log(new Date(today).getTime());

		const isFound = dates.includes(new Date(today).getTime())
	
	
		return isFound;
	  };
   
	const chackbox = document.querySelectorAll(".sorting_7 input");
	const motherChackBox = document.querySelector(".sorting_asc_7 input");
   // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);
	const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
         const element = chackbox[i];
         if (type === "all") {
            if (motherChackBox.checked) {
               element.checked = true;
            } else {
               element.checked = false;
            }
			} else {
				if (!element.checked) {
				   motherChackBox.checked = false;
				   break;
				} else {
				   motherChackBox.checked = true;
				}
			}
		}
    };
	return(
		<>
			<Tab.Container defaultActiveKey="All">
				<div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
					<div className="card-action coin-tabs mb-2">
						<Nav as="ul" className="nav nav-tabs" role="tablist">
							<Nav.Item as="li" className="nav-item">
								<Nav.Link className="nav-link" eventKey="All">All Rooms</Nav.Link>
							</Nav.Item>
							<Nav.Item as="li" className="nav-item">
								<Nav.Link className="nav-link" eventKey="Available">Available Room</Nav.Link>
							</Nav.Item>
							<Nav.Item as="li" className="nav-item">
								<Nav.Link className="nav-link" eventKey="Booked">Booked</Nav.Link>
							</Nav.Item>
						</Nav>
					</div>
					<div className="d-flex align-items-center mb-2"> 
						<Link onClick={()=> {setAddCard(true);   }} className="btn btn-secondary">+ Add New Room</Link>
						<div className="newest ms-3">
							<Dropdown>
								<Dropdown.Toggle as="div" className=" btn-select-drop default-select btn i-false">
									{selectBtn} <i className="fas fa-angle-down ms-2 "></i>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={()=>setSelectBtn("Oldest")} eventKey="All">Oldest</Dropdown.Item>
									<Dropdown.Item onClick={()=>setSelectBtn("Newest")} eventKey="All">Newest</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown> 
						</div>	
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-xl-12">
					<Modal className="fade bd-example-modal-lg" size="lg"  show={addCard} onHide={setAddCard} >
					<form >
					<Modal.Header>
                    <Modal.Title><b>CREATE ROOM / ROOM NUMBERS</b></Modal.Title>
                    <button type="button" className="btn-close" onClick={()=> setAddCard(false)} data-dismiss="modal"><span></span></button>
                  </Modal.Header>
						
				  
							
			<Modal.Body>
                    
				  <div className="row ">
                    <div className="form-group mb-3 col-md-6">
                      <label><b>Room Title</b></label> 
						<input type="text" className="form-control {(addFormData.client)?'text-primary':''}" autoComplete="off"
							name="title" required="required"
							onChange={handleAddFormChange}
							placeholder="Enter Room Title e.g Deluxe Single Room Double Bed"
							value={addFormData.title}
							
						/>
                    </div>

                    <div className="form-group mb-3 col-md-4 {(addFormData.client)?'text-primary':''}">
                      <label><b>Room Rate / Price</b></label>
						<input type="number" className="form-control" autoComplete="off"
							name="price"
							onChange={handleAddFormChange}
							placeholder="Enter Rate/Night e.g 12500"
							value={addFormData.price}
							
						/>
                    </div>
                   
               </div>
			   <div className="row">
				
						<div className="form-group mb-3 col-md-9">
							<hr size='70%'/>

						</div>
					
					</div>
			   <div className="row">
				
					<div className="form-group mb-3 col-md-10 ">
							<label><b>Room Desc / Facility</b></label>
							<textarea
					required="required"
                      className="form-control"
                      rows="4"
                      
					  id="desc"
					  name="desc" 
					  onChange={handleAddFormChange}
					  placeholder="Enter Description e.g King size bed with children court, A.C, Fridge"
					 
                    >{addFormData.desc}</textarea>
							
						</div>
					
					</div>
				  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label><b>Rooms Numbers</b></label>
					  <textarea
					required="required"
                      className="form-control"
                      rows="4"
                      id="room"
					
					  name="room" 
					  onChange={handleAddFormChange}
					  placeholder="Enter Comma Separated Room Numbers"
					
                    >{addFormData.room}</textarea>


                    </div>
                    
					<div className="form-group mb-3 col-md-4 {(addFormData.client)?'text-primary':''}">
                      <label><b>Max People per room</b></label>
						<input type="number" className="form-control" autoComplete="off"
							name="maxPeople"
							onChange={handleAddFormChange}
							placeholder="Enter Max People per room"
							value={addFormData.maxPeople}
							
						/>
                    </div>
                   
               </div>

			</Modal.Body>
             <Modal.Footer>
                    <Button
                      variant="danger light"
					  onClick={()=> setAddCard(false)}
                    >
                      Close
                    </Button>
                    <Button
                      variant=""
                      type="submit"
                      className="btn btn-primary"
					  onClick={handleAddFormSubmit}
				//	  disabled={(addFormData.room && addFormData.desc && addFormData.price && addFormData.title)?'':'disabled'}
                    >
                      Save changes
                    </Button>
              </Modal.Footer>
               
                  
							</form>
				</Modal>
						<div className="card">
							<div className="card-body p-0">
								<Tab.Content>	
									<Tab.Pane eventKey="All">
										<div className="table-responsive">
											<div id="room_wrapper" className="dataTables_wrapper no-footer">
												<table className="table card-table display mb-4 dataTablesCard booking-table room-list-tbl dataTable no-footer">
													<thead>
														<tr role="row">
															<th className="sorting_asc_7 bg-none" >
																<div className="form-check  style-1">
																	<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required=""/>
																</div>
															</th>
															<th>Room </th>
															<th>Room Description</th>
															{/* <th>Room Floor</th>
															<th>Facility</th> */}
															<th>Rate</th>
															<th>Status</th>
															<th className="bg-none"></th>
														</tr>
													</thead>
													<tbody>
													{showLoading ? (
														<Spinner />
														) : 
																											(
															<Fragment>

		{lists.length > 0 ? (
			lists.map((list,key) => (
												
				list?.roomNumbers?.map((room,key) => {
					const uDates = room.unavailableDates.sort(compareAsc)
					return (
				<tr role="row" className={(key%2===0)?'even':'odd'} key={room._id}>
				<td className="sorting_7">  
					<div className="form-check   style-1">
						<input type="checkbox" onClick={() => chackboxFun()}
							className="form-check-input" id="customCheckBox21" required=""
						/>
					</div>  
				</td>
				<td>
					<div className="room-list-bx d-flex align-items-center">
						<img className="me-3 rounded" src={(key%2===0)?room4:room5} alt="" />
						<div>
							<span className=" text-secondary fs-14 d-block">#{room.number}</span>
							<span className=" fs-16 font-w500 text-nowrap">{list.title}</span>
							
						</div>
					</div>
				</td>
				<td className="">
					<span className="facility">{list.desc}</span>
				</td>
				{/* <td>
					<div>
						
						<span className="fs-16 font-w500">Floor A-1</span>
					</div>
				</td>
				<td className="facility">
					<div>
						<span className="fs-16 comments">AC, Shower, Double Bed, Towel, Bathup, Coffee Set, LED TV, Wifi</span>
					</div>
				</td> */}
				<td>
					<div className="">
						<span className="mb-2">Price </span>	
						<span className="font-w500">&#8358;{list.price.toLocaleString("en-US")}<small className="fs-14 ms-2">/night</small></span>
					</div>
				</td>
				<td>
					<Link to={"#"} className= {!isAvailable(room.unavailableDates)?'btn btn-success btn-md':'btn btn-danger btn-md'} >{(!isAvailable(room.unavailableDates))?'AVAILABLE':'BOOKED'}</Link>
					<span className=" text-secondary fs-14 d-block text-wrap"><b>{ isAvailable(room.unavailableDates)? `Unavailable Dates:`:``}</b></span> 
					<span className=" text-secondary fs-14 d-block text-wrap"><b>{ isAvailable(room.unavailableDates)? `[${format(new Date(uDates[0]), 'dd-MM-yyyy') }] to [${format(new Date(uDates[uDates.length-1]), 'dd-MM-yyyy') }]`:``}</b></span> 
				</td>
				<td><DropdownBlog setAddCard={setAddCard} /></td>
			</tr>)})
													
														 ))) : (
															<h4>No rooms found...</h4>
														  )}
		</Fragment>
      )			}			 
													</tbody>
												</table>
												<div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
													<div className="dataTables_info">
														Showing {activePag.current * sort + 1} to{" "}
														{data.length > (activePag.current + 1) * sort
															? (activePag.current + 1) * sort
															: data.length}{" "}
														of {data.length} entries
													</div>
													<div
														className="dataTables_paginate paging_simple_numbers mb-0"
														id="example2_paginate"
													>
														<Link
															className="paginate_button previous disabled"
															to="/room-list"
															onClick={() =>
															   activePag.current > 0 &&
															   onClick(activePag.current - 1)
															}
														 >
															<i className="fa fa-angle-double-left"></i> Previous
														</Link>
														<span>
															{paggination.map((number, i) => (
															   <Link key={i} to="/room-list"
																  className={`paginate_button  ${
																	 activePag.current === i ? "current" : ""
																  } `}
																  onClick={() => onClick(i)}
															   >
																  {number}
															   </Link>
															))}
														</span>

														<Link
															className="paginate_button next"
															to="/room-list"
															onClick={() =>
															   activePag.current + 1 < paggination.length &&
															   onClick(activePag.current + 1)
															}
														>
															Next <i className="fa fa-angle-double-right"></i>
														</Link>
													</div>
												</div>
											</div>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="Available">
										<Available lists={lists}  showLoading={showLoading} successMessage={successMessage} errorMessage= {errorMessage} />	
									</Tab.Pane>
									<Tab.Pane eventKey="Booked">
										<Booked lists={lists}  showLoading={showLoading} successMessage={successMessage} errorMessage= {errorMessage} />	
									</Tab.Pane>
								</Tab.Content>
							</div>	
						</div>	
					</div>	
				</div>		
			</Tab.Container>				
		</>
	)
}
//export default Rooms;
Rooms.propTypes = {
	getRoomsAction: PropTypes.func.isRequired,
	room: PropTypes.object.isRequired,
	createRoomAction:PropTypes.func.isRequired,
  };
  
  const mapStateToProps = (state) => ({
	room: state.RoomReducer
  });
  
  export default connect(mapStateToProps, { getRoomsAction,createRoomAction })(Rooms);
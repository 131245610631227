export const ROLES = [
    { value: "Employee", label: "Employee Role", color: "#FF8B00" },
    { value: "Admin", label: "Admin Role", color: "#FFC400" },
    { value: "Manager", label: "Manager Role", color: "#36B37E" },
]
export const PERMISSION = [
    { value: "Create-User", label: "Create User", color: "#FF8B00" },
    { value: "Edit-User", label: "Edit User", color: "#FFC400" },
    { value: "List-Users", label: "List Users", color: "#36B37E" },
]
export const DEPARTMENTS = [
    { value: "Admin Unit", label: "Admin Unit", color: "#FF8B00" },
    { value: "IT Unit", label: "IT Unit", color: "#FFC400" },
    { value: "Front Desk", label: "Front Desk", color: "#FFC400" },
    { value: "Canteen", label: "Canteen", color: "#FFC400" },
    { value: "SPA and Gym", label: "SPA and Gym", color: "#FFC400" },
    { value: "Swimming Pool", label: "Swimming Pool", color: "#FFC400" },
    { value: "Laundary and Dry Cleaning", label: "Laundary and Dry Cleaning", color: "#36B37E" },
    { value: "Party Palace", label: "Party Palace", color: "#36B37E" },
    { value: "Hall and Banquet", label: "Hall and Banquet", color: "#36B37E" },
]
export const DESIGNATION = [
    { value: "Hotel Manager", label: "Hotel Manager", color: "#FF8B00" },
    { value: "Head Accountant", label: "Head Accountant", color: "#FFC400" },
    { value: "IT Officer", label: "IT Officer", color: "#36B37E" },
]
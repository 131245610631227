import React, {  useEffect,Fragment, useState } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from 'react-form-stepper';
import { Link, useNavigate,useMatch, useParams } from "react-router-dom";
import StepOneBuyCredit from "./StepOneBuyCredit";
import StepTwoBuyCredit from "./StepTwoBuyCredit";
import PropTypes from "prop-types";
import api from "../../../../utils/api";
// import {
// 	BuyFund
//   } from "../../../../store/actions/profileAction";
// import StepThree from "./StepThree";
// import StepFour from "./StepFour";
import PageTitle from "../../../layouts/PageTitle";
import { usePaystackPayment, PaystackButton, PaystackConsumer } from '../../../../dist/index.es';
import { connect, useDispatch } from 'react-redux';
import swal from "sweetalert";

import {
	BuyFund,
	getCurrentProfile,
  } from "../../../../store/actions/profileAction";

const initialState ={


	receiver_username: '',
	credit: '',
	password: '',
	amount:0,
	unit: 0,
	purpose: ''


}
const RegisterPhone = ({
	profile: { profile, balance, loading },
	getCurrentProfile,
	BuyFund,
	auth: {phone},
	name,
	email
  
  }) => {
	const [goSteps, setGoSteps] = useState(0);

	const [ref, setRef] = useState('');
	const [user, setUser] = useState({});
	const [amountToPay, setAmountToPay] = useState({});
	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialState);
	const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
	const [payFlag,setPayFlag] = useState(true)
   
	
	const {
	
		receiver_username,
		credit,
		password,
		unit,
		purpose

		} = formData;

  
	let errorsObj = {		
	
			receiver_username:'',
			credit:'',
			password:'',
			unit: 0,
			purpose: ''

	 	};

		 useEffect(() => {
			const userData = { ...initialState };
			// if there is no profile, attempt to fetch one
			!profile && getCurrentProfile();
	
		  }, [loading, profile, getCurrentProfile]);

		 useEffect(() => {
			
			calculatedAmount()
	
		  }, [unit, purpose ]);




			const onClose = () => {
				// implementation for  whatever you want to do when the Paystack dialog closed.
				console.log('closed')
			}

			const onSuccess = (reference) => {
			
				//setRef(reference)
		
				let formData2 = {...formData, trans_ref: reference,  trans_pay_method: 'paystack', trans_amount: formData.credit, trans_name: 'Fund User Account - online' }
				
				BuyFund(formData2, navigate).then((res) => {
					if(res !=='Invalid Credentials'){
							swal(`User account funded!`, {
						icon: "success",
						});
						//setAddCard(false);
						navigate("/my-phones")
					}else{
						swal("Invalid Credentials!");
					}
					
				});
				
				
			};

			const componentProps = {
				//   ...config,
				reference: (new Date()).getTime().toString(),
				email:email,
				amount:amountToPay*100,
				metadata: {
					name,
					 phone,
				  },
				publicKey: publicKey,
				
			   //  lastname: 'story',
				   text: 'Proceed to CHECKOUT ',
				   onSuccess,
				   onClose
			};



	const calculatedAmount = ()=>{

		let calAmount = 0
		let price = 0
		let commision = 0
		let calAmountToPay = 0
		let credit = 1500
		let credit2 = 3500

		if( purpose === 'validate' && unit > 0 ) {
			price = profile?.appStatus? 1100:1500
			commision = 400 * unit
		}

		if( purpose === 'declare' && unit > 0 ) {
			price =  profile?.appStatus? 3200:3500
			commision = 300 * unit
		}

		calAmount = unit *  (purpose === 'declare'? credit2:credit)
		calAmountToPay = price * unit 
		 
		setFormData({ ...formData, credit: calAmount});
		setAmountToPay(calAmountToPay);

	}  

	const [errors, setErrors] = useState(errorsObj);

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });
		  
	

	// const onUnitChange = (e) =>
	//   setFormData({ ...formData, [e.target.name]: e.target.value });

	// const onPurposeChange = (e) =>
	//   setFormData({ ...formData, [e.target.name]: e.target.value });
	






   const  onSubmit = async ()=> {

       // e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

		if (credit == '') {
            errorObj.credit = 'Credit is Required';
            error = true;
			swal('Oops', errorObj.credit, "error");
        }
		if (purpose == '') {
            errorObj.purpose = 'Purpose is Required';
            error = true;
			swal('Oops', errorObj.purpose, "error");
        }
		if (unit == '') {
            errorObj.unit = 'Unit is Required';
            error = true;
			swal('Oops', errorObj.unit, "error");
        }
		
	

        setErrors(errorObj);

        if (error) return;
		setGoSteps(1)
	//	handleWallet(formData, navigate, false).then(() => navigate("/my-phones"));


    }
	   
	return (
		<Fragment>
			{/* <PageTitle activeMenu="Add Phone Service" motherMenu="Home" /> */}

			
			<div className='row'>
					<div className='col-lg-4 mb-2'>
					<button type="button" className="btn btn-warning" onClick={() => navigate(-1)}> <i className="flaticon-delete-1"></i> Discard</button> 
					</div>
				
					
				
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header">
							 <h4 className="card-title">Buy Bulk Credit</h4>
							{/*<h6>New Phone Registration Fee</h6>  */}
							
						</div>
						{/* <form onSubmit={(e) => onSubmit(e)}> */}
								<div className="card-body">
								<div className="text-center mb-3">
									
								{/* <h4 className="card-title">Phone Registration Service</h4> */}
								{/* <h6>My Credit Balance</h6>
								
								<Fragment>
									<div>&#8358;{balance}</div> 
								</Fragment> */}
								
								
								
                   		 </div>
							<div className="form-wizard ">

								{/* <Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} />
									<Step className="nav-link" onClick={() => setGoSteps(3)} />
								</Stepper> */}
								
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									{/* <Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} /> */}
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
								</Stepper>

							  {goSteps === 0 && (
								<>
								   <StepOneBuyCredit 
									formData={formData} 
									onChange={onChange}
									amountToPay={amountToPay}
									 />	
									
									<div className="text-end toolbar toolbar-bottom p-2"> 
										<button  className="btn btn-warning sw-btn-next" onClick={(e) => onSubmit() } >Next</button>
									</div>	
								</>
							  )}
{/* setGoSteps(1) */}
{/* onClick={(e) => checkReferral(e)} */}
							  {/* {goSteps === 1 && (
								<>
									<StepTwo />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1" onClick={() => setGoSteps(2)}>Next</button>
									</div>	
								</>
							  )}
							  {goSteps === 2 && (
								<>
									<StepThree />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(3)}>Next</button>
									</div>	
								</>
							  )} */}
							  {goSteps === 1 && (
								<>
									<StepTwoBuyCredit formData={formData} 	user={user} amountToPay={amountToPay} />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-warning sw-btn-prev me-1" onClick={() => {setGoSteps(0); }}>Prev</button>
										

										
        									  <Fragment>						
												<PaystackButton className="btn btn-secondary my-1" {...componentProps} />
											</Fragment>
           									
									</div>	
								</>	
							  )}
							  
							</div>
						</div>
						{/* </form> */}
						

					</div>
				</div>
			</div>
		</Fragment>
	);
};


RegisterPhone.propTypes = {

	BuyFund: PropTypes.func.isRequired,
	
	profile: PropTypes.object.isRequired,
	
	getCurrentProfile: PropTypes.func.isRequired,

  };
  const mapStateToProps = (state) => ({
	profile: state.profileReducer,
	name: state.auth.auth.name,
	email: state.auth.auth.email,
	auth: state.auth.auth,


  });

  const mapDispatchToProps = { BuyFund,getCurrentProfile};

  export default connect(mapStateToProps, mapDispatchToProps)(RegisterPhone);
import React, { Fragment } from "react";
import {  Button, Alert} from "react-bootstrap";

const StepOne = ({formData,
  onChange,
  changeCountry,
  changeState,
  changeService,
  creatingProfile,
  displaySocialInputs,
  toggleSocialInputs,
  stateLGA,
  province,
  serviceFlag,
  lgovt,
  validationFlag
}) => {

   return (
      <section>
         <div className="row">
         <div className="my-6">
          {/* <button
           
            type="button"
            className="btn btn-danger"
          >
           PREVIEW
           
          </button> */}
          {/* <span className="mx-4">Optional</span> */}
        </div>
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label"><strong>Your Name*</strong></label>
                  
                  <div>{formData.yourname}</div>
                  {/* <input
                     type="text"
                     placeholder="Father's Name"
                     className="form-control"
                     name="father"
                     value={formData.father}
                     onChange={onChange}
                     required
                  /> 
                 
          <small className="form-text">
            your surname or Guardian's name whose name you bear
          </small>*/}
               </div>
            </div>
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label"><strong>Sex*</strong></label>
                  
                  <div>{formData.sex}</div>
                  {/* <input
                     type="text"
                     placeholder="Father's Name"
                     className="form-control"
                     name="father"
                     value={formData.father}
                     onChange={onChange}
                     required
                  /> 
                 
          <small className="form-text">
            your gender
          </small>*/}
               </div>
            </div>
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label"><strong>Address*</strong></label>
                  
                  <div>{formData.address}</div>
                  {/* <input
                     type="text"
                     placeholder="Father's Name"
                     className="form-control"
                     name="father"
                     value={formData.father}
                     onChange={onChange}
                     required
                  /> 
                 
          <small className="form-text">
          your location, where you currently stay.
          </small>*/}
               </div>
            </div>
            {/* <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Profession Status*</label>
                  <div>{formData.status}</div>
                  <select name="status" value={formData.status}  onChange={onChange} className="form-control">
            <option>* Select Professional Status</option>
            <option value="Developer">Developer</option>
        
            <option value="Project Manager">Project Manager</option>
          
            <option value="Accountant">Accountant</option>
            <option value="Lawyer">Lawyer</option>
            <option value="Intern">Intern</option>
            <option value="Other">Other</option>
          </select>
          <small className="form-text">
            Give us an idea of where you are at in your career
          </small>
               </div>
            </div> */}
            {/* <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Company*</label>
                  <div>{formData.company}</div>
                  <input
               type="email"
               className="form-control"
               id="inputGroupPrepend2"
               aria-describedby="inputGroupPrepend2"
            placeholder="Company"
            name="company"
            value={formData.company}
            onChange={onChange}
          />
          <small className="form-text">
            Could be your own company or one you work for
          </small>
               </div>
            </div> */}
            {/* <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Website*</label>
                  <div>{formData.website}</div>
                  <input
            type="text"
            placeholder="Website"
            name="website"
            value={formData.website}
            onChange={onChange}
            className="form-control"
          />
          <small className="form-text">
            Could be your own or a company website
          </small>
               </div>
            </div> */}
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label"><strong>Country*</strong></label>
                  <div>{formData.country}</div>
                  {/* <select name="country"  value={formData.country}  onChange={changeCountry} className="form-control">
    <option value=''>Country</option>
    <option value='Nigeria'>Nigeria</option>
    <option value='US'>US</option>
    <option value='UK'>UK</option>
    <option value='Canada'>Canada</option>
  
    
    </select> 
          <small className="form-text">
            Select the Country where you currently reside / domicile
          </small>*/}
               </div>
            </div>
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label"><strong>State*</strong></label>
                  <div>{formData.state}</div>
                  {/* <select name="state" value={formData.state} className="form-control" onChange={changeState} >
    <option>* Select State</option>
    {  creatingProfile && (province==='Nigeria' && Object.entries(stateLGA)?.map(([key,val]) => (
      <option key={key} value={key}>{key}</option>
    )))}

    {  !creatingProfile && (formData.country==='Nigeria' && Object.entries(stateLGA)?.map(([key,val]) => (
      <option key={key} value={key}>{key}</option>
    )))}


    </select> 
          <small className="form-text">
            City & state suggested (eg. Lagos, LA)
          </small>*/}
       
               </div>
            </div>
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label"><strong>LGA*</strong></label>
                  <div>{formData.lga}</div>
                  {/* <select name="lga" value={formData.lga} className="form-control" onChange={onChange}  >
                  <option>* Select LGA</option>
          { 
     
     creatingProfile &&
       (   lgovt  && Object.entries(stateLGA[lgovt])?.map(([key,val]) => (
                  <option key={key} value={val}>{val}</option>
                )))
          
          } 
          { 
     
     !creatingProfile &&
       (   formData.state  && Object.entries(stateLGA[formData.state])?.map(([key,val]) => (
                  <option key={key} value={val}  >{val}</option>
                )))
          
          } 
          </select> 
          <small className="form-text">
            Local Government Area[LGA] of the place you currently stay or leave
          </small>*/}
       
               </div>
            </div>

            {/* <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Skills*</label>
                  <div>{formData.skills}</div>
             
        <input
            type="text"
            placeholder="* Skills"
            name="skills"
            value={formData.skills}
            onChange={onChange}
            className="form-control"
          />
          <small className="form-text">
            Please use comma separated values (eg. Public presenting, Oratory Speaker,Leadership,HTML,CSS,JavaScript,PHP)
          </small>
               </div>
            </div> */}
            {/* <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Github Username*</label>
                  <div>{formData.githubusername}</div>
       
     <input
            type="text"
            placeholder="Github Username"
            name="githubusername"
            value={formData.githubusername}
            onChange={onChange}
            className="form-control"
          /> 
          <small className="form-text">
            Applicable to software engineers ONLY. If you want your latest repos and a Github link, include your
            username
          </small>
               </div>
            </div>  */}
           <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label"><strong>Phone no*</strong></label>
                  <div>{formData.phoneno}</div>
             
        {/* <input
            type="text"
            placeholder="1st Phone Number"
            name="phoneno"
            value={formData.phoneno}
            onChange={onChange}
            className="form-control"
          /> 
          <small className="form-text">
           primary Phone number
          </small>*/}
               </div>
            </div>
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label"><strong>2nd Phone Number*</strong></label>
                  <div>{formData.phoneno2}</div>
       
      {/* <input
            type="text"
            placeholder="2nd Phone Number"
            name="phoneno2"
            value={formData.phoneno2}
            onChange={onChange}
            className="form-control"
          /> 
          <small className="form-text">
          Secondary Phone number
          </small>*/}
               </div>
            </div> 
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  {/* <label className="text-label"><strong>Date of Birth*</strong></label>
                  <div>{formData.birthday}</div> */}
       
      {/* <input
            type="text"
            placeholder="Date of Birth"
            name="birthday"
            value={formData.birthday}
            onChange={onChange}
            className="form-control"
          /> 
          <small className="form-text">
          *Date of Birth(18 yrs and above validation)
          </small>*/}
               </div>
            </div> 
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label"><strong>Referral Code*</strong></label>
                  <div>{formData.referralno}</div>
    
      {/* <input
                type="text"
                placeholder="Referral No"
                name="referralno"
              
                value={formData.referralno}
                onChange={onChange}
                className="form-control"
              />
               <small className="form-text">
                 Referral No. is unique to a member. Ask your agent for it. 
               </small> */}
               </div>
            </div>  
            {/* <div className="col-lg-12 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Bio*</label>
                  <div>{formData.bio}</div>
                   <textarea
            placeholder="A short bio of yourself"
            name="bio"
            value={formData.bio}
            onChange={onChange}
            className="form-control"
            required
          />
          <small className="form-text">Tell us a little about yourself</small>
               </div>
            </div> */}

{/* 
            <div className="my-2">
          <button
            onClick={toggleSocialInputs(!displaySocialInputs)}
            type="button"
            className="btn btn-danger"
          >
            Social Network Links
          </button>
          <span className="mx-4">Optional</span>
        </div> */}
        {/* {!displaySocialInputs &&  (  
        <Fragment> */}
         {/* <div className="col-lg-4 mb-4"><div className="form-group mb-3">
         <i className="fab fa-twitter fa-2x" />
         <div>{formData.twitter}</div> */}
              {/* <input
                type="text"  className="form-control"
                placeholder="Twitter URL"
                name="twitter"
                value={formData.twitter}
               onChange={onChange}
              /> */}
            {/* </div>  </div> */}

               {/* <div className="col-lg-4 mb-4"><div className="form-group mb-3">
             
             <i className="fab fa-facebook fa-2x" />
               <div>{formData.facebook}</div>
               <input
                type="text"  className="form-control"
                placeholder="Facebook URL"
                name="facebook"
                value={formData.facebook}
               onChange={onChange}
              />
            </div>  </div> */}

               {/* <div className="col-lg-4 mb-2"><div className="form-group mb-3">
             
             <i className="fab fa-youtube fa-2x" /> 
              <div>{formData.youtube}</div>
               <input
                type="text"  className="form-control"
                placeholder="YouTube URL"
                name="youtube"
                value={formData.youtube}
               onChange={onChange}
              />
            </div>  </div> */}

               {/* <div className="col-lg-4 mb-2"><div className="form-group mb-3">
              <i className="fab fa-linkedin fa-2x" />
              <div>{formData.linkedin}</div>
              <input
                type="text"  className="form-control"
                placeholder="Linkedin URL"
                name="linkedin"
                value={formData.linkedin}
               onChange={onChange}
              />
            </div>  </div> */}

               {/* <div className="col-lg-4 mb-2"><div className="form-group mb-3">
              <i className="fab fa-instagram fa-2x" />
              <div>{formData.instagram}</div>
              <input
                type="text"  className="form-control"
                placeholder="Instagram URL"
                name="instagram"
                value={formData.instagram}
               onChange={onChange}
              />
            </div>  </div>
         */}
           {validationFlag &&  (  
									<Fragment>
									<Alert  variant="warning"  className="solid alert-square">						
										<strong>Info! </strong> Check for correction before submition, 
										correction of submitted details has to do with sending complain to the admin 
									</Alert>
								</Fragment>
           		)}	
         </div>
      </section>
   );
};

export default StepOne;

import {
  GET_PROFILE,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  UPDATE_PROFILE,
  GET_PROFILES,
  GET_REPOS,
  NO_REPOS,
  SET_PROFILE_STATUS,
  CHANGE_STATUS,
  GET_PROFILES2,
  GET_PROFILES3,
  GET_PROFILES4,
  ADD_CREDIT,
  TRANSFER_CREDIT,
  UPDATE_PROFILE_VALIDATE,
  CHANGE_TNC
} from "../types";

const initialState = {
  profile: null,
  profiles: [],
  profiles2: [],
  profiles3: [],
  profiles4: [],
  credits: [],
  repos: [],
  loading: true,
  error: {},
  balance: 0

};

function profileReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE:
    case UPDATE_PROFILE:
     ///console.log(payload);
      return {
        ...state,
        profile: payload.profile,
        credits: payload.transfer,
        balance: payload.profile.balance,
        loading: false,
      };
      
    case UPDATE_PROFILE_VALIDATE:
     ///console.log(payload);
      return {
        ...state,
        profile: payload.profile,
       
        balance: payload.profile.balance,
        loading: false,
      };
    case CHANGE_TNC:
     ///console.log(payload);
      return {
        ...state,
        profile: payload.profile,
        loading: false,
      };
      
      case CHANGE_STATUS: 

		  const profiles = [...state.profiles];
       // profiles.push(action.payload);

        return {
            ...state,
            profiles,
            loading: false, 
        };

      case TRANSFER_CREDIT: 
      case ADD_CREDIT: 

      const credits = [...state.credits];
      credits.push(payload);

      return {
          ...state,
          credits,
          balance: state.balance - parseFloat(payload.amount),
          loading: false
      };



    case GET_PROFILES:
      return {
        ...state,
        profiles: payload,
        loading: false,
      };

    case GET_PROFILES2:
      return {
        ...state,
        profiles2: payload,
        loading: false,
      };

    case GET_PROFILES3:
      return {
        ...state,
        profiles3: payload,
        loading: false,
      };

    case GET_PROFILES4:
      return {
        ...state,
        profiles4: payload,
        loading: false,
      };

    case SET_PROFILE_STATUS:
      return {
        ...state,
        auth: payload,
    };
      


    case PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        profile: null,
      };


    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
        repos: [],
      };
    case GET_REPOS:
      return {
        ...state,
        repos: payload,
        loading: false,
      };
    case NO_REPOS:
      return {
        ...state,
        repos: [],
      };
    default:
      return state;
  }
}

export default profileReducer;

import React, { Fragment, useState,useEffect } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from 'react-form-stepper';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
//import StepThree from "./StepThree";
//import StepFour from "./StepFour";
//import PageTitle from "../../../layouts/PageTitle";
import LogoutPage from '../../../layouts/nav/Logout';
import logo from "../../../../images/logo-full.png";
import { Button, Alert} from "react-bootstrap";
import { PaystackButton } from '../../../../dist/index.es';
import { Link, useNavigate,useMatch } from "react-router-dom";

import swal from "sweetalert";



import {
	createProfile,
	getCurrentProfile,
	
  } from "../../../../store/actions/profileAction";
  import {
  
	getStatesLGA,
	getServiceFees
  } from "../../../../store/actions/statesLGAAction";


const initialState = {
	company: "",
	website: "",
	phoneno:"",
	phoneno2:"",
	birthday:"",
	location: "",
	sex: "",
	tnc: true,
	address: "",
	country: "",
	state: "",
	lga: "",
	father: "",
	yourname: "",
	status: "",
	skills: "",
	githubusername: "",
	profileStatus:"MEMBER",
	bio: "",
	twitter: "",
	facebook: "",
	linkedin: "",
	youtube: "",
	instagram: "",
	phoneModel: "",
	phoneNumber:"",
	phoneName: "",
	phoneStatus: "",
	emei: "",
   phonesno: "",
   referralno: "",
   nok: "",
   nokrel: "",
   nokphone1: "",
   nokphone2: ""
  
  };

const UserProfile = ({
	profile: { profile, loading },
	createProfile,
	getCurrentProfile,
	stateLGA:{stateLGA,serviceFees},
	getStatesLGA,
	getServiceFees,
	name,
	email,
	user
  
  }) => {
	
	const [goSteps, setGoSteps] = useState(0);
	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialState);
	const creatingProfile = useMatch("/welcome");
	const [province,setState] = useState('')
	const [lgovt,setLgovt] = useState('')
	const [tranxFee,setTranxFee] = useState(0)
	const [tranxDesc,setTranxDesc] = useState('')
	const [amount,setAmount] = useState(0)
	const [sum,setSum] = useState(0)
	const [serviceFlag,setServiceFlag] = useState(false)
	const [validationFlag,setValidationFlag] = useState(false)
	const [displaySocialInputs, toggleSocialInputs] = useState(false);
	const [displayPhoneInputs, togglePhoneInputs] = useState(false);

	const changeCountry = (e)=> {
		
		setState(e.target.value)
		setFormData({ ...formData, [e.target.name]: e.target.value });
	   }
	 
	 const changeState = (e)=>  {
	   setLgovt(e.target.value)
	   setFormData({ ...formData, [e.target.name]: e.target.value });
	 }
	 
	 const changeService = (e)=> {
	   const ArrItem = (e.target.value).split('-') 
	   setTranxDesc(ArrItem[0])
	   setTranxFee(ArrItem[1])
	   //setAmount(ArrItem[1]*100)
	  }


	  const ontncChanged = () => 
	{
	  
	  setFormData({ ...formData, tnc:formData.tnc?false:true});
	}
	  const onSuccess = (reference) => {
		// Implementation for whatever you want to do with reference and after success call.
		//setProfileStatus("MEMBER")
		
		
    	afterValidate()
		console.log('reference',reference);
	};
	
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log('closed')
	}
	const componentProps = {
		//   ...config,
		reference: (new Date()).getTime().toString(),
		email:email,
		amount:1000*100,
		publicKey: 'pk_test_a137d402b5975716e89952a898aad2832c961d69',
		name:name,
	   //  lastname: 'story',
		   text: 'Proceed to CHECKOUT',
		   onSuccess,
		   onClose
	   };
	  

	useEffect(() => {
		// if there is no profile, attempt to fetch one
		 if(!profile) getCurrentProfile();
		if (!loading) {
			getStatesLGA()
			
		}
		
		if (loading) {
		getServiceFees()
		  setServiceFlag(true)
		}
		// if we finished loading and we do have a profile
		// then build our profileData
		if (!loading && profile) {
		  const profileData = { ...initialState };
		  for (const key in profile) {
			if (key in profileData) profileData[key] = profile[key];
		  }
		  for (const key in profile.social) {
			if (key in profileData) profileData[key] = profile.social[key];
		  }
		  // the skills may be an array from our API response
		  if (Array.isArray(profileData.skills))
			profileData.skills = profileData.skills.join(", ");
		  // set local state with the profileData
		
		  setFormData(profileData);
		//   if(serviceFlag ){
		// 	//  
		// 	let sum2 = []
		// 	sum2 = [...found]
		// 	//setAmount(sum2[1][1]*100)
		// 	console.log(sum2)
		//   }
		}
	  }, [loading, getCurrentProfile, profile,getStatesLGA,setServiceFlag,serviceFlag,getServiceFees]);
	
	  const {
		company,
		website,
		location,
		country,
		state,
		lga,
		father,
		yourname,
		status,
		skills,
		profileStatus,
		githubusername,
		bio,
		twitter,
		facebook,
		linkedin,
		youtube,
		instagram,
		phoneModel,
		phoneNumber,
		phoneName,
		phoneStatus,
		tnc,
		emei,
	   phonesno,
	   phoneno,
	   phoneno2,
	   birthday,
	   address,
	   sex,
	   referralno,
	   nok,
	   nokrel,
	   nokphone1,
	   nokphone2
	  } = formData;


	  let errorsObj = {		
		company:'',
	  website:'',
	  location:'',
	  country:'',
	  state:'',
	  lga:'',
	  father:'',
	  yourname:'',
	  status:'',
	  skills:'',
	  profileStatus:'',
	  githubusername:'',
	  bio:'',
	  twitter:'',
	  facebook:'',
	  linkedin:'',
	  youtube:'',
	  instagram:'',
	  phoneModel:'',
	  phoneNumber:'',
	  phoneno:'',
	  birthday,
	  phoneno2:'',
	  address:'',
	  sex:'',
	  tnc:'',
	  phoneName:'',
	  phoneStatus:'',
	  emei:'',
	 phonesno:'',
	 referralno:'',
	 nok:'',
	 nokrel:'',
	 nokphone1:'',
	 nokphone2:''
	 };
	 //console.log(formData)
	  const [errors, setErrors] = useState(errorsObj);
	  const onChange = (e) =>
	  setFormData({ ...formData, [e.target.name]: e.target.value });

	//   const found = Object.entries(serviceFees['services'])
	//   .find(([key,val]) => key=== 0 )

	let found =''
	
  if(serviceFlag){
	
	   found = Object.entries(serviceFees['services'])
	  .find(([key, value]) => value[0] === "New Member Registration Fee" );
	 // setAmount(found[1][1]*100)
  }
//   if(serviceFlag ){
// 	//  
// 	let sum2 = []
// 	sum2 = [...found]
	
// 	console.log(sum2)
//   }

		function afterValidate() {
			//preventDefault();
			setFormData({ ...formData, profileStatus: "MEMBER" });
			
			createProfile(formData, navigate, user, profile ? true : false).then(() =>
			navigate("/dashboard")	);
		}


	  function onSubmit(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (sex === '') {
            errorObj.sex = 'Sex is Required';
            error = true;
			swal('Oops', errorObj.sex, "error");
        }
        

			var bday=birthday;
			bday=bday.split("-");
			var bday_in_milliseconds = new Date(parseInt(bday[0], 10), parseInt(bday[1], 10) - 1 , parseInt(bday[2]), 10).getTime(); //birth-date in milliseconds
			var now = new Date().getTime(); //current time in milliseconds
			if(now - bday_in_milliseconds < 567648000000){ //567648000000 is 18 years in milliseconds
				errorObj.birthday = 'Member under 18years is not allowed.';
				error = true;
				swal('Oops', errorObj.birthday, "error");
			}

	
  
        if (country === '') {
            errorObj.country = 'Country is Required';
            error = true;
			swal('Oops', errorObj.country, "error");
        }

		 if (state === '') {
            errorObj.state = 'State is Required';
            error = true;
			swal('Oops', errorObj.state, "error");
        }
		 if (lga === '') {
            errorObj.lga = 'LGA is Required';
            error = true;
			swal('Oops', errorObj.lga, "error");
        }
		 if (father === '') {
            errorObj.father = 'Father is Required';
            error = true;
			swal('Oops', errorObj.father, "error");
        }
		 if (yourname === '') {
            errorObj.yourname = 'yourname is Required';
            error = true;
			swal('Oops', errorObj.yourname, "error");
        }
		//  if (status === '') {
        //     errorObj.status = 'Status is Required';
        //     error = true;
		// 	swal('Oops', errorObj.status, "error");
        // }
		 if (phoneno === '') {
            errorObj.phoneno = 'Phone no is Required';
            error = true;
			swal('Oops', errorObj.phoneno, "error");
        }
		 if (address === '') {
            errorObj.address = 'address is Required';
            error = true;
			swal('Oops', errorObj.address, "error");
        }
		//  if (phoneno2 === '') {
        //     errorObj.phoneno2 = '2nd Phone  no is Required';
        //     error = true;
		// 	swal('Oops', errorObj.phoneno2, "error");
        // }
		 if (!tnc) {
            errorObj.tnc = 'Terms & Conditions is Required';
            error = true;
			swal('Oops', errorObj.tnc, "error");
        }

        setErrors(errorObj);
        if (error) return;
		afterValidate()
		//setValidationFlag(true);
		//setFormData({ ...formData, profileStatus: "MEMBER" });
      //  dispatch(loadingToggleAction(true));
       // dispatch(signupAction({ name, email, phone, password }, navigate));
	//    createProfile(formData, profile ? true : false).then(() =>
	//    navigate("/dashboard")
	//  );

    }
	return (
		
		<Fragment>


<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
		


						
						{/* { user?.profileStatus !=='MEMBER' &&( */}
								<div className="card-body">
									<div className="text-center mb-3">
												<br /><div className="card-title"><strong>{name}</strong> </div>Status: <Button className="me-2" variant="primary btn-xs">
										{user?.profileStatus}
										</Button><Button className="me-4" variant="success btn-xs">
										<Link to="/dashboard">Dashboard</Link>
										</Button><br /><br /><br />
										<Alert  variant="warning"  className="solid alert-square">						
											<strong>Info! </strong> We are committed to privacy protection. Pls contact Admin if you wish to modify your profile. 
						
										</Alert>
									</div>



					<form onSubmit={onSubmit}>

						<div className="form-wizard ">
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									{/* <Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} /> */}
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
								</Stepper>
							  {goSteps === 0 && (
								<>
									
									<StepOne 
									
									formData={formData} 
									onChange={onChange} 
									changeCountry={changeCountry}
									changeState={changeState}
									changeService={changeService}
									creatingProfile={creatingProfile}
									displaySocialInputs={displaySocialInputs}
									toggleSocialInputs={toggleSocialInputs}
									stateLGA={stateLGA}
									province={province}
									serviceFlag={serviceFlag}
									lgovt={lgovt}
									ontncChanged={ontncChanged}
									
									/>	
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-warning sw-btn-next" onClick={() => setGoSteps(1)}>Next</button>
									</div>	
								</>
							  )}
							  {/* {goSteps === 1 && (
								<>
									<StepTwo />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1" onClick={() => setGoSteps(2)}>Next</button>
									</div>	
								</>
							  )}
							  {goSteps === 2 && (
								<>
									<StepThree />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1"  onClick={() => setGoSteps(3)}>Next</button>
									</div>	
								</>
							  )} */}
							  {goSteps === 1 && (
								<>
									<StepTwo 
									formData={formData} 
									onChange={onChange} 
									changeCountry={changeCountry}
									changeState={changeState}
									changeService={changeService}
									creatingProfile={creatingProfile}
									displaySocialInputs={displaySocialInputs}
									toggleSocialInputs={toggleSocialInputs}
									stateLGA={stateLGA}
									province={province}
									serviceFlag={serviceFlag}
									lgovt={lgovt}
									/>
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-warning sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
										{/* {validationFlag &&  (  
        									  <Fragment>
												<PaystackButton className="btn btn-primary my-1" {...componentProps} />
											</Fragment>
           									  )}	 */}

										{!validationFlag &&  (  
        									  <Fragment>						
												<button className="btn btn-primary sw-btn-next ms-1"   disabled>submit</button>
												{/* <button className="btn btn-primary sw-btn-next ms-1"  onClick={onSubmit} disabled>submit</button> */}
											</Fragment>
           									)}	

									</div>	
								</>	
							  )}
							  
							</div>
						</form>
							
						</div>
						{/* )} */}


							{/* {    user?.profileStatus ==='MEMBER' &&
							
							<>
								<div className="card-body">
									<div className="text-center mb-3">
												<br /><div className="card-title"><strong>Welcome! </strong> {name}</div>Status: <Button className="me-2" variant="primary btn-xs">
										{user?.profileStatus}
										</Button><Button className="me-4" variant="success btn-xs">
										<Link to="/dashboard">Dashboard</Link>
										</Button><br /><br />
								
									</div>
								</div>

							</>
							
							} */}
					


					</div>
				</div>
			</div>

			
		</Fragment>
	);
};





UserProfile.propTypes = {
	createProfile: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	stateLGA: PropTypes.object.isRequired,
	getStatesLGA: PropTypes.func.isRequired,
	getServiceFees: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = (state) => ({
	profile: state.profileReducer,
	stateLGA: state.statesLGAReducer,
	// errorMessage: state.auth.errorMessage,
	// successMessage: state.auth.successMessage,
	// showLoading: state.auth.showLoading,
	name: state.auth.auth.name,
	email: state.auth.auth.email,
	user: state.auth.auth,


  });
  const mapDispatchToProps = { createProfile, getCurrentProfile,getStatesLGA,getServiceFees };
  export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
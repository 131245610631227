import { useState } from "react";
import AsyncSelect from "react-select/async";

const colourOptions = [
  { label: "Oranage", value: "orange" },
  { label: "Yellow", value: "yellow" },
  { label: "Blue", value: "blue" }
];

const SelectOption = (props) => {
  

  const filterColors = (inputValue) => {
    return colourOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };

  const handleChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    props.setInputValue(props.inputValue);
    
    return inputValue;
  };

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
      onInputChange={handleChange}
      isMulti
      isSearchable
    />
  );
};

export default SelectOption;
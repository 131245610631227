
import {
    CONFIRMED_CREATE_NOTE_ACTION,
    CONFIRMED_CREATE_NOTE_ITEM_ACTION,
    CONFIRMED_DELETE_NOTE_ACTION,
    CONFIRMED_EDIT_NOTE_ACTION,
    CONFIRMED_GET_NOTES,
    CONFIRMED_GET_NOTE,
    CONFIRMED_GET_GUEST_BY_PHONE,
    CONFIRMED_GET_NOTE_CLIENTS,
	NOTE_ERROR,
	NOTE_CLEAR
} from '../actions/NoteType';
const initialData = {
	list :null,
	lists : [],
	client : null,
	notes : [],

	errorMessage: '',

    successMessage: '',
    showLoading: true,
}
const noteReducer = (state = initialData, action) =>{

	switch(action.type){
		case CONFIRMED_EDIT_NOTE_ACTION:
			const newLists = [...state.lists];
			const listIndex = newLists.findIndex(
				(list) => list._id === action.payload._id,
			);
	
			newLists[listIndex] = action.payload;
			return {
				...state,
				lists:newLists,
			};
		case CONFIRMED_DELETE_NOTE_ACTION:
			const listss = [...state.lists];
			const listsIndex = listss.findIndex(
				(lists) => lists.id === action.payload,
			);
	
			listss.splice(listsIndex, 1);
	
			return {
				...state,
				lists:listss
			};


		case CONFIRMED_GET_NOTE : 
		case CONFIRMED_CREATE_NOTE_ITEM_ACTION : 
	
		return{
	    	...state,
			list: action.payload,
			showLoading: false,
		}
		case CONFIRMED_GET_NOTE_CLIENTS : 
	
		return{
	    	...state,
			notes: action.payload,
			showLoading: false,
		}

		case CONFIRMED_GET_GUEST_BY_PHONE : 
	
		return{
	    	...state,
			client: action.payload,
			showLoading: false,
		}

		case CONFIRMED_GET_NOTES : 

		return {
            ...state,
            lists: action.payload,
            // activeUsers: (actions.payload).filter((user) => user.active),
            // inActiveUsers: (actions.payload).filter((user) => !user.active),
            showLoading: false,
        };

		case CONFIRMED_CREATE_NOTE_ACTION: 

		  const lists = [...state.lists];
        lists.push(action.payload);

        return {
            ...state,
            lists,
            showLoading: false, 
        };
     
		case NOTE_ERROR : 
		return {
            ...state,
            errorMessage: action.payload,
        };
		

		case NOTE_CLEAR : 
		return {
            ...state,
            client: null,
        };


		default: return state;
           
	}
}
export default noteReducer;
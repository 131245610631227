import React, { useEffect,Fragment,useState } from "react";
import {  useParams, useMatch, useNavigate,useHistory  } from "react-router-dom";
import swal from "sweetalert";
import { connect, useDispatch } from 'react-redux';
import Select from "react-select";
//import Toastr from '../../PluginsMenu/Toastr/Toastr2';


import {
  createUserAction,
  getCurrentUserAction,
  clear_user
} from '../../../../store/actions/UserActions';
import PropTypes from "prop-types";



import { ButtonGroup, Dropdown, SplitButton } from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import PageTItle from "../../../layouts/PageTitle";
import { ROLES,PERMISSION,DESIGNATION,DEPARTMENTS } from "../../../../config/roles"

/*
  NOTE: declare initialState outside of component
  so that it doesn't trigger a useEffect
  we can then safely use this to construct our userData
 */
const initialState = {
  _id:'',
  username: '', 
  password: '', 
  sname: '', 
  fname: '', 
  email: '', 
  phone: '', 
  active: '',
   dept: [], 
   designation: [], 
   permission: [],
   roles:[],
   active:false
};

const CreateUser = ({ createUserAction, getCurrentUserAction,user: { users, showLoading,successMessage,errorMessage } }) => {
  // if (id && !users ){
  //   navigate('/concierge-list');
  // } 
 

  const { id } = useParams()

  const [formData, setFormData] = useState(initialState);

  const creatingUser = useMatch('/CreateUser');

   // const [username, setUsername] = useState('');
    let errorsObj = { username: '', password: '', sname: '', fname: '', email: '', phone: '', active: '', dept: '', designation: '', permission: '', roles: '',active:false };
    const [errors, setErrors] = useState(errorsObj);
    // const [fname, setFname] = useState('');
    // const [sname, setSname] = useState('');
    // const [email, setEmail] = useState('');
    // const [phone, setPhone] = useState('');
    const [selectedActive, setSelectedActive] = useState(false);
   // const [dept, setDept] = useState([]);
    // const [designation, setDesignation] = useState([]);
    // const [permission, setPermission] = useState([]);
    // const [password, setPassword] = useState('');
    const [selectedRoles, setSelectedRoles] = useState();
    const [selectedPermission, setSelectedPermission] = useState();
    const [selectedDepartments, setSelectedDepartments] = useState();
    const [selectedDesignation, setSelectedDesignation] = useState();
    // const [roles, setRoles] = useState([])
   
   // console.log("form data-"+JSON.stringify(formData))
    const navigate = useNavigate();
   
    //console.log(roles);
   
    
      // Function triggered on selection
  function handleSelectRoles(data) {

    const roles = Array.from(
        data, //HTMLCollection 
        (option) => option
    )
    setSelectedRoles(data);

   setFormData({ ...formData, roles:roles });
  }

      // Function triggered on selection
  function handleSelectPermission(data) {

    const permission = Array.from(
        data, //HTMLCollection 
        (option) => option
    )
    setSelectedPermission(data);

   setFormData({ ...formData, permission:permission });

  }
      // Function triggered on selection
  function handleSelectDesignation(data) {

    const designation = Array.from(
        data, //HTMLCollection 
        (option) => option
    )
    setSelectedDesignation(data);

   setFormData({ ...formData, designation:designation });

   
  }
      // Function triggered on selection
  function handleSelectDepartments(data) {

    const departments = Array.from(
        data, //HTMLCollection 
        (option) => option
    )
    setSelectedDepartments(data);

    setFormData({ ...formData, dept:departments });
   
   
  }


  const onActiveChanged = () => 
  {
    
    setFormData({ ...formData, active:formData.active?false:true});
  }
 


    const {
    username, password, sname, fname, email, phone, active, dept, designation, roles, permission
    } = formData;

    function onSignUp(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (username === '') {
            errorObj.username = 'Username is Required';
            error = true;
			swal('Oops', errorObj.username, "error");
        }
        if (password === '' && creatingUser)  {
            errorObj.password = 'password is Required';
            error = true;


			swal('Oops', errorObj.password, "error");
        }
        if (fname === '') {
            errorObj.fname = 'fname is Required';
            error = true; 
			swal('Oops', errorObj.fname, "error");
        }
        if (sname === '') {
            errorObj.sname = 'sname is Required';
            error = true;
			swal('Oops', errorObj.sname, "error");
        }
        if (email === '') {
            errorObj.email = 'email is Required';
            error = true;
			swal('Oops', errorObj.email, "error");
        }
        if (phone === '') {
            errorObj.phone = 'phone is Required';
            error = true;
			swal('Oops', errorObj.phone, "error");
        }
        if (active === '') {
            errorObj.active = 'active is Required';
            error = true;
			swal('Oops', errorObj.active, "error");
        }
        if (dept === '') {
            errorObj.dept = 'dept is Required';
            error = true;
			swal('Oops', errorObj.dept, "error");
        }
        if (roles === '') {
            errorObj.roles = 'Roles is Required';
            error = true;
			swal('Oops', errorObj.dept, "error");
        }
        if (designation === '') {
            errorObj.designation = 'designation is Required';
            error = true;
			swal('Oops', errorObj.designation, "error");
        }
        if (permission === '') {
            errorObj.permission = 'permission is Required';
            error = true;
			swal('Oops', errorObj.permission, "error");
        }
        setErrors(errorObj);

      // let edit = false;
      //  createUserAction(formData ,navigate, !creatingUser ? edit = true : edit= false);

      //   return!creatingUser && notifyTopRight()
      handleCreate(e);
        
    }

    const handleCreate = (e) => {
      e.preventDefault();
      swal({
        title: "Are you sure?",
          text:
          `This function creates only ADMIN user`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
        }).then((willDelete) => {
        if (willDelete) {
         
          let edit = false;

          createUserAction(formData ,navigate, !creatingUser ? edit = true : edit= false)

          // deletePhoneAction_forsale(formData, navigate).then((res) => {
          //     if(res !=='Invalid Credentials'){
          //       swal(`User has been created!`, {
          //       icon: "success",
          //       });
          //       navigate("/concierge-list")
          //     }else{
          //       swal("Invalid Entries!");
          //     }
         
          // });

        } else {
          swal(`User is not created!`);
        }
        })
  
      }

    const onChange = (e) =>setFormData({ ...formData, [e.target.name]: e.target.value });

    

  //   const options = Object.values(ROLES).map(role => {
  //     return (
  //         <option
  //             key={role}
  //             value={role}

  //         > {role}</option >
  //     )
  // })

  useEffect(() => {

    const userData = { ...initialState };

    if (!showLoading && users && !creatingUser) {
   
     
     const user = users.find((element) => {
      return element._id === id;
    })
    //console.log(user);
     
      for (const key in user) {
        //console.log(key);
        if (key in userData) userData[key] = user[key];
      }

      //set rooles
        const values = Array.from(
          user.roles, //HTMLCollection 
          (option) => option
      )
      
      setSelectedRoles(values);


      //set desiggnation 
        const savedDesignation = Array.from(
          user.designation, //HTMLCollection 
          (option) => option
      )

      setSelectedDesignation(savedDesignation);
        
           //set department 
           const savedDept = Array.from(
            user.dept, //HTMLCollection 
            (option) => option
        )
  
        setSelectedDepartments(savedDept);
     
        
           //set permission
           const savedPerm = Array.from(
            user.permission, //HTMLCollection 
            (option) => option
        )
  
        setSelectedPermission(savedPerm);

      // set local state with the userData
      setFormData(userData);
  
    
    } else{
      setSelectedPermission('');
      setSelectedDepartments('');
      setSelectedDesignation('');
      setSelectedRoles('');
      setFormData(userData);
    }  
  }, [showLoading,creatingUser, users, id]);

 

    return (
     
 <Fragment>

      <PageTItle activeMenu={creatingUser ? 'Create User' : 'Edit User'} motherMenu="Form" pageContent={creatingUser ? 'Create User' : 'Edit User'} />
<form onSubmit={onSignUp} autocomplete="off">
      <div className="row">
    
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Personal Detail</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">First Name</label>
                    <div className="col-sm-9">
                    <input
                            defaultValue={fname}
                            name="fname"
                            // onChange={(e) => setFname(e.target.value)}
                            onChange={onChange}
                            className="form-control"
                            placeholder="firstname"
                          />
                    </div>
                  </div>
                  {errors.fname && <div>{errors.fname}</div>}
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Surname</label>
                    <div className="col-sm-9">
                    <input
                            defaultValue={sname}
                            name="sname"
                            // onChange={(e) =>
                            //   setSname(e.target.value)
                            // }

                            onChange={onChange}
                            className="form-control"
                            placeholder="Surname"
                            //defaultValue="sname"
                          />
                    </div>
                  </div>
                  {errors.sname && <div>{errors.sname}</div>}
                  
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Email</label>
                    <div className="col-sm-9">
                    <input
                            defaultValue={email}
                            name="email"
                            type="email"
                            // onChange={(e) =>
                            //   setEmail(e.target.value)
                            // }
                            onChange={onChange}
                            className="form-control"
                            placeholder="Email"
                            //defaultValue="email"
                          />
                    </div>
                  </div>
                  {errors.email && <div>{errors.email}</div>}
                  
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Phone</label>
                    <div className="col-sm-9">
                    <input
                            defaultValue={phone}
                            name="phone"
                            // onChange={(e) =>
                            //   setPhone(e.target.value)
                            // }
                            onChange={onChange}
                            className="form-control"
                            placeholder="Phone"
                            //defaultValue="phone"
                          />
                    </div>
                  </div>
                  {errors.phone && <div className="border-primary">{errors.phone}</div>}
                  
                 
                  
                  
               
              </div>
            </div>

          </div>
        </div>
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Credentials</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
               
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Username</label>
                    <div className="col-sm-9">
                    <input
                           // defaultValue={username}
                            name="username"
                            // onChange={(e) => setUsername(e.target.value)}
                            onChange={onChange}
                            className="form-control"
                            placeholder="username"
                           
                          />
                    </div>
                  </div>
                  {errors.username && <div>{errors.username}</div>}
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Password</label>
                    <div className="col-sm-9">
                    <input
                           // defaultValue={password}
                            name="password"
                            // onChange={(e) =>
                            //   setPassword(e.target.value)
                            // }
                            onChange={onChange}
                            className="form-control"
                            placeholder="Password"
                          type="password"
                          autocomplete="new-password"
                          />
                    </div>
                  </div>
                  {errors.password && <div>{errors.password}</div>}
                  
                
               
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="row">
    
      <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Roles & Permissions</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
              
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Roles</label>
                    <div className="col-sm-9">
                         <Select
                         
                        options={ROLES}
                        placeholder="Select roles"
                        value={selectedRoles}
                        onChange={handleSelectRoles}
                        isSearchable={true}
                        isMulti
                      />
                          
					 
					
                    </div>
                  </div>
                  {errors.roles && <div>{errors.roles}</div>}
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Permissions</label>
                    <div className="col-sm-9">
                         <Select
                        options={PERMISSION}
                        placeholder="Select permissions"
                        value={selectedPermission}
                        onChange={handleSelectPermission}
                        isSearchable={true}
                        isMulti
                      />
                          
					 
					
                    </div>
                  </div>
                  {errors.permission && <div>{errors.permission}</div>}
                  
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        {creatingUser ? 'Create User' : 'Edit User'}
                      </button>
                    </div>
                  </div>
                  
                
              </div>
            </div>

          </div>
        </div>
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">HR Detail</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
               
              <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Department</label>
                    <div className="col-sm-9">
                         <Select
                        options={DEPARTMENTS}
                        placeholder="Select Department"
                        value={selectedDepartments}
                        onChange={handleSelectDepartments}
                        isSearchable={true}
                         isMulti
                      />
                          
					 
					
                    </div>
                  </div>
                  {errors.permission && <div>{errors.permission}</div>}
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Designation</label>
                    <div className="col-sm-9">
                         <Select
                        options={DESIGNATION}
                        placeholder="Select Designation"
                        value={selectedDesignation}
                        onChange={handleSelectDesignation}
                        isSearchable={false}
                        isMulti
                      />
                          
					 
					
                    </div>
                  </div>
                  {errors.permission && <div>{errors.permission}</div>}
                  <div className="form-check custom-checkbox mb-3 checkbox-success">
                    <input
                      type="checkbox"
                      // defaultChecked
                      className="form-check-input"
                      id="active"
                      name={active}
                      value={active}
                      checked ={ active && 'checked' }
                      // onChange={(e) =>
                      //   setActive(e.target.value)
                      // }
                      onChange={onActiveChanged}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="active"
                    >
                      Active
                    </label>
                  </div>
                  {errors.active && <div>{errors.active}</div>}
                
                  
                  
                  <div className="new-account mt-3">
                        
                       
                      </div>
             
              </div>
            </div>

          </div>
        </div>
      </div>  
       </form>
    </Fragment>

        
     
    );
};







CreateUser.propTypes = {
  createUserAction: PropTypes.func.isRequired,
  getCurrentUserAction: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.UsersReducer
});

export default connect(mapStateToProps, { createUserAction, getCurrentUserAction })(
  CreateUser
);
import axiosInstance from './AxiosInstance';

export function getClients() {
    return axiosInstance.get(`/clients`);
}
export function getClient(clientId) {
    return axiosInstance.get(`clients/${clientId}`);
}

export function createClient(clientData) {
    return axiosInstance.post(`/clients`, clientData);
}

export function updateClient(clientData) {
    return axiosInstance.put(`/clients`, clientData);
}

export function updateClientName(clientData) {
    return axiosInstance.patch(`/clients/clientnames`, clientData);
}

export function deleteClient(clientId) {
    return axiosInstance.delete(`/clients/${clientId}`);
}



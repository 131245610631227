import {
  STATELGA_ERROR,
  STATE_LGA,
  SERVICE_FEE,
  SERVICE_FEE_ERROR
} from "../types";

const initialState = {
  loading: true,
  error: {},
  stateLGA:{},
  serviceFees:{},
};

function statesLGA(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
   
    case STATE_LGA:
      return {
        ...state,
        stateLGA: payload,
        loading: false,
      };
   

    case STATELGA_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        stateLGA: null,
      };

    case SERVICE_FEE:
      return {
        ...state,
        serviceFees: payload,
        loading: false,
      };
   

    case SERVICE_FEE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        serviceFees: null,
      };
   
    default:
      return state;
  }
}

export default statesLGA;


import {
    CONFIRMED_CREATE_INVOICE_ACTION,
    CONFIRMED_CREATE_INVOICE_ITEM_ACTION,
    CONFIRMED_DELETE_INVOICE_ACTION,
    CONFIRMED_EDIT_INVOICE_ACTION,
    CONFIRMED_GET_INVOICES,
    CONFIRMED_GET_INVOICE,
    LOADING_TOGGLE_ACTION
} from '../actions/InvoiceType';
const initialData = {
	list :null,
	lists : [],
	errorMessage: '',
    successMessage: '',
    showLoading: true,
}
const InvoiceReducer = (state = initialData, action) =>{

	switch(action.type){
		case CONFIRMED_EDIT_INVOICE_ACTION:
		case CONFIRMED_DELETE_INVOICE_ACTION:
		case CONFIRMED_GET_INVOICE : 
		case CONFIRMED_CREATE_INVOICE_ITEM_ACTION : 
	
		return{
	    	...state,
			list: action.payload,
			showLoading: false,
		}

		case CONFIRMED_GET_INVOICES : 

		return {
            ...state,
            lists: action.payload,
            // activeUsers: (actions.payload).filter((user) => user.active),
            // inActiveUsers: (actions.payload).filter((user) => !user.active),
            showLoading: false,
        };

		case CONFIRMED_CREATE_INVOICE_ACTION: 

		  const lists = [...state.lists];
        lists.push(action.payload);

        return {
            ...state,
            lists,
            showLoading: false, 
        };
     
		case "REMOVE_INVOICE" : return{
			...state,
			list: null
		}
		default: return state;
           
	}
}
export default InvoiceReducer;
import React,{ useState, useEffect, useMemo } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import {Link,useNavigate}  from "react-router-dom";
import MOCK_DATA from './MOCK_DATA_2.json';
import { COLUMNS } from './ColumnsSearcher';
import { GlobalFilter } from './GlobalFilter'; 

import {
	Row,
	Modal,
	 Tab,
	  Nav, 
	Button, 
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	
  } from "react-bootstrap";
//import './table.css';
import './filtering.css';
import swal from "sweetalert";
import { connect } from 'react-redux';

  import PropTypes from "prop-types";
  //import Spinner from '../layouts/Spinner';
  //import useAuth from '../../config/hooks/useAuth';
  import {
	changeStatus,
	getProfilesSearch,
	
  } from "../../../../store/actions/profileAction";

  const initialState ={

	phoneid: ''

}
export const FilteringTable = ({ getProfilesSearch, changeStatus, profiles3 }) => {
	
	const [formData, setFormData] = useState(initialState);
	const columns = useMemo( () => COLUMNS, [] )
	const data = useMemo( () => profiles3, [] )
	const navigate = useNavigate();

// delete data  
const handleDelete = (phone) => {
	//e.preventDefault();
	//let msg = 'Note that deliberate declaring of your phone missing causes authorities to track down to you and there is penalty for this.'
	let msg2 = 'Once approved, the user will become an MRight Agent and would be issued referral code'

	swal({
		title: "Are you sure?",
		text:
		  `${msg2}`,
		icon: "warning",
		buttons: true,
		dangerMode: true,
	  }).then((willDelete) => {
		if (willDelete) {
		 
			changeStatus({phoneid: phone}, navigate).then((res) => {
			if(res !=='Invalid Credentials'){
					swal(`Approved as a MRight Agent!`, {
				icon: "success",
				});
			navigate("/dashboard")
			}else{
				swal("Invalid Credentials!");
			}
			
		});
		} else {
		  swal(`Status cancelled!`);
		}
	  })

}

const onClick = (e,phone)=>{
	e.preventDefault();
	//alert(phone)
	//setFormData({ ...formData, phoneid: phone});
	handleDelete(phone)
}

	const tableHooks = (hooks) => {
		hooks.visibleColumns.push((columns) => [
		  ...columns,
		  {
			id: "Edit",
			Header: "Action",
			Cell: ({ row }) => (
				row.values.state!=='Lagos'  &&
			 ( <button className="btn btn-warning" onClick={(e) => onClick(e, row.values.phoneno) }>
				Approve 
			  </button>)
			),
		  },
		]);
	  };

	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	},   useFilters, useGlobalFilter, usePagination)
	// const tableInstance = useTable({
	// 	columns,
	// 	data,	
	// 	initialState : {pageIndex : 0}
	// },  tableHooks, useFilters, useGlobalFilter, usePagination)
	
	useEffect(() => {
		getProfilesSearch();
	  }, [getProfilesSearch]);

	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
	

	const {globalFilter, pageIndex} = state
		
	return(
		<>
			<PageTitle activeMenu="Searches Log" motherMenu="MRight" />
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Searches Log</h4>
                </div>
				<div className="card-body">
					<div className="table-responsive">
						{/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
						<Table {...getTableProps()} responsive>
							<thead>
							   {headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>
												{column.render('Header')}
												{column.canFilter ? column.render('Filter') : null}
											</th>
										))}
									</tr>
							   ))}
							</thead> 
							<tbody {...getTableBodyProps()} className="" >
							
								{page.map((row) => {
									prepareRow(row)
									return(
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
											})}
										</tr>
									)
								})}
							</tbody>
						</Table>
						<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number" 
									className="ml-2"
									defaultValue={pageIndex + 1} 
									onChange = {e => { 
										const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
										gotoPage(pageNumber)
									} } 
								/>
							</span>
						</div>
						<div className="text-center mb-3">	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
	
}



FilteringTable.propTypes = {
  changeStatus: PropTypes.func.isRequired,
  getProfilesSearch: PropTypes.func.isRequired,
  profiles3: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiles3: state.profileReducer.profiles3,
});

export default connect(mapStateToProps, { getProfilesSearch, changeStatus })(FilteringTable);
 

import axiosInstance from './AxiosInstance';

export function getNotes() {
    return axiosInstance.get(`/notes`);
}
export function getNote(noteId) {
    return axiosInstance.get(`notes/${noteId}`);
}
export function getOpenNoteClients() {
    return axiosInstance.get(`notes/client`);
}

export function getGuestByPhone(phone) {
    return axiosInstance.get(`notes/client/${phone}`);
}

export function createNote(noteData) {
    return axiosInstance.post(`/notes`, noteData);
}

export function updateNote(noteData) {
    return axiosInstance.patch(`/notes`, noteData);
}

export function updateNoteName(noteData) {
    return axiosInstance.patch(`/notes/notenames`, noteData);
}

export function deleteNote(noteId) {
    return axiosInstance.put(`/notes`,{_id: noteId});
}



import React,{useState,useEffect,Fragment} from 'react';
import {Link}  from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {nanoid} from 'nanoid';
import swal from "sweetalert";
import PageTitle from "../layouts/PageTitle";
import pic1 from './../../images/profile/small/pic1.jpg';
//import Editable from './Editable';
import EditClient from './EditClient';
import { connect } from 'react-redux';
import {
	createClientAction,
	getClientsAction,
	deleteClientAction,
	updateClientAction
  } from '../../store/actions/ClientActions';
  import PropTypes from "prop-types";
  import Spinner from '../layouts/Spinner';
 // import useAuth from '../../config/hooks/useAuth';
// const tableList = [
// 	{
// 		id:"1", name:'Tiger Nixon', department:'Architect',gender:'Male', 
// 		education:'M.COM., M.B.A', mobile:'12345 67890', email:'info1@example.com'
// 	},
// 	{	
// 		id:"2", name:'Gloria Little', department:' Administrator',gender:'Male', 
// 		education:'BTech, MTech', mobile:'09876 54321', email:'info2@example.com'
// 	},
// 	{	
// 		id:"3", name:'Bradley Greer', department:'Software Engineer',gender:'Male', 
// 		education:'B.CA M.CA', mobile:'98765 67890', email:'info3@example.com'
// 	},
// 	{	
// 		id:"4", name:'Gloria Little', department:' Administrator',gender:'Male', 
// 		education:'BTech, MTech', mobile:'09876 54321', email:'info4@example.com'
// 	},
// 	{
// 		id:"5", name:'Tiger Nixon', department:'Architect',gender:'Male', 
// 		education:'M.COM., M.B.A', mobile:'12345 67890', email:'info5@example.com'
// 	},
// 	{	
// 		id:"6", name:'Bradley Greer', department:'Software Engineer',gender:'Male', 
// 		education:'B.CA M.CA', mobile:'98765 67890', email:'info6@example.com'
// 	},
// ]; 







const Clients = ({ updateClientAction,deleteClientAction,createClientAction, getClientsAction,list: { lists, showLoading,successMessage,errorMessage } }) =>{
	

	
//	const [list, //setList] = useState();
//	const { department,cust_phone } = useAuth()
 //   const [id, setId] = useState(department);
	

	// delete data  
    const handleDeleteClick = (contentId) => {
       // const newList = [...list];    
       // const index = list.findIndex((content)=> content.id === contentId);
        //newList.splice(index, 1);
        //setList(newList);

		//const delContent = {_id: contentId};

        swal({
			title: "Are you sure, DELETE?",
			text:
			  "Once deleted, you will not be able to recover this record!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {
			  deleteClientAction(contentId);
			  swal("Record has been deleted!", {
				icon: "success",
			  });
                
			} else {
			  swal("Record is not deleted!");
			}
		  })



		


    }
	
	//Modal box
	const [addCard, setAddCard] = useState(false);
	//Add data 
    const [addFormData, setAddFormData ] = useState({
        cust_sname:'',
        cust_fname:'',
        cust_email:'',
        cust_phone:'',
        cust_address:'',

    }); 

    	useEffect(() => {
           getClientsAction()
	   }, [getClientsAction]);



    // Add contact function
    const handleAddFormChange = (event) => {
        event.preventDefault();    
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = {...addFormData};
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };
    
     //Add Submit data
    const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        var error = false;
		var errorMsg = '';
        if(addFormData.cust_sname === ""){
            error = true;
			errorMsg = 'Please fill  Guest Surname';
        }else if(addFormData.cust_fname === ""){
            error = true;
			errorMsg = 'Please fill Guest Firstname';
        }else if(addFormData.cust_email === ""){
			error = true;
			errorMsg = "please fill Guest Email";
		
        }else if(addFormData.cust_phone === ""){
			error = true;
			errorMsg = "please fill Guest Phone";
		
        }else if(addFormData.cust_address === ""){
			error = true;
			errorMsg = "please fill Guest Address";
		}
        if(!error){
            const newContent = {
                // id: nanoid(),
				//clientId: id,
                cust_sname: addFormData.cust_sname,
                cust_fname:  addFormData.cust_fname,
                cust_email:  addFormData.cust_email,
                cust_phone:  addFormData.cust_phone,
                cust_address:  addFormData.cust_address
             };
            
         //console.log(newContent)
			createClientAction(newContent,false);
			//const newList = [...list, list];
            //setList(newList);
			
            
			if(errorMessage.status === 400){
				return false
			}
			else{
			setAddCard(false);
            console.log('Invalid stuff'+errorMessage.msg.status)
			swal('Good job!', 'Successfully Added', "success");

			}
addFormData.cust_sname  = addFormData.cust_fname = addFormData.cust_phone=addFormData.cust_email=addFormData.cust_address;      
              
            
        }else{
			swal('Oops', errorMsg, "error");
		}
    };
	
	//Edit start 
	//const [editModal, setEditModal] = useState(false);	
	// Edit function editable page loop
    const [editContentId, setEditContentId] = useState(null);
   
    // Edit function button click to edit
    const handleEditClick = ( event, content) => {
        event.preventDefault();
        setEditContentId(content._id);
        const formValues = {
            cust_sname: content.cust_sname,
            cust_fname: content.cust_fname,  
			cust_email:  content.cust_email,
			cust_phone:  content.cust_phone,
			cust_address:  content.cust_address

        }
        setEditFormData(formValues);
        //setEditModal(true);
    };
   
    // edit  data  
    const [editFormData, setEditFormData] = useState({
        cust_sname:'',
        cust_fname:'',
        cust_phone:'',
        cust_email:'',
        cust_address:'',
    })
    
    //update data function
    const handleEditFormChange = (event) => {
        event.preventDefault();   
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = {...editFormData};
        newFormData[fieldName] = fieldValue;
        setEditFormData(newFormData);
    };
    
    // edit form data submit
    const handleEditFormSubmit = (event) => {
        event.preventDefault();
        const editedContent = {
		//	clientId: id,
            _id: editContentId,
            cust_sname: editFormData.cust_sname,
            cust_fname: editFormData.cust_fname,
            cust_phone: editFormData.cust_phone,
            cust_email: editFormData.cust_email,
            cust_address: editFormData.cust_address,
      
        }

		updateClientAction(editedContent);

        //const newList = [...list];
       // const index = list.findIndex((content)=> content.id === editContentId);


        //newList[index] = editedContent;
        //setList(newList);
        setEditContentId(null);
       // setEditModal(false);
    }
	//Cencel button to same data
    const handleCancelClick = () => {
        setEditContentId(null);    
    };
	
	return(
		<>
			{/* <PageTitle activeMenu="" motherMenu="Clients" /> */}
			<div className="col-12">
				<Modal className="modal fade"  show={addCard} onHide={setAddCard} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Add Guest</h4>
									<button type="button" className="btn-close" onClick={()=> setAddCard(false)} data-dismiss="modal"><span></span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											 <div className="form-group mb-3">
												<label className="text-black font-w500">Guest Surname</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autoComplete="off"
														name="cust_sname" required="required"
														onChange={handleAddFormChange}
														placeholder="Guest Surame"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Guest Firstname</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autoComplete="off"
														name="cust_fname" required="required"
														onChange={handleAddFormChange}
														placeholder="Guest Firstname"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Guest Phone</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autoComplete="off"
														name="cust_phone" required="required"
														onChange={handleAddFormChange}
														placeholder="Guest Phone"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Guest Email</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autoComplete="off"
														name="cust_email" required="required"
														onChange={handleAddFormChange}
														placeholder="Guest Email"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Guest Address</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autoComplete="off"
														name="cust_address" required="required"
														onChange={handleAddFormChange}
														placeholder="Guest Address"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="submit" className="btn btn-primary" onClick={handleAddFormSubmit}>Add</button>   
									<button type="button" onClick={()=> setAddCard(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
								</div>
							</form>
							
						</div>
					</div>
				</Modal>
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">{('Members List').toUpperCase()}</h4>
					</div>
					<div className="card-body">
						<div className="w-100 table-responsive">
							<div id="example_wrapper" className="dataTables_wrapper">
								<form onSubmit={handleEditFormSubmit}>
									<table id="example" className="display w-100 dataTable">
										<thead>
											<tr>
												{/* <th></th> */}
												<th>Surname</th>
												<th>Firstname</th>
												<th>Phone</th>
												<th>Email</th>
												<th>Address</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>

										{showLoading ? (
											<Spinner />
											) : (
												<Fragment>

	
											{lists?.map((content, index)=>(
												<tr key={index}>
													{editContentId === content._id ? 
														( 
															<EditClient editFormData={editFormData} 
																handleEditFormChange={handleEditFormChange} handleCancelClick={handleCancelClick}/> 
															) : 
														( 
															<>
																{/* <td><img className="rounded-circle" width="35" src={pic1} alt="" /></td> */}
																<td>{content.cust_sname}</td>
																<td>{content.cust_fname}</td>
																<td>{content.cust_phone}</td>
																<td>{content.cust_email}</td>
																<td>{content.cust_address}</td>
																{/* <td>{content.education}</td>
																<td><Link to={"#"}><strong>{content.mobile}</strong></Link></td>
																<td><Link to={"#"}><strong>{content.email}</strong></Link></td> */}
																<td>
																	<div className="d-flex">
																		<Link className="btn btn-primary shadow btn-xs sharp me-2"
																			onClick={()=> setAddCard(true)}
																		>
																			<i className="fa fa-plus"></i>
																		</Link>
																		<Link  className="btn btn-secondary	 shadow btn-xs sharp me-2"
																			onClick={(event) => handleEditClick(event, content)}
																		>
																			<i className="fas fa-pen"></i>
																		</Link>
																		<Link  className="btn btn-danger shadow btn-xs sharp" 
																			onClick={()=>handleDeleteClick(content._id)}
																		> 
																			<i className="fa fa-trash"></i>
																		</Link>
																			
																	</div>												
																</td>			
															</>   
														)
													}
												</tr>    
											))}
											  </Fragment>
      )}
										</tbody>
									</table>
								</form>	
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}


Clients.propTypes = {
	updateClientAction: PropTypes.func.isRequired,
	createClientAction: PropTypes.func.isRequired,
	getClientsAction: PropTypes.func.isRequired,
	deleteClientAction: PropTypes.func.isRequired,
	list: PropTypes.object.isRequired
  };
  
  const mapStateToProps = (state) => ({
	list: state.ClientReducer
  });
  
  export default connect(mapStateToProps, { updateClientAction,deleteClientAction, createClientAction, getClientsAction })(
	Clients
  );
import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import jwtDecode from 'jwt-decode'

export function signUp(signupdata) {
    //axios call
  
  

    if(process.env.REACT_APP_ENV === 'development') {
        return axios.post(
        process.env.REACT_APP_ENV_API_USER_LC,
        signupdata,
    );
   }else{
        return axios.post(
       process.env.REACT_APP_ENV_API_USER,
       signupdata
         );
   }

    
}

export function login(username, password) {
    const postData = {
        phone:username,
        password
    };
   
    if(process.env.REACT_APP_ENV === 'development') {
         return axios.post(
        process.env.REACT_APP_ENV_API_AUTH_LC,
        postData
             )
    }else{
         return axios.post(
        process.env.REACT_APP_ENV_API_AUTH,
        postData
          );
    }
   
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );

    const decoded = jwtDecode(tokenDetails.idToken)
    const user = decoded.user
   // console.log(user)
   if(user.profileStatus === 'MEMBER' && user.asg === 'DSF@NR') {

    tokenDetails.profileStatus = 'MEMBER'
    tokenDetails.asg = 'DSF@NR'

    }
    else if(user.profileStatus === 'MEMBER' && user.asg === '') {

        tokenDetails.profileStatus = 'MEMBER'
       // tokenDetails.asg = 'NONE@NR'
    
        
    } 
    
    else if(user.profileStatus === 'MEMBER' && user.asg === 'TAPMA@NR') {

        tokenDetails.profileStatus = 'MEMBER'
        tokenDetails.asg = 'TAPMA@NR'
    
        }else {
    
    tokenDetails.profileStatus = ''
    tokenDetails.phone = user.phone
    tokenDetails.app = user.app
    
    }



    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        //
         

        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
   
    //console.log(`i am here`)
    //return;
     const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
	//tokenDetails.profileStatus = 'MEMBER'
    dispatch(loginConfirmedAction(tokenDetails));
	
    const timer = expireDate.getTime() - todaysDate.getTime();
    //
    runLogoutTimer(dispatch, timer, navigate);
}
export function setProfileStatus(dispatch, status,navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
	tokenDetails.profileStatus = status
    dispatch(loginConfirmedAction(tokenDetails));
	
   // const timer = expireDate.getTime() - todaysDate.getTime();
    //
    //runLogoutTimer(dispatch, timer, navigate);
}

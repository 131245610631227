import React,{useState,useEffect,Fragment} from 'react';
import {Link}  from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {nanoid} from 'nanoid';
import swal from "sweetalert";
import PageTitle from "../layouts/PageTitle";
import pic1 from './../../images/profile/small/pic1.jpg';
//import Editable from './Editable';
import EditService from './EditService';
import { connect } from 'react-redux';
import {
	createServiceItemAction,
	getCurrentServiceAction,
	deleteServiceAction
  } from '../../store/actions/ServiceActions';
  import PropTypes from "prop-types";
  import Spinner from '../layouts/Spinner';
  import useAuth from '../../config/hooks/useAuth';
// const tableList = [
// 	{
// 		id:"1", name:'Tiger Nixon', department:'Architect',gender:'Male', 
// 		education:'M.COM., M.B.A', mobile:'12345 67890', email:'info1@example.com'
// 	},
// 	{	
// 		id:"2", name:'Gloria Little', department:' Administrator',gender:'Male', 
// 		education:'BTech, MTech', mobile:'09876 54321', email:'info2@example.com'
// 	},
// 	{	
// 		id:"3", name:'Bradley Greer', department:'Software Engineer',gender:'Male', 
// 		education:'B.CA M.CA', mobile:'98765 67890', email:'info3@example.com'
// 	},
// 	{	
// 		id:"4", name:'Gloria Little', department:' Administrator',gender:'Male', 
// 		education:'BTech, MTech', mobile:'09876 54321', email:'info4@example.com'
// 	},
// 	{
// 		id:"5", name:'Tiger Nixon', department:'Architect',gender:'Male', 
// 		education:'M.COM., M.B.A', mobile:'12345 67890', email:'info5@example.com'
// 	},
// 	{	
// 		id:"6", name:'Bradley Greer', department:'Software Engineer',gender:'Male', 
// 		education:'B.CA M.CA', mobile:'98765 67890', email:'info6@example.com'
// 	},
// ]; 







const Services = ({ deleteServiceAction,createServiceItemAction, getCurrentServiceAction,list: { list, showLoading,successMessage,errorMessage } }) =>{
	

	
//	const [list, //setList] = useState();
	const { department,serviceDesc } = useAuth()
    const [id, setId] = useState(department);
	

	// delete data  
    const handleDeleteClick = (contentId) => {
       // const newList = [...list];    
       // const index = list.findIndex((content)=> content.id === contentId);
        //newList.splice(index, 1);
        //setList(newList);

		const delContent = {service_id: id,	_id: contentId};

        swal({
			title: "Are you sure?",
			text:
			  "Once deleted, you will not be able to recover this file!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {
			  swal("File has been deleted!", {
				icon: "success",
			  });
                deleteServiceAction(id,delContent);
			} else {
			  swal("File is not safe!");
			}
		  })



		


    }
	
	//Modal box
	const [addCard, setAddCard] = useState(false);
	//Add data 
    const [addFormData, setAddFormData ] = useState({
        name:'',
        serviceRate:'',
        serviceDesc:'',

    }); 

    	useEffect(() => {
           getCurrentServiceAction(id)
	   }, [getCurrentServiceAction,id]);



    // Add contact function
    const handleAddFormChange = (event) => {
        event.preventDefault();    
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = {...addFormData};
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };
    
     //Add Submit data
    const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        var error = false;
		var errorMsg = '';
        if(addFormData.name === ""){
            error = true;
			errorMsg = 'Please fill  Service name';
        }else if(addFormData.serviceRate === ""){
            error = true;
			errorMsg = 'Please fill Service Rate.';
        }else if(addFormData.serviceDesc === ""){
			error = true;
			errorMsg = "please fill Service Desc";
		}
        if(!error){
            const newContent = {
                // id: nanoid(),
				serviceId: id,
                name: addFormData.name,
                serviceRate:  addFormData.serviceRate,
                serviceDesc:  addFormData.serviceDesc
             
			};
            
         //console.log(newContent)
			createServiceItemAction(newContent,false);
			//const newList = [...list, list];
            //setList(newList);
			addFormData.name  = addFormData.serviceRate = addFormData.serviceDesc;      
            setAddCard(false);
            swal('Good job!', 'Successfully Added', "success");
              
            
        }else{
			swal('Oops', errorMsg, "error");
		}
    };
	
	//Edit start 
	//const [editModal, setEditModal] = useState(false);	
	// Edit function editable page loop
    const [editContentId, setEditContentId] = useState(null);
   
    // Edit function button click to edit
    const handleEditClick = ( event, content) => {
        event.preventDefault();
        setEditContentId(content._id);
        const formValues = {
            name: content.name,
            serviceRate: content.serviceRate,  
            serviceDesc: content.serviceDesc

        }
        setEditFormData(formValues);
        //setEditModal(true);
    };
   
    // edit  data  
    const [editFormData, setEditFormData] = useState({
        name:'',
        serviceRate:'',
        serviceDesc:'',
    })
    
    //update data function
    const handleEditFormChange = (event) => {
        event.preventDefault();   
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = {...editFormData};
        newFormData[fieldName] = fieldValue;
        setEditFormData(newFormData);
    };
    
    // edit form data submit
    const handleEditFormSubmit = (event) => {
        event.preventDefault();
        const editedContent = {
			serviceId: id,
            _id: editContentId,
            name: editFormData.name,
            serviceRate: editFormData.serviceRate,
            serviceDesc: editFormData.serviceDesc,
      
        }

		createServiceItemAction(editedContent,true);

        //const newList = [...list];
       // const index = list.findIndex((content)=> content.id === editContentId);


        //newList[index] = editedContent;
        //setList(newList);
        setEditContentId(null);
       // setEditModal(false);
    }
	//Cencel button to same data
    const handleCancelClick = () => {
        setEditContentId(null);    
    };
	
	return(
		<>
			{/* <PageTitle activeMenu="" motherMenu="Services" /> */}
			<div className="col-12">
				<Modal className="modal fade"  show={addCard} onHide={setAddCard} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Add Service Item</h4>
									<button type="button" className="btn-close" onClick={()=> setAddCard(false)} data-dismiss="modal"><span></span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											 <div className="form-group mb-3">
												<label className="text-black font-w500">Name</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autoComplete="off"
														name="name" required="required"
														onChange={handleAddFormChange}
														placeholder="Service Name"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Service Rate</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autoComplete="off"
														name="serviceRate" required="required"
														onChange={handleAddFormChange}
														placeholder="Service Rate"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Description</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autoComplete="off"
														name="serviceDesc" required="required"
														onChange={handleAddFormChange}
														placeholder="Service Desc"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="submit" className="btn btn-primary" onClick={handleAddFormSubmit}>Add</button>   
									<button type="button" onClick={()=> setAddCard(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
								</div>
							</form>
							
						</div>
					</div>
				</Modal>
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">M-Right SERVICE FEES</h4>
					</div>
					<div className="card-body">
						<div className="w-100 table-responsive">
							<div id="example_wrapper" className="dataTables_wrapper">
								<form onSubmit={handleEditFormSubmit}>
									<table id="example" className="display w-100 dataTable">
										<thead>
											<tr>
												{/* <th></th> */}
												<th>Service Name</th>
												<th>Rate</th>
												<th>Service Description</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>

										{showLoading ? (
											<Spinner />
											) : (
												<Fragment>

	
											{list?.serviceNames?.map((content, index)=>(
												<tr key={index}>
													{editContentId === content._id ? 
														( 
															<EditService editFormData={editFormData} 
																handleEditFormChange={handleEditFormChange} handleCancelClick={handleCancelClick}/> 
															) : 
														( 
															<>
																{/* <td><img className="rounded-circle" width="35" src={pic1} alt="" /></td> */}
																<td>{content.name}</td>
																<td>{content.serviceRate}</td>
																<td>{content.serviceDesc}</td>
																{/* <td>{content.education}</td>
																<td><Link to={"#"}><strong>{content.mobile}</strong></Link></td>
																<td><Link to={"#"}><strong>{content.email}</strong></Link></td> */}
																<td>
																	<div className="d-flex">
																		<Link className="btn btn-primary shadow btn-xs sharp me-2"
																			onClick={()=> setAddCard(true)}
																		>
																			<i className="fa fa-plus"></i>
																		</Link>
																		<Link  className="btn btn-secondary	 shadow btn-xs sharp me-2"
																			onClick={(event) => handleEditClick(event, content)}
																		>
																			<i className="fas fa-pen"></i>
																		</Link>
																		<Link  className="btn btn-danger shadow btn-xs sharp" 
																			onClick={()=>handleDeleteClick(content._id)}
																		> 
																			<i className="fa fa-trash"></i>
																		</Link>
																			
																	</div>												
																</td>			
															</>   
														)
													}
												</tr>    
											))}
											  </Fragment>
      )}
										</tbody>
									</table>
								</form>	
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}


Services.propTypes = {
	createServiceItemAction: PropTypes.func.isRequired,
	getCurrentServiceAction: PropTypes.func.isRequired,
	deleteServiceAction: PropTypes.func.isRequired,
	list: PropTypes.object.isRequired
  };
  
  const mapStateToProps = (state) => ({
	list: state.ServiceReducer
  });
  
  export default connect(mapStateToProps, { deleteServiceAction, createServiceItemAction, getCurrentServiceAction })(
	Services
  );
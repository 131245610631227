import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';

export const COLUMNS = [
	
	{
		Header : 'Phone Serial',
		Footer : 'Phone Serial',
		accessor: 'text',
		Filter: ColumnFilter,
	},
	{
		Header : 'Phone Status',
		Footer : 'Phone Status',
		accessor: 'phoneStatus',
		Filter: ColumnFilter,
	},
	{
		Header : 'searcher Phone',
		Footer : 'searcher Phone',
		accessor: 'searcherPhone',
		Filter: ColumnFilter,
	},
	{
		Header : 'Search Date',
		Footer : 'Search Date',
		accessor: 'date',
		Cell: ({ value }) => {return format(new Date(value), 'do MMM, yyyy')},
		Filter: ColumnFilter,
	}
	
	
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]
import React, { Fragment } from "react";

const StepTwo = ({
      formData,
      onChange     

}) => {

   return (
      <section>
    
    <div className="row">   

        <br />

        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
              <label className="text-black font-w500">Enter Customer's Username</label>
              <div className="contact-name">
                      <input
                        type="text"  className="form-control"
                        placeholder="Enter Customer's Username"
                        name="receiver_username"
                        value={formData.receiver_username}
                        onChange={onChange}
                      />

                  
              </div>
        </div>

       
       
             <div className="form-group mb-3">
                    

                    <label className="text-black font-w500">Purpose of Credit</label>
                      <div className="contact-name">
                    <select name="purpose" value={formData.purpose} className="form-control" onChange={onChange}  >
                    <option value="">* Select Purpose</option>
                    
                    <option  value='validate'>To Validate Phone</option>
                    <option  value='declare'>To Declare Phone Missing</option>

                        </select>
                      <span className="validation-text"></span>
{/* <small className="form-text">
           Enter Purpose of credit
         </small> */}
                      
                  
                  </div>

      </div>
         
      <div className="form-group mb-3">
          <label className="text-black font-w500">Enter Number of unit to buy</label>
               <div className="contact-name">
                     <input
                          type="number"  className="form-control"
                          placeholder="Enter Number of unit to buy"
                          name="unit"
                          value={formData.unit}
                          onChange={onChange}
                          autoComplete="No"
                        />
                </div>
  
        </div>
         
          <div className="form-group mb-3">
            <label className="text-black font-w500">Credit Amount</label>
            <div className="contact-name">
              <input
                type="text"  className="form-control"
                placeholder="Credit Amount"
                name="credit"
                value={formData.credit}
                // onChange={onChange}
                readOnly
              />
            </div>
        </div>
      
        

    

        <div className="form-group mb-3">
            <label className="text-black font-w500">Enter Phone Shop Owner's Password</label>
            <div className="contact-name">
                <input
                  type="password"  className="form-control"
                  placeholder="Shop Owner Password"
                  name="password"
                  value={formData.password}
                  onChange={onChange}
                />
           
             </div>
         </div>

       
     </div>
  
     </div>

      
  </section>

   );
};

export default StepTwo;

import React from "react";

const StepTwo = ({
  formData,
  amountToPay
 

}) => {

   return (
      <section>
    
    <div className="row">   
   

        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-phone fa-2x" /> */}
         <label className="text-label"><strong>Purpose of Credit</strong>*</label>
           <div>{formData.purpose}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}- 
            onChange={onChange}
          /> 
           <small className="form-text">
           Referral no is required, only if you are reffered by someone to the platform
         </small>*/}</div></div>


<div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-facebook fa-2x" /> */}
          <label className="text-label"><strong>Number of Unit to Buy*</strong></label>
          <div>{formData.unit}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="Phone Name"
            name="phoneName"
            value={formData.phoneName}
            onChange={onChange}
          /> 
          <small className="form-text">
               Add Phone name and version no e.g Infinix smart 5
             </small>*/}</div></div>
   

        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-facebook fa-2x" /> */}
          <label className="text-label"><strong>Credit Amount*</strong></label>
          <div>{formData.credit}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="Phone Name"
            name="phoneName"
            value={formData.phoneName}
            onChange={onChange}
          /> 
          <small className="form-text">
               Add Phone name and version no e.g Infinix smart 5
             </small>*/}</div></div>

             
       

        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-youtube fa-2x" /> */}
          <label className="text-label"><strong>Amount to Pay*</strong></label>
          <div>{amountToPay}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="Phone Status"
            name="phoneStatus"
            value={formData.phoneStatus}
            onChange={onChange}
          /> 
          <small className="form-text">
             Add phone color
             </small>*/}</div></div>

    

      
</div>

      
      </section>
   );
};

export default StepTwo;

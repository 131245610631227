import {
    createService,
    getService,
    getServices,
  
    updateService,
    updateServiceName,
    deleteService
} from '../../services/ServiceApi';
import {
    CONFIRMED_CREATE_SERVICE_ACTION,
    CONFIRMED_CREATE_SERVICE_ITEM_ACTION,
    CONFIRMED_DELETE_SERVICE_ACTION,
    CONFIRMED_EDIT_SERVICE_ACTION,
    CONFIRMED_GET_SERVICES,
    CONFIRMED_GET_SERVICE,
    SERVICE_ERROR
} from './ServiceType';

import {
    setAlert 
    } from './alertAction';

// // Create or update Service
// export const createService =
//   (formData, navigate, edit = false) =>
//   async (dispatch) => {
//     try {
//       const res = await createService('/profile', formData);

//       dispatch({
//         type: GET_SERVICE,
//         payload: res.data
//       });

//       dispatch(
//         setAlert(edit ? 'Profile Updated' : 'Profile Created', 'success')
//       );

//       if (!edit) {
//         navigate('/dashboard');
//       }
//     } catch (err) {
//       const errors = err.response.data.errors;

//       if (errors) {
//         errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
//       }

//       dispatch({
//         type: PROFILE_ERROR,
//         payload: { msg: err.response.statusText, status: err.response.status }
//       });
//     }
//   };

// // Add Experience
// export const addServiceItem = (formData, navigate) => async (dispatch) => {
//   try {
//     const res = await api.put('/profile/experience', formData);

//     dispatch({
//       type: UPDATE_PROFILE,
//       payload: res.data
//     });

//     dispatch(setAlert('Experience Added', 'success'));

//     navigate('/dashboard');
//   } catch (err) {
//     const errors = err.response.data.errors;

//     if (errors) {
//       errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
//     }

//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };


export function deleteServiceAction(serviceId,delContent) {
    return (dispatch, getState) => {
        deleteService(serviceId,delContent).then((response) => {
            dispatch(confirmedDeletetServiceAction(response.data));
            dispatch(
                setAlert('Service Item Deleted', 'success')
            );
           // history.push('/Services');
        });
    };
}

export function confirmedDeletetServiceAction(serviceData) {
    return {
        type: CONFIRMED_DELETE_SERVICE_ACTION,
        payload: serviceData,
    };
}




export function createServiceAction(serviceData) {
 
	return (dispatch, getState) => {
        try {
            console.log(serviceData);
            createService(serviceData).then((response) => {


              dispatch(
                setAlert('service Created', 'success')
              );

                dispatch(confirmedCreateServiceAction(response.data));
               // const status =   edit ? 'User Updated' : 'User Created'
             //   const msg_status = {successMessage: status}
                //   dispatch({
                //       type: CREATE_STATUS,
                //       payload: msg_status
                //     });
                //navigate('/Services');
            });
            
        } catch (err) {
            dispatch({
                type: SERVICE_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
              });
            //   dispatch(
            //     setAlert(err.response.statusText, 'error')
            //   );

       }
       
    };
}
export function createServiceItemAction(serviceData,edit) {
 
	return (dispatch, getState) => {
        try {
        
           updateServiceName(serviceData).then((response) => {

            if(!edit){
                dispatch(
                setAlert('Service Item Created', 'success')
              );
               
            }else{
                dispatch(
                        setAlert('Service Item Updated', 'success')
                    );
            }
              

                dispatch(confirmedCreateServiceItemAction(response.data));
            });
            
        } catch (err) {
            dispatch({
                type: SERVICE_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
              });
             

        }
       
    };
}

export function getServicesAction() {
    return (dispatch, getState) => {
        getServices().then((response) => {
      
        
            dispatch(confirmedGetServicesAction(response.data));
          
        });
    };
}

export function getCurrentServiceAction(serviceId) {
    return (dispatch, getState) => {
        getService(serviceId).then((response) => {

            dispatch(confirmedGetServiceAction(response.data));
          
        });
    };
}

export function confirmedCreateServiceAction(singleService) {
	
    return {
        type: CONFIRMED_CREATE_SERVICE_ACTION,
        payload: singleService,
    };
}

export function confirmedCreateServiceItemAction(singleService) {
	
    return {
        type: CONFIRMED_CREATE_SERVICE_ITEM_ACTION,
        payload: singleService,
    };
}

export function confirmedGetServicesAction(services) {
    return {
        type: CONFIRMED_GET_SERVICES,
        payload: services,
    };
}

export function confirmedGetServiceAction(service) {
    return {
        type: CONFIRMED_GET_SERVICE,
        payload: service,
    };
}

export function confirmedUpdateServiceAction(service) {

    return {
        type: CONFIRMED_EDIT_SERVICE_ACTION,
        payload: service,
    };
}

export function updateServiceAction(service, history) {
    return (dispatch, getState) => {
        updateService(service).then((response) => {
            dispatch(confirmedUpdateServiceAction(response.data));
            history.push('/Services');
        });
			
    };
}
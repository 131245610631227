import React from 'react'
import { parseISO, formatDistanceToNow, format, differenceInCalendarDays } from 'date-fns'

const { ru } = require("date-fns/locale");

function getCurrentDate() {
  const date = new Date();
  const pattern = "PPp";
  return format(date, pattern, { locale: ru });
}

function getDifferenceDate(startDate, endDate) {
  const start = parseISO(startDate);
  const end = parseISO(endDate);
  return differenceInCalendarDays(start, end);
}

// Example usage
export const TimeDiff = ({date})=>{

  const currentDate = getCurrentDate();



const startDate = format(new Date(), 'yyyy-MM-dd');
//const endDate = "2023-12-21";
const endDate = format(new Date(
  new Date(date).getTime() + 15 *60 * 60 * 24 * 1000,
), 'yyyy-MM-dd');
const difference = getDifferenceDate(endDate,startDate );
console.log(`Difference in days ${startDate}-${endDate}:`, difference);

return (
  <span >
       &nbsp; <i>{difference}</i>
  </span>
)

}


export const TimeAgo = ({ timestamp }) => {
  let timeAgo = ''
  if (timestamp) {
    const date = parseISO(timestamp)
    const timePeriod = formatDistanceToNow(date)
    timeAgo = `${timePeriod} ago`
  }

  return (
    <span title={timestamp}>
      &nbsp; <i>{timeAgo}</i>
    </span>
  )
}

export const FormatDate = ({date}) => {
  const dateFormatted = format(new Date(date), 'dd-MM-yyyy')
  return (
    <span >
      The trial period will expire on &nbsp;<i>{dateFormatted}</i>
    </span>
  )

}

export const IsExpired = ({date}) => {

  let todaysDate = new Date();
  let expireDate = new Date(   new Date(date).getTime() + 15 *60 * 60 * 24 * 1000  )
  let expireDateFormated = format(expireDate, 'dd-MM-yyyy');
  if(todaysDate > expireDate) return (<span>Trial Period has Expired - {expireDateFormated}</span>)
  else return (<span>Trial Period remaining<TimeDiff date = {date} /> day(s) to expire on <br />{expireDateFormated} </span>)
  
}
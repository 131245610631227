import React,{ Fragment,useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button} from "react-bootstrap";
import { DropdownBlog } from "./../ConciergeList";
import avt2 from './../../../../images/nouser.png';
import avt4 from './../../../../images/nouser.png';

const Inactive = ({inActiveUsers,showLoading})=>{
	const [data, setData] = useState(
		document.querySelectorAll("#inactive_wrapper tbody tr")
	);
	const sort = 10;
	const activePag = useRef(0);
	const [test, settest] = useState(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
   // use effect
   useEffect(() => {
      setData(document.querySelectorAll("#inactive_wrapper tbody tr"));
      //chackboxFun();
	}, [test]);

	const pad =  (num, size) => {
		num = num.toString();
		while (num.length < size) num = "0" + num;
		return num;
	}
   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};
  
	const chackbox = document.querySelectorAll(".sorting_2 input");
	const motherChackBox = document.querySelector(".sorting_asc_2 input");
   // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);
	const chackboxFun = (type) => {
      for (let i = 0; i < chackbox.length; i++) {
         const element = chackbox[i];
         if (type === "all") {
            if (motherChackBox.checked) {
               element.checked = true;
            } else {
               element.checked = false;
            }
         } else {
            if (!element.checked) {
               motherChackBox.checked = false;
               break;
            } else {
               motherChackBox.checked = true;
            }
         }
      }
    };
	return(
		<>
			<div className="table-responsive">
				<div id="inactive_wrapper" className="dataTables_wrapper no-footer">
					<table
						id="example2"
						className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer"
					>
						<thead>
							<tr role="row">
								<th className="sorting_asc_2 bg-none" >
									<div className="form-check  style-1">
										<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required=""/>
									</div>
								</th>
								<th className="sorting_asc3">Name</th>
								<th className="sorting3">Username</th>
								<th className="sorting3">Phone</th>
								<th className="sorting3">Roles</th>
								<th className="sorting3">Status</th>
							</tr>
						</thead>
						<tbody>


{showLoading ? (
"loading"
) : (
<Fragment>


{inActiveUsers?.length > 0 ? (
inActiveUsers?.map((user,key) => (
<tr role="row" className="odd" key={user._id}>
<td className="sorting_1">
<div className="form-check  style-1">
<input type="checkbox" onClick={() => chackboxFun()} className="form-check-input"
id="customCheckBox2" required=""
/>
</div>
</td>
<td>
<div className="concierge-list-bx d-flex align-items-center">
<img className="me-3 rounded" src={(key%2==0)? avt2:avt4} alt="" />
<div>
<h5 className="fs-8 mb-0 text-nowrap"><Link to={"#"} className="text-black">{user.fname} {user.sname} </Link></h5>
<span className=" text-secondary fs-8 d-block">#{user.ticket && pad(user.ticket,3)}</span>

</div>
</div>
</td>
<td className="">
<p className="mb-0">{user.username}</p>
</td>
<td>
<div>

<span className="font-w600 text-nowrap"><i className="fas fa-phone-alt me-2 "></i>{user.phone}</span>
</div>
</td>
<td>
<div>
{/* <h5 className="text-nowrap">Monday, Friday</h5> */}
<span className="tex-secondary text-nowrap">{(user.roles || []).map((role) => 

( <Button me-2  variant="primary btn-xxs" key={role._id}>
{role.value}
</Button>)


)}</span>
</div>
</td>
<td>
<span  className="font-w600 text-danger">{user.active?'Active':'Inactive'}</span>
</td>
<td><DropdownBlog /></td>
</tr>
))
) : (
<h4>No users found...</h4>
)}

</Fragment>
)}
	
	
</tbody>
					</table>
					<div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
						<div className="dataTables_info">
							Showing {activePag.current * sort + 1} to{" "}
							{data.length > (activePag.current + 1) * sort
								? (activePag.current + 1) * sort
								: data.length}{" "}
							of {data.length} entries
						</div>
						<div
							className="dataTables_paginate paging_simple_numbers mb-0"
							id="example2_paginate"
						>
							<Link
								className="paginate_button previous "
								to="/concierge-list"
								onClick={() =>
								   activePag.current > 0 &&
								   onClick(activePag.current - 1)
								}
							 >
								<i className="fa fa-angle-double-left" aria-hidden="true"></i> Previous
							</Link>
							<span>
								{paggination.map((number, i) => (
								   <Link
									  key={i}
									  to="/concierge-list"
									  className={`paginate_button  ${
										 activePag.current === i ? "current" : ""
									  } `}
									  onClick={() => onClick(i)}
								   >
									  {number}
								   </Link>
								))}
							</span>

							<Link
								className="paginate_button next"
								to="/concierge-list"
								onClick={() =>
								   activePag.current + 1 < paggination.length &&
								   onClick(activePag.current + 1)
								}
							>
								Next <i className="fa fa-angle-double-right" aria-hidden="true"></i>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

Inactive.propTypes = {
	showLoading: PropTypes.bool.isRequired,
	inActiveUsers: PropTypes.object.isRequired
  };
export default Inactive;
import axiosInstance from './AxiosInstance';

export function getServices() {
    return axiosInstance.get(`/services`);
}
export function getService(serviceId) {
    return axiosInstance.get(`services/${serviceId}`);
}

export function createService(serviceData) {
    return axiosInstance.post(`/services`, serviceData);
}

export function updateService(serviceData) {
    return axiosInstance.put(`/services`, serviceData);
}

export function updateServiceName(serviceData) {
    return axiosInstance.patch(`/services/servicenames`, serviceData);
}

export function deleteService(serviceId,serviceData) {
    return axiosInstance.put(`/services/servicenames/${serviceId}`,serviceData);
}

export function formatUsers(usersData) {
    let users = [];
    for (let key in usersData) {
        users.push({ ...usersData[key], id: key });
        console.log(users);
    }

    return users;
}

import React,{ Fragment,useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";

import { DropdownBlog } from "./../ConciergeList";
import room7 from './../../../../images/room/room7.jpg';
import room5 from './../../../../images/room/room5.jpg';
import Spinner from '../../../layouts/Spinner';

import { compareAsc,format } from 'date-fns'
const Booked = ({lists,showLoading,errorMessage}) =>{
	const [data, setData] = useState(
		document.querySelectorAll("#booked_wrapper tbody tr")
	);
	const sort = 10;
	const activePag = useRef(0);
	const [test, settest] = useState(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};


	const isAvailable = (unavailableDates) => {
	
		let today = new Date().toLocaleDateString();
		
		const dates = unavailableDates.map( (date1)=> new Date(date1).getTime())
		console.log(dates);
		console.log(new Date(today).getTime());

		const isFound = dates.includes(new Date(today).getTime())
	
	
		return isFound;
	  };

   // use effect
   useEffect(() => {
      setData(document.querySelectorAll("#booked_wrapper tbody tr"));
      //chackboxFun();
	}, [test]);

  
   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};
  
	const chackbox = document.querySelectorAll(".sorting_6 input");
	const motherChackBox = document.querySelector(".sorting_asc6 input");
   // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);
	const chackboxFun = (type) => {
      for (let i = 0; i < chackbox.length; i++) {
         const element = chackbox[i];
         if (type === "all") {
            if (motherChackBox.checked) {
               element.checked = true;
            } else {
               element.checked = false;
            }
         } else {
            if (!element.checked) {
               motherChackBox.checked = false;
               break;
            } else {
               motherChackBox.checked = true;
            }
         }
      }
    };
	return(
		<>
			<div className="table-responsive">
				<div id="booked_wrapper" className="dataTables_wrapper no-footer">
					<table className="table card-table display mb-4 dataTablesCard booking-table room-list-tbl dataTable no-footer">
						<thead>
							<tr role="row">
								<th className="sorting_asc6 bg-none" >
									<div className="form-check  style-1">
										<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required=""/>
									</div>
								</th>
								<th>Room </th>
															<th>Room Description</th>
															{/* <th>Room Floor</th>
															<th>Facility</th> */}
															<th>Rate</th>
															<th>Status</th>
															<th className="bg-none"></th>
							</tr>
						</thead>
						<tbody>										
						{showLoading ? (
														<Spinner />
														) : 
																											(
															<Fragment>

		{lists?.length > 0 ? (
			lists?.map((list,key) =>
			
			 (
												
				list?.roomNumbers?.map((room,key) => 
					
					{
						const uDates = room.unavailableDates.sort(compareAsc)
						if(isAvailable(room.unavailableDates))
						return (
					<tr role="row" className={(key%2===0)?'even':'odd'} key={room._id}>
				<td className="sorting_7">  
					<div className="form-check   style-1">
						<input type="checkbox" onClick={() => chackboxFun()}
							className="form-check-input" id="customCheckBox21" required=""
						/>
					</div>  
				</td>
				<td>
					<div className="room-list-bx d-flex align-items-center">
						<img className="me-3 rounded" src={(key%2===0)?room7:room5} alt="" />
						<div>
							<span className=" text-secondary fs-14 d-block">#{room.number}</span>
							<span className=" fs-16 font-w500 text-nowrap">{list.title}</span>
						</div>
					</div>
				</td>
				<td className="">
					<span className="fs-16 font-w500 text-wrap">{list.desc}</span>
				</td>
				{/* <td>
					<div>
						
						<span className="fs-16 font-w500">Floor A-1</span>
					</div>
				</td>
				<td className="facility">
					<div>
						<span className="fs-16 comments">AC, Shower, Double Bed, Towel, Bathup, Coffee Set, LED TV, Wifi</span>
					</div>
				</td> */}
				<td>
					<div className="">
						<span className="mb-2">Price </span>	
						<span className="font-w500">&#8358;{list.price.toLocaleString("en-US")}<small className="fs-14 ms-2">/night</small></span>
					</div>
				</td>
				<td>
					<Link to={"#"} className= {!isAvailable(room.unavailableDates)?'btn btn-success btn-md':'btn btn-danger btn-md'} >{(!isAvailable(room.unavailableDates))?'AVAILABLE':'BOOKED'}</Link>
					<span className=" text-secondary fs-14 d-block text-wrap"><b>{ isAvailable(room.unavailableDates)? `Unavailable Dates:`:``}</b></span> 
					<span className=" text-secondary fs-14 d-block text-wrap"><b>{ isAvailable(room.unavailableDates)? `[${format(new Date(uDates[0]), 'dd-MM-yyyy') }] to [${format(new Date(uDates[uDates.length-1]), 'dd-MM-yyyy') }]`:``}</b></span> 
				</td>
				<td><DropdownBlog /></td>
			</tr>
				)})
													
														 ))) : (
															<h4>No rooms found...</h4>
														  )}
		</Fragment>
      )			}			 
						</tbody>
					</table>
					<div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
						<div className="dataTables_info">
							Showing {activePag.current * sort + 1} to{" "}
							{data.length > (activePag.current + 1) * sort
								? (activePag.current + 1) * sort
								: data.length}{" "}
							of {data.length} entries
						</div>
						<div
							className="dataTables_paginate paging_simple_numbers mb-0"
							id="example2_paginate"
						>
							<Link
								className="paginate_button previous disabled"
								to="/room-list"
								onClick={() =>
								   activePag.current > 0 &&
								   onClick(activePag.current - 1)
								}
							 >
								<i className="fa fa-angle-double-left"></i> Previous
							</Link>
							<span>
								{paggination.map((number, i) => (
								   <Link key={i} to="/room-list"
									  className={`paginate_button  ${
										 activePag.current === i ? "current" : ""
									  } `}
									  onClick={() => onClick(i)}
								   >
									  {number}
								   </Link>
								))}
							</span>

							<Link
								className="paginate_button next"
								to="/room-list"
								onClick={() =>
								   activePag.current + 1 < paggination.length &&
								   onClick(activePag.current + 1)
								}
							>
								Next <i className="fa fa-angle-double-right"></i>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Booked
import React, { useState, useRef, useEffect } from "react";
import {Link,useNavigate}  from "react-router-dom";
import {Modal,Button,Col,Dropdown, Tab, Nav, NavItem} from 'react-bootstrap';
// import data from "./tableData.js";

import swal from "sweetalert";
import { connect } from 'react-redux';

  import PropTypes from "prop-types";
  //import Spinner from '../layouts/Spinner';
  //import useAuth from '../../config/hooks/useAuth';
  import {
	changeStatus,
	getProfiles,
	
  } from "../../../store/actions/profileAction";

  const initialState ={

	phoneid: ''

}

const Registrars = ({ getProfiles, changeStatus, profiles }) => {
  
  const navigate = useNavigate();
	const [addCard2, setAddCard2] = useState(false);
  const [addFormData2, setAddFormData2 ] = useState({
    name:'',
    status:'',
    date:'',
    img_link:'',
    elem:''
    
  }); 
  // delete data  
  const handleGrant = (row) => { 
  // e.preventDefault();
    let msg2 = ''
    //
    //let msg = 'Note that deliberate declaring of your phone missing causes authorities to track down to you and there is penalty for this.'
    const grantType = `${row.appStatus?!row.revoke?'Revoke Referral Licence':'Grant Referral Licence':'Approve As a Registrar'}`
    if(grantType==='Revoke Referral Licence'){
      msg2 = 'Once Revoked, the user will no longer be able to user his(er) referral code'
    }else if (grantType==='Grant Referral Licence'){
      msg2 = 'Once granted, the user will be able to user his(er) referral code'
    }else{
      msg2 = 'Once approved, the user will become an MRight Registrar and would be issued referral code'
    }
   
  
    swal({
      title: "Are you sure?",
      text:
        `${msg2}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
      }).then((willDelete) => {
      if (willDelete) {
       
      
        changeStatus({phoneid: row._id, grantType: grantType}, navigate).then((res) => {
        if(res !=='Invalid Credentials'){
            swal(`${row.appStatus?!row.revoke?'Referral Agentship Revoked':'Agentship Licence Granted ':'Approved As a Registrar'}`, {
          icon: "success",
          });
        navigate("/dashboard")
        }else{
          swal("Invalid Credentials!");
        }
        
      });
      } else {
        swal(`Status cancelled!`);
      }
      })
  
  }
  // // delete data  
  // const handleRevoke = (phone) => {
  //   //e.preventDefault();
  //   //let msg = 'Note that deliberate declaring of your phone missing causes authorities to track down to you and there is penalty for this.'
  //   let msg2 = 'Once revoked, the user will no longer be able to use his(er) MRight Registrar referral code'
  
  //   swal({
  //     title: "Are you sure?",
  //     text:
  //       `${msg2}`,
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //     }).then((willDelete) => {
  //     if (willDelete) {
       
  //       changeStatus({phoneid: phone}, navigate).then((res) => {
  //       if(res !=='Invalid Credentials'){
  //           swal(`Revoked as a MRight Registrar!`, {
  //         icon: "success",
  //         });
  //       navigate("/dashboard")
  //       }else{
  //         swal("Invalid Credentials!");
  //       }
        
  //     });
  //     } else {
  //       swal(`Status cancelled!`);
  //     }
  //     })
  
  // }
  
  const onGrant = (event,row)=>{
    event.preventDefault();
    //alert(phone)
    //setFormData({ ...formData, phoneid: phone});
    handleGrant(row) 
  }
    


  const handleDetailClick = ( event, row) => {
    event.preventDefault();
    let img_ext
    let elem 
   if( row.birthday_url){
     img_ext = (row.birthday_url).split(".")
     elem = img_ext[img_ext.length-1]
      // alert(elem)
   }
    

    const formValues = {
        name: row.yourname,
        status:  row.appStatus?!row.revoke?'Approved':'Agentship Revoked':'Pending',
        date: row.date,
        img_link: row.birthday_url,
        elem:( elem==='pdf')?'anchor':'img',
        prac_exp: row.prac_exp,
        comp_cert:row.comp_cert
    }

  setAddFormData2(formValues);

};
  // const onRevoke = (e,phone)=>{
  //   e.preventDefault();
  //   //alert(phone)
  //   //setFormData({ ...formData, phoneid: phone});
  //   handleRevoke(phone) 
  // }






  useEffect(() => {
		getProfiles();
	  }, [getProfiles]);

  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );
  const sort = 5;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
   // chackboxFun();
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  return (
    <div className="col-12">
      	<Modal className="fade bd-example-modal-lg" size="lg"  show={addCard2} onHide={setAddCard2} >
							<form >
								<Modal.Header>
									<Modal.Title><b>User Detail</b></Modal.Title>
									<button type="button" className="btn-close" onClick={()=> {setAddCard2(false); 
                setAddFormData2 ({
                  name:'',
                  status:'',
                  date:'',
                  img_link:'',
                  elem:''
                  
                })  
                } } 
											
										data-dismiss="modal"><span></span></button>
								</Modal.Header>

								<Modal.Body>

									<div className="row ">
										<div className=" mb-3 col-lg-4 text-secondary">
										<label><b>Name</b></label> <br></br>
                    <div className="">
                          {addFormData2.name}
                    </div>
										
										</div>

										<div className=" mb-3 col-lg-4 text-secondary">
										<label><b>Status</b></label><br></br>
                    <div className="">
                      {addFormData2.status}
                    </div>
                  
										</div>


										<div className=" mb-3 col-lg-4 text-secondary">
										<label><b>Date Registered</b></label><br></br>
                    {addFormData2.date}
										</div>
						
									</div>
									<div className="row ">
										<div className="col-lg-4 text-secondary">
										<label><b>Computer Practical Experience</b></label> <br></br>
                    <div className="">
                    {addFormData2.prac_exp?'Yes':'No'}
                    </div>
										
										</div>

										<div className="col-lg-4 text-secondary">
										<label><b>Possession of Certificate</b></label><br></br>
                    <div className="">
                      {addFormData2.comp_cert?'Yes':'No'}
                    </div>
                  
										</div>


										<div className="col-lg-4 text-secondary">
										
										</div>
						
									</div>
									<div className="row ">
										<div className=" mt-4 col-lg-12 text-primary">
                          <label><b>Attachment</b></label> 
                          
                       { addFormData2.elem==='anchor' && (addFormData2.img_link && <a href={`${addFormData2.img_link}`}>Click here to View</a>)}
                       { addFormData2.elem==='img' && (addFormData2.img_link && <img src={addFormData2.img_link} alt="attachment" width='100%' height='750px' />)}
                          
                          {/* <Link to={{ pathname: 'http://localhost:5005/images/06cf8092-ef6f-4854-8fca-1d2596320bb0-2018_06_08-11-38-58-(1).pdf' }} target="_blank" >here
                            </Link> */}
                          {/*  */}


										</div>
                    {/* <Nav>
                                                <NavItem
                              className="navbar"
                              target="_blank"
                              role="link"
                              href={addFormData2.img_link}
                              as="a" //<-- ADD HERE
                          >
                            Click here to View
                          </NavItem>
                    </Nav> */}
                   
					
									</div>

						


								</Modal.Body>
								<Modal.Footer>
										<Button
										variant="danger light"
										onClick={ ()=> setAddCard2(false) }
										>
										Close
										</Button>
										
								</Modal.Footer>
			
							</form>
				     	</Modal>

      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Registrars</h4>
        </div>
        <div className="card-body">
          <div className="w-100 table-responsive">
            <div id="patientTable_basic_table" className="dataTables_wrapper">
              <table
                id="example5"
                className="display dataTable no-footer w-100"
                style={{ minWidth: 600 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label=": activate to sort column descending"
                      style={{ width: 32 }}
                    >
                      <div className="form-check custom-checkbox">
                        <input
                          type="checkbox"
                          onClick={() => chackboxFun("all")}
                          className="form-check-input"
                          id="checkAll"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="checkAll"
                        />
                      </div>
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Patient ID: activate to sort column ascending"
                      style={{ width: 73 }}
                    >
                      Sno
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date Check in: activate to sort column ascending"
                      style={{ width: 100 }}
                    >
                      Name
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Patient Name: activate to sort column ascending"
                      style={{ width: 100 }}
                    >
                      Gender
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Doctor Assgined: activate to sort column ascending"
                      style={{ width: 120 }}
                    >
                     Location
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Disease: activate to sort column ascending"
                      style={{ width: 62 }}
                    >
                      Phone
                    </th>
                     <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Room no: activate to sort column ascending"
                      style={{ width: 66 }}
                    >
                      Date Registered
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Status: activate to sort column ascending"
                      style={{ width: 106 }}
                    >
                      User Category
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Status: activate to sort column ascending"
                      style={{ width: 106 }}
                    >
                      Status
                    </th>
                   
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Action: activate to sort column ascending"
                      style={{ width: 47 }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* profileStatus appStatus yourname sex country state lga phoneno date _id  */}

                  { profiles && profiles.map((row,i) => {
												return (
                          <tr role="row" className="odd" key={i}>
                          <td className="sorting_1">
                            <div className="form-check custom-checkbox ">
                              <input
                                type="checkbox"
                                onClick={() => chackboxFun()}
                                className="form-check-input"
                                id="customCheckBox2"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customCheckBox2"
                              />
                            </div>
                          </td>
                          <td>{i + 1}</td>
                          <td>{row.yourname}</td>
                          <td>{row.sex}</td>
                          <td>{`${row.lga} ${row.state}, ${row.country}`}</td>
                          <td>{row.phone}</td>
                          <td>{row.date}</td>
                          <td>{row.profileStatus}</td>
                          <td>
                        
                            <span className={`badge light ${(row.appStatus)?!row.revoke?'badge-success':'badge-danger':'badge-warning'}`}>
                              <i className={`fa  ${row.appStatus?!row.revoke?'fa-check text-success':'fa-circle text-danger':' fa-circle  text-warning'}  me-1`} />
                              {row.appStatus?row.revoke?'Agentship Revoked':'Approved':'Pending'}
                            </span>
                          </td>
                          
                          <td>
                            <Dropdown className="dropdown ms-auto text-right">
                              <Dropdown.Toggle
                                variant=""
                                className="btn-link i-false"
                                data-toggle="dropdown"
                              >
                                <svg
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x={0} y={0} width={24} height={24} />
                                    <circle fill="#000000" cx={5} cy={12} r={2} />
                                    <circle fill="#000000" cx={12} cy={12} r={2} />
                                    <circle fill="#000000" cx={19} cy={12} r={2} />
                                  </g>
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                <Dropdown.Item className="dropdown-item" as="button" >
                               
                                <div onClick={(event) => {onGrant(event, row);}}>
                                {`${row.appStatus?!row.revoke?'Revoke Agentship Licence':'Grant Agentship Licence':'Approve As an Agent'}`}
                                </div>
                          
                                </Dropdown.Item>
                                {/* <Dropdown.Item className="dropdown-item" as="button">Revoke Referral Licence</Dropdown.Item> */}
                                <Dropdown.Item className="dropdown-item" as="button">

                                <div onClick={(event) => {setAddCard2(true); handleDetailClick(event, row);}}>
                                  View Details
                                </div>
                                
                                  </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>

                        )
											})}
                </tbody>
              </table>

              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/Registrars"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/Registrars"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/Registrars"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Registrars.propTypes = {
  changeStatus: PropTypes.func.isRequired,
  getProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiles: state.profileReducer.profiles,
});

export default connect(mapStateToProps, { getProfiles, changeStatus })(Registrars);

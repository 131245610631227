import React from "react";

const StepTwo = ({
  formData,
  onChange,

  displayPhoneInputs,
  togglePhoneInputs,

}) => {

   return (
      <section>
    
    <div className="row">   
    <div className="my-2">
          {/* <button
            onClick={() => togglePhoneInputs(!displayPhoneInputs)}
            type="button"
            className="btn btn-danger"
          >
            Preview Phone Details
          </button>
          <span className="mx-4">Optional</span> */}
        </div>



   

        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-facebook fa-2x" /> */}
          <label className="text-label"><strong>Phone Name*</strong></label>
          <div>{formData.phoneName}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="Phone Name"
            name="phoneName"
            value={formData.phoneName}
            onChange={onChange}
          /> 
          <small className="form-text">
               Add Phone name and version no e.g Infinix smart 5
             </small>*/}</div></div>

        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-youtube fa-2x" /> */}
          <label className="text-label"><strong>Phone Color*</strong></label>
          <div>{formData.phonecolor}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="Phone Status"
            name="phoneStatus"
            value={formData.phoneStatus}
            onChange={onChange}
          /> 
          <small className="form-text">
             Add phone color
             </small>*/}</div></div>

        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-youtube fa-2x" /> */}
          <label className="text-label"><strong>Phone Condition*</strong></label>
          <div>{formData.phonecondition}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="Phone Status"
            name="phoneStatus"
            value={formData.phoneStatus}
            onChange={onChange}
          /> 
          <small className="form-text">
             Add phone condition e.g new or old
             </small>*/}</div></div>

  
          <div className="col-lg-4 mb-2"><div className="form-group mb-3">
           <label className="text-label"><strong>Phone serial number*</strong></label>
          <div>{formData.phonesno}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="Phone serial no."
            name="phonesno"
            value={formData.phonesno}
            onChange={onChange}
          /> 
           <small className="form-text">
             Phone Serial No. is unique to a phone and sensitive
             </small>*/}</div></div>

<div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-linkedin fa-2x" /> */}
          <label className="text-label"><strong>Confirm Phone Serial Number*</strong></label>
          {/* <label className="text-label"><strong>Phone EMEI*</strong></label> */}
          <div>{formData.emei}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="Phone EMEI No"
            name="emei"
            value={formData.emei}
            onChange={onChange}
          /> *
           <small className="form-text">
             Phone EMEI No. is unique to a phone and sensitive
             </small>/}

       
          {/* <i className="fab fa-instagram fa-2x" /> */}
          </div></div>
       

        {/* <div className="lead">
            <h3>
              Emergency Details for phone recovery  
            </h3>
            <div>
               <small className="form-text">
                  e.g using friends or relative phone no in case of loss or stolen.
                  
                  </small>
            </div>
       
        </div> */}
        {/* <p className="lead">
        or relative phone no in case of loss or stolen.
        </p> */}
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-instagram fa-2x" /> */}
          <label className="text-label"><strong>NOK Name*</strong></label>
          <div>{formData.nok}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="NOK Fullname"
            name="nok"
            value={formData.nok}
            onChange={onChange}
          />
           <small className="form-text">
           Next of kin, NOK fullname
           </small> */}
        </div></div>
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-instagram fa-2x" /> */}
          <label className="text-label"><strong>NOK Relationship*</strong></label>
          <div>{formData.nokrel}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="NOK Reletionship"
            name="nokrel"
            value={formData.nokrel}
            onChange={onChange}
          /> 
           <small className="form-text">
           Next of kin, NOK relationship
           </small>*/}</div></div>
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-instagram fa-2x" /> */}
          <label className="text-label"><strong>NOK Phone*</strong></label>
          <div>{formData.nokphone1}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="NOK Primary Phone"
            name="nokphone1"
            value={formData.nokphone1}
            onChange={onChange}
          /> 
           <small className="form-text">
           Next of kin, NOK Primary Phone to contact in case of emergency or phone theft
           </small>*/}</div></div>
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-instagram fa-2x" /> */}
          <label className="text-label"><strong>NOK Second Phone*</strong></label>
          <div>{formData.nokphone2}</div>
          {/* <input
            type="text"  className="form-control"
            placeholder="NOK Secondary Phone"
            name="nokphone2"
            value={formData.nokphone2}
            onChange={onChange}
          />
           <small className="form-text">
           Next of kin, NOK Secondary Phone to contact in case of emergency or phone theft
           </small> */}</div></div>
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          {/* <i className="fab fa-instagram fa-2x" /> 
          <label className="text-label"><strong>NOK Email*</strong></label>
          <div>{formData.nokemail}</div>*/}
          {/* <input
            type="text"  className="form-control"
            placeholder="NOK Secondary Phone"
            name="nokphone2"
            value={formData.nokphone2}
            onChange={onChange}
          /> 
           <small className="form-text">
           Next of kin, NOK email address
           </small>*/}</div></div>


             {/* <p className="lead">
       ADD MORE....
        </p> */}
        {/* <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          <i className="fab fa-instagram fa-2x" />
          <label className="text-label">NOK Name*</label>
          <div>{formData.nok2}</div>
          <input
            type="text"  className="form-control"
            placeholder="NOK Fullname"
            name="nok"
            value={formData.nok}
            onChange={onChange}
          /> 
           <small className="form-text">
           Next of kin, NOK fullname
           </small>
        </div></div>
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          <i className="fab fa-instagram fa-2x" />
          <label className="text-label">NOK Relationship Name*</label>
          <div>{formData.nok2rel}</div>
          <input
            type="text"  className="form-control"
            placeholder="NOK Reletionship"
            name="nokrel"
            value={formData.nokrel}
            onChange={onChange}
          /> 
           <small className="form-text">
           Next of kin, NOK relationship
           </small></div></div>
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          <i className="fab fa-instagram fa-2x" />
          <label className="text-label">NOK Phone number*</label>
          <div>{formData.nok2phone1}</div>
          <input
            type="text"  className="form-control"
            placeholder="NOK Primary Phone"
            name="nokphone1"
            value={formData.nokphone1}
            onChange={onChange}
          /> 
           <small className="form-text">
           Next of kin, NOK Primary Phone to contact in case of emergency or phone theft
           </small></div></div>
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          <i className="fab fa-instagram fa-2x" />
          <label className="text-label">NOK Second Phone number*</label>
          <div>{formData.nok2phone2}</div>
          <input
            type="text"  className="form-control"
            placeholder="NOK Secondary Phone"
            name="nokphone2"
            value={formData.nokphone2}
            onChange={onChange}
          />
           <small className="form-text">
           Next of kin, NOK Secondary Phone to contact in case of emergency or phone theft
           </small></div></div>
        <div className="col-lg-4 mb-2"><div className="form-group mb-3">
          <i className="fab fa-instagram fa-2x" />
          <label className="text-label">NOK Email*</label>
          <div>{formData.nok2email}</div>
          <input
            type="text"  className="form-control"
            placeholder="NOK Secondary Phone"
            name="nokphone2"
            value={formData.nokphone2}
            onChange={onChange}
          /> 
           <small className="form-text">
           Next of kin, NOK email address
           </small></div></div> */}

      
</div>

      
      </section>
   );
};

export default StepTwo;

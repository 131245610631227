import React, { Fragment,useState } from "react";
import {  useNavigate,useHistory  } from "react-router-dom";
import swal from "sweetalert";
import { connect, useDispatch } from 'react-redux';
import {
  getPostsAction,
    createPostAction,
     loadingToggleAction,
} from '../../../../store/actions/PostActions';

import { ButtonGroup, Dropdown, SplitButton } from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import PageTItle from "../../../layouts/PageTitle";

function PostPage(props) {
    const [title, setTitle] = useState('');
    let errorsObj = { title: '', desc: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [desc, setDesc] = useState('');

   const navigate = useNavigate();
    const dispatch = useDispatch();
    

    function onSignUp(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (title === '') {
            errorObj.title = 'Title is Required';
            error = true;
			swal('Oops', errorObj.title, "error");
        }
        if (desc === '') {
            errorObj.desc = 'desc is Required';
            error = true;
			swal('Oops', errorObj.desc, "error");
        }
        setErrors(errorObj);
        if (error) return;
        dispatch(loadingToggleAction(true));
        dispatch(createPostAction({title, desc},navigate));
       // dispatch(getPostsAction());
        return
        
    }
    return (
     
 <Fragment>
      <PageTItle activeMenu="Element" motherMenu="Form" pageContent="Element" />

      <div className="row">
    
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Vertical Form</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={onSignUp}>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Title</label>
                    <div className="col-sm-9">
                    <input
                            defaultValue={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="form-control"
                            placeholder="Title"
                          />
                    </div>
                  </div>
                  {errors.title && <div>{errors.title}</div>}
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Desc</label>
                    <div className="col-sm-9">
                    <input
                            defaultValue={desc}
                            onChange={(e) =>
                              setDesc(e.target.value)
                            }
                            className="form-control"
                            placeholder="Desc"
                            //defaultValue="desc"
                          />
                    </div>
                  </div>
                  {errors.desc && <div>{errors.desc}</div>}
                  
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        Create Post
                      </button>
                    </div>
                  </div>
                  <div className="new-account mt-3">
                        
                        <p>RESULT:{props.posts}</p>
                      </div>
                </form>
              </div>
            </div>
          </div>
        </div>
 
      </div>
    </Fragment>

        
     
    );
};

const mapStateToProps = (state) => {
    return {
        posts:state.posts.posts,
        errorMessage: state.posts.errorMessage,
        successMessage: state.posts.successMessage,
        showLoading: state.posts.showLoading,
    };
};

export default connect(mapStateToProps)(PostPage);


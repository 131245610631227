import React,{useState,useEffect,Fragment} from 'react';
import {Link}  from "react-router-dom";
import {Modal,Button,Col} from 'react-bootstrap';
//import { Row, Col, Card, Button, Dropdown, ButtonGroup } from "react-bootstrap";
import {nanoid} from 'nanoid';
import swal from "sweetalert";
import PageTitle from "../layouts/PageTitle";
import pic1 from './../../images/profile/small/pic1.jpg';
//import Editable from './Editable';
import EditNote from './EditNote';
import { connect } from 'react-redux';
import {
	createNoteAction,
	getNotesAction,
	deleteNoteAction,
	updateNoteAction,
	getGuestByPhoneAction
  } from '../../store/actions/NoteActions';
  import PropTypes from "prop-types";
  import Spinner from '../layouts/Spinner';
  import { format } from 'date-fns'
 // import useAuth from '../../config/hooks/useAuth';
// const tableList = [
// 	{
// 		id:"1", name:'Tiger Nixon', department:'Architect',gender:'Male', 
// 		education:'M.COM., M.B.A', mobile:'12345 67890', email:'info1@example.com'
// 	},
// 	{	
// 		id:"2", name:'Gloria Little', department:' Administrator',gender:'Male', 
// 		education:'BTech, MTech', mobile:'09876 54321', email:'info2@example.com'
// 	},
// 	{	
// 		id:"3", name:'Bradley Greer', department:'Software Engineer',gender:'Male', 
// 		education:'B.CA M.CA', mobile:'98765 67890', email:'info3@example.com'
// 	},
// 	{	
// 		id:"4", name:'Gloria Little', department:' Administrator',gender:'Male', 
// 		education:'BTech, MTech', mobile:'09876 54321', email:'info4@example.com'
// 	},
// 	{
// 		id:"5", name:'Tiger Nixon', department:'Architect',gender:'Male', 
// 		education:'M.COM., M.B.A', mobile:'12345 67890', email:'info5@example.com'
// 	},
// 	{	
// 		id:"6", name:'Bradley Greer', department:'Software Engineer',gender:'Male', 
// 		education:'B.CA M.CA', mobile:'98765 67890', email:'info6@example.com'
// 	},
// ]; 




const initialState = {
	_id:null,
	text:'',
	services:'',
	client:[]
   // cust_phone:'',
	// cust_fname:'',
	// cust_sname:'',
	// cust_email:'',
  };


const Notes = ({ getGuestByPhoneAction,updateNoteAction,deleteNoteAction,createNoteAction, getNotesAction,list: { lists, showLoading,successMessage,errorMessage } }) =>{
	

	//const dateTime = format(new Date(), 'yyyyMMdd\tHH:mm:ss')
	// const [clients, setClients] = useState({
    //     _id:null,
    //     text:'',
    //     services:'',
    //    // cust_phone:'',
    //     cust_fname:'',
    //     cust_sname:'',
	// 	cust_email:'',
    // });
//	const { department,cust_phone } = useAuth()
    const [phone, setPhone] = useState('');
	
 
	// delete data  
    const handlePhoneCheckClick = () => {

	setPhone(addFormData.cust_phone);  

   


	// else{

	// 	{
	// 		addFormData.client  = addFormData.text=addFormData.cust_fname=addFormData.cust_sname='';  
		
	// 		swal('Phone does not exist', 'New Guest!', "success");
	// 	}
	// }
	

	}
    const handleDeleteClick = (contentId) => {
       // const newList = [...list];    
       // const index = list.findIndex((content)=> content.id === contentId);
        //newList.splice(index, 1);
        //setList(newList);

		//const delContent = {_id: contentId};

        swal({
			title: "Are you sure, DELETE?",
			text:
			  "Once deleted, you will not be able to recover this record!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {
			  deleteNoteAction(contentId);
			  swal("Record has been deleted!", {
				icon: "success",
			  });
                
			} else {
			  swal("Record is not deleted!");
			}
		  })



		


    }
	
	//Modal box
	const [addCard, setAddCard] = useState(false);
	//Add data 
    const [addFormData, setAddFormData ] = useState({
        client:null,
        text:'',
        services:'',
        cust_phone:'',
        cust_fname:'',
        cust_sname:'',
		cust_email:'',
    }); 

    	useEffect(() => {
           getNotesAction()
	   }, [getNotesAction]);

    	useEffect(() => {
			const userData = { ...initialState };
			const clientNew =lists.find((element) => {
				return element?.client?.cust_phone === phone
			  })
		 
			  for (const key in clientNew) {
				if (key in userData) userData[key] = clientNew[key];
			  }
			const newFormData = {...addFormData};
			//const cust_phone = 'cust_phone'
			const cust_fname ='cust_fname';
			const cust_sname = 'cust_sname';
			const cust_email = 'cust_email';
			const client = 'client';
			newFormData[client] = userData._id;
			//newFormData[cust_phone] = userData?client..client?.cust_phone;
			newFormData[cust_fname] = userData.client.cust_fname;
			newFormData[cust_sname] = userData.client.cust_sname;
			newFormData[cust_email] = userData.client.cust_email;

			setAddFormData(newFormData);

			console.log('client'+JSON.stringify(userData))
			console.log('client'+JSON.stringify(newFormData))
			 
				 
		 
		// 		 swal('Phone Returns Detail', 'Guest Already Exist!', "error");
			// }

		}, [phone,lists]);



    // Add Note function
    const handleAddFormChange = (event) => {
        event.preventDefault();    
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = {...addFormData};
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };
    
     //Add Submit data
    const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        var error = false;
		var errorMsg = '';
        // if(addFormData.client === ""){
        //     error = true;
		// 	errorMsg = 'Please fill  Guest Surname';
        // }else
		 if(addFormData.text === ""){
            error = true;
			errorMsg = 'Please fill Guest Notes';
        }else if(addFormData.services === ""){
			error = true;
			errorMsg = "please fill Guest Services";
		
        }else if(addFormData.cust_phone === ""){
			error = true;
			errorMsg = "please fill Guest Phone & Click on Confirm Existence";
		
        }else if(addFormData.cust_fname === ""){
			error = true;
			errorMsg = "please fill Guest Firstname";
		
        }else if(addFormData.cust_sname === ""){
			error = true;
			errorMsg = "please fill Guest Surname";
		}
        if(!error){
			// let  cust_fname =  ''
			// let cust_sname =  ''
			// let cust_phone =  ''
			// let client = ''
			//if(!clients){
            //     cust_fname =  addFormData.cust_fname
			//    cust_sname =  addFormData.cust_sname
			//    cust_phone =  addFormData.cust_phone
			// }else{
			// 	 cust_fname =  clients.cust_fname
            //      cust_sname = clients.cust_sname

			// }
			//(addFormData.client)?client=clients._id:client=''
			const newContent = {
                // id: nanoid(),
				//noteId: id,
                client: addFormData._id,
                text:  addFormData.text,
                services:  addFormData.services,
                cust_phone:  addFormData.cust_phone,
                cust_fname:  addFormData.cust_fname,
                cust_sname:  addFormData.cust_sname
             };
            
         //console.log(newContent)
			createNoteAction(newContent);
			//const newList = [...list, list];
            //setList(newList);
			
            
			if(errorMessage.status === 400){
				return false
			}
			else{
			setAddCard(false);
            console.log('Invalid stuff'+errorMessage.msg.status)
			swal('Good job!', 'Guest Successfully Added', "success");

			}
addFormData.client  = addFormData.text = addFormData.cust_phone=addFormData.services=addFormData.cust_fname=addFormData.cust_sname='';      
              
            
        }else{
			swal('Oops', errorMsg, "error");
		}
    };
	
	//Edit start 
	//const [editModal, setEditModal] = useState(false);	
	// Edit function editable page loop
    const [editContentId, setEditContentId] = useState(null);
   
    // Edit function button click to edit
    const handleEditClick = ( event, content) => {
        event.preventDefault();
        setEditContentId(content._id);
        const formValues = {
           client: content.client._id,
            text: content.text,  
			services:  content.services,
			completed:  content.completed,
			// cust_phone:  content.cust_phone,
			// cust_fname:  content.cust_fname,
			// cust_sname:  content.cust_sname,

        }
        setEditFormData(formValues);
        //setEditModal(true);
    };
   
    // edit  data  
    const [editFormData, setEditFormData] = useState({
        client:'',
        text:'',
      //  cust_phone:'',
        services:'',
        // cust_sname:'',
        // cust_fname:'',
		completed:''
    })
    
    //update data function
    const handleEditFormChange = (event) => {
        event.preventDefault();   
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = {...editFormData};
        newFormData[fieldName] = fieldValue;
        setEditFormData(newFormData);
    };
    
    // edit form data submit
    const handleEditFormSubmit = (event) => {
        event.preventDefault();
        const editedContent = {
		//	noteId: id,
            _id: editContentId,
            client: editFormData.client,
            text: editFormData.text,
         //   cust_phone: editFormData.cust_phone,
            services: editFormData.services,
            completed: editFormData.completed,
          //  cust_fname: editFormData.cust_fname,
            //cust_sname: editFormData.cust_sname,
			
      
        }

		updateNoteAction(editedContent);

        //const newList = [...list];
       // const index = list.findIndex((content)=> content.id === editContentId);


        //newList[index] = editedContent;
        //setList(newList);
        setEditContentId(null);
       // setEditModal(false);
    }


	//Cencel button to same data
    const handleCancelClick = () => {
        setEditContentId(null);    
    };
	
	const onActiveChanged = () => 
	{
	  
	  setEditFormData({ ...editFormData, completed:editFormData.completed?false:true});
	}

	return(
		<>
			{/* <PageTitle activeMenu="" motherMenu="Notes" /> */}
			<div className="col-12">
			<Modal className="fade bd-example-modal-lg" size="lg"  show={addCard} onHide={setAddCard} >
					<form >
					<Modal.Header>
                    <Modal.Title>Add Guest Note</Modal.Title>
                    <button type="button" className="btn-close" onClick={()=> setAddCard(false)} data-dismiss="modal"><span></span></button>
                  </Modal.Header>
						
				  
							
			<Modal.Body>
					<div className="row">
						<div className="form-group mb-3 col-md-6">
						<label>Guest Phone</label>
							<input type="text" className="form-control" autoComplete="off"
								name="cust_phone" required="required"
								onChange={handleAddFormChange}
								placeholder="Guest Phone"
							/>
						</div>
					</div>	
					<div className="row">
							<div className="form-group mb-3 col-md-6">
						<label></label>
							<Button className="me-2" variant="primary btn-xs" onClick={handlePhoneCheckClick}>
								Confirm Existence
							</Button>
							

						</div>
					</div>
					
					<div className="row">
						<div className="form-group mb-3 col-md-12">
							<hr size='70%'/>

						</div>
					
					</div>
                   
                    
				  <div className="row ">
                    <div className="form-group mb-3 col-md-4">
                      <label><code><b>Guest Surname</b>   </code></label> 
						<input type="text" className="form-control {(addFormData.client)?'text-primary':''}" autoComplete="off"
							name="cust_sname" required="required"
							onChange={handleAddFormChange}
							placeholder="Guest Surame"
							value={(addFormData.cust_sname)?addFormData.cust_sname:''}
							disabled={(addFormData.client)?'disabled':''}
						/>
                    </div>
                    <div className="form-group mb-3 col-md-4 {(addFormData.client)?'text-primary':''}">
                      <label><code><b>Guest Firstname</b></code></label>
						<input type="text" className="form-control" autoComplete="off"
							name="cust_fname" required="required"
							onChange={handleAddFormChange}
							placeholder="Guest Firstname"
							value={(addFormData.cust_fname)?addFormData.cust_fname:''}
							disabled={(addFormData.client)?'disabled':''}
						/>
                    </div>
                    <div className="form-group mb-3 col-md-4 {(addFormData.client)?'text-primary':''}">
                      <label><code><b>Guest Email</b></code></label>
						<input type="text" className="form-control" autoComplete="off"
							name="cust_email"
							onChange={handleAddFormChange}
							placeholder="Guest Email"
							value={(addFormData.cust_email)?addFormData.cust_email:''}
							disabled={(addFormData.client)?'disabled':''}
						/>
                    </div>
                   
               </div>
			   <div className="row">
						<div className="form-group mb-3 col-md-12">
							<hr size='70%'/>

						</div>
					
					</div>
				  <div className="row">
                    <div className="form-group mb-3 col-md-8">
                      <label>Visit Note</label>
						<input type="text" className="form-control" autoComplete="off"
							name="text" required="required"
							onChange={handleAddFormChange}
							placeholder="Guest purpose of visit"
						/>
                    </div>
                    <div className="form-group mb-3 col-md-4">
                      <label>Intended Services</label>
						<input type="text" className="form-control" autoComplete="off"
							name="services" required="required"
							onChange={handleAddFormChange}
							placeholder="Intended Services"
						/>
                    </div>
                   
                   
               </div>

			</Modal.Body>
             <Modal.Footer>
                    <Button
                      variant="danger light"
					  onClick={()=> setAddCard(false)}
                    >
                      Close
                    </Button>
                    <Button
                      variant=""
                      type="submit"
                      className="btn btn-primary"
					  onClick={handleAddFormSubmit}
					  disabled={(addFormData.services && addFormData.text && addFormData.cust_fname && addFormData.cust_sname)?'':'disabled'}
                    >
                      Save changes
                    </Button>
              </Modal.Footer>
               
                  
							</form>
				</Modal>
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">{('Members Notes').toUpperCase()}</h4>
					</div>
					<div className="card-body">
						<div className="w-100 table-responsive">
							<div id="example_wrapper" className="dataTables_wrapper">
								<form onSubmit={handleEditFormSubmit}>
									<table id="example" className="display w-100 dataTable">
										<thead>
											<tr>
												{/* <th></th> */}
												<th>SCN N0.</th>
												<th>Members</th>
												<th>Intended Services</th>
												 <th>Status</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>

										{showLoading ? (
											<Spinner />
											) : (
												<Fragment>

	
											{lists?.map((content, index)=>(
												<tr key={index}>
													{editContentId === content._id ? 
														( 
															<EditNote editFormData={editFormData} onActiveChanged={onActiveChanged}
																handleEditFormChange={handleEditFormChange} handleCancelClick={handleCancelClick}/> 
															) : 
														( 
															<>
																{/* <td><img className="rounded-circle" width="35" src={pic1} alt="" /></td> */}
																<td><b>SCN-{content.ticket}</b><br />{format(new Date('2023-06-15T11:44:59.201Z'), 'dd-MM-yyyy\tHH:mm:ss')}</td>
																<td>{content.client?.cust_fname}<br />{(content.client?.cust_sname)}<br />{(content.client?.cust_phone)}</td>
																{/* <td>{content.cust_phone}</td> */}
																<td> <ul className="list-icons">{
																content.services.map((service,index) => {
																	return (
																		<li key={index}>
																		<Link to={"#"}>
																		  <span className="align-middle me-2">
																			<i className="fa fa-check text-info"></i>
																		  </span>{" "}
																		{service}  
																		</Link>
                  														</li>

																	)
																})
																}   </ul></td>
																<td>{(!(content.completed)? <Button className="me-2" variant="success btn-rounded">
                 <b>OPEN</b> 
                </Button>:<Button className="me-2" variant="danger btn-rounded">
                  <b>CLOSED</b>
                </Button> )}</td>
																{/* <td>{content.education}</td>
																<td><Link to={"#"}><strong>{content.mobile}</strong></Link></td>
																<td><Link to={"#"}><strong>{content.email}</strong></Link></td> */}
																<td>
																	<div className="d-flex">
																		<Link className="btn btn-primary shadow btn-xs sharp me-2"
																			onClick={()=> {setAddCard(true);addFormData.client  = addFormData.text = addFormData.cust_phone=addFormData.services=addFormData.cust_fname=addFormData.cust_sname='';   }}
																		>
																			<i className="fa fa-plus"></i>
																		</Link>
																		<Link  className="btn btn-secondary	 shadow btn-xs sharp me-2"
																			onClick={(event) => handleEditClick(event, content)}
																		>
																			<i className="fas fa-pen"></i>
																		</Link>
																		<Link  className="btn btn-danger shadow btn-xs sharp" 
																			onClick={()=>handleDeleteClick(content._id)}
																		> 
																			<i className="fa fa-trash"></i>
																		</Link>
																			
																	</div>												
																</td>			
															</>   
														)
													}
												</tr>    
											))}
											  </Fragment>
      )}
										</tbody>
									</table>
								</form>	
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}


Notes.propTypes = {
	updateNoteAction: PropTypes.func.isRequired,
	createNoteAction: PropTypes.func.isRequired,
	getNotesAction: PropTypes.func.isRequired,
	deleteNoteAction: PropTypes.func.isRequired,
	getGuestByPhoneAction: PropTypes.func.isRequired,

	list: PropTypes.object.isRequired
  };
  
  const mapStateToProps = (state) => ({
	list: state.NoteReducer
  });
  
  export default connect(mapStateToProps, { updateNoteAction,deleteNoteAction, createNoteAction, getNotesAction, getGuestByPhoneAction })(
	Notes
  );
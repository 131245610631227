import {
    createInvoice,
    getInvoice,
    getInvoices,
  
    updateInvoice,
    updateInvoiceName,
    deleteInvoice
} from '../../services/InvoiceApi';
import {
    CONFIRMED_CREATE_INVOICE_ACTION,
    CONFIRMED_CREATE_INVOICE_ITEM_ACTION,
    CONFIRMED_DELETE_INVOICE_ACTION,
    CONFIRMED_EDIT_INVOICE_ACTION,
    CONFIRMED_GET_INVOICES,
    CONFIRMED_GET_INVOICE,
    INVOICE_ERROR
} from './InvoiceType';

import {
    setAlert 
    } from './alertAction';

// // Create or update Invoice
// export const createInvoice =
//   (formData, navigate, edit = false) =>
//   async (dispatch) => {
//     try {
//       const res = await createInvoice('/profile', formData);

//       dispatch({
//         type: GET_INVOICE,
//         payload: res.data
//       });

//       dispatch(
//         setAlert(edit ? 'Profile Updated' : 'Profile Created', 'success')
//       );

//       if (!edit) {
//         navigate('/dashboard');
//       }
//     } catch (err) {
//       const errors = err.response.data.errors;

//       if (errors) {
//         errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
//       }

//       dispatch({
//         type: PROFILE_ERROR,
//         payload: { msg: err.response.statusText, status: err.response.status }
//       });
//     }
//   };

// // Add Experience
// export const addInvoiceItem = (formData, navigate) => async (dispatch) => {
//   try {
//     const res = await api.put('/profile/experience', formData);

//     dispatch({
//       type: UPDATE_PROFILE,
//       payload: res.data
//     });

//     dispatch(setAlert('Experience Added', 'success'));

//     navigate('/dashboard');
//   } catch (err) {
//     const errors = err.response.data.errors;

//     if (errors) {
//       errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
//     }

//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };


export function deleteInvoiceAction(invoiceId, history) {
    return (dispatch, getState) => {
        deleteInvoice(invoiceId).then((response) => {
            dispatch(confirmedDeletetInvoiceAction(invoiceId));
            history.push('/Invoices');
        });
    };
}

export function confirmedDeletetInvoiceAction(invoiceId) {
    return {
        type: CONFIRMED_DELETE_INVOICE_ACTION,
        payload: invoiceId,
    };
}




export function createInvoiceAction(invoiceData) {
 
	return (dispatch, getState) => {
        try {
            console.log(invoiceData);
            createInvoice(invoiceData).then((response) => {


              dispatch(
                setAlert('invoice Created', 'success')
              );

                dispatch(confirmedCreateInvoiceAction(response.data));
               // const status =   edit ? 'User Updated' : 'User Created'
             //   const msg_status = {successMessage: status}
                //   dispatch({
                //       type: CREATE_STATUS,
                //       payload: msg_status
                //     });
                //navigate('/Invoices');
            });
            
        } catch (err) {
            dispatch({
                type: INVOICE_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
              });
              dispatch(
                setAlert(err.response.statusText, 'error')
              );

       }
       
    };
}
export function createInvoiceItemAction(invoiceData) {
 
	return (dispatch, getState) => {
        try {
        
           updateInvoiceName(invoiceData).then((response) => {


              dispatch(
                setAlert('Invoice Item Created', 'success')
              );

                dispatch(confirmedCreateInvoiceItemAction(response.data));
            });
            
        } catch (err) {
            dispatch({
                type: INVOICE_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
              });
             

        }
       
    };
}

export function getInvoicesAction() {
    return (dispatch, getState) => {
        getInvoices().then((response) => {
      
        
            dispatch(confirmedGetInvoicesAction(response.data));
          
        });
    };
}

export function getCurrentInvoiceAction(invoiceId) {
    return (dispatch, getState) => {
        getInvoice(invoiceId).then((response) => {

            dispatch(confirmedGetInvoiceAction(response.data));
          
        });
    };
}

export function confirmedCreateInvoiceAction(singleInvoice) {
	
    return {
        type: CONFIRMED_CREATE_INVOICE_ACTION,
        payload: singleInvoice,
    };
}

export function confirmedCreateInvoiceItemAction(singleInvoice) {
	
    return {
        type: CONFIRMED_CREATE_INVOICE_ITEM_ACTION,
        payload: singleInvoice,
    };
}

export function confirmedGetInvoicesAction(invoices) {
    return {
        type: CONFIRMED_GET_INVOICES,
        payload: invoices,
    };
}

export function confirmedGetInvoiceAction(invoice) {
    return {
        type: CONFIRMED_GET_INVOICE,
        payload: invoice,
    };
}

export function confirmedUpdateInvoiceAction(invoice) {

    return {
        type: CONFIRMED_EDIT_INVOICE_ACTION,
        payload: invoice,
    };
}

export function updateInvoiceAction(invoice, history) {
    return (dispatch, getState) => {
        updateInvoice(invoice).then((response) => {
            dispatch(confirmedUpdateInvoiceAction(response.data));
            history.push('/Invoices');
        });
			
    };
}
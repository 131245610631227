import React, {   useState, Fragment }  from "react";
import { Link,useNavigate } from "react-router-dom";
import axios from 'axios';
import swal from "sweetalert";
import { format } from 'date-fns';
// image
import logo from "../../images/logo-full.png";
import { Row, Card, Col, ListGroup, Badge, Tab } from "react-bootstrap";

const initialState ={

  searcherphoneno:'',
	tnc: false,
  phones:[]

}
const ForgotPassword = () => {
 
  const [dataBlock] = useState({
    title:"Forgot Your Password?",
    subTitle:"Change your password in three easy steps. This helps to keep your new password secure."
}) 
const navigate = useNavigate();
const [searchPhone, setSearchPhone] = useState(false);
const [formData, setFormData] = useState(initialState);
let errorsObj = {	 emei:'',  phonesno:'',  searcherphoneno:''  };
const [errors, setErrors] = useState(errorsObj);
const {	emei,tnc,searcherphoneno	} = formData;
const [flag, setFlag] = useState(false);
const [sentMail, setSentMail] = useState(false);
const [processFlag, setProcessFlag] = useState(false);
const [showPhone, setShowPhone] = useState(false);
const [data, setData] = useState({});
const [phone, setPhone] = useState([]);


let DataMe = {}
const onChange = (e) =>{
  setFormData({ ...formData, [e.target.name]: e.target.value });
 //if ((e.target.value).length > 8 ) getPhone ()
}


let apicall = ''
if(process.env.REACT_APP_ENV === 'development') {
  apicall = process.env.REACT_APP_ENV_API_LC
}else{
  apicall = process.env.REACT_APP_ENV_API
}

const onClick = (e) =>{
 
  setProcessFlag();
}
const ontncChanged = (e) => 
{
  e.preventDefault();
  setFormData({ ...formData, tnc:formData.tnc?false:true});
  //setShowPhone(true);
  showPhone? setShowPhone(false): setShowPhone(true)
}

const ontncChanged2 = (e) => {

  setPhone(prevState => {
    // check if it is already added
    if(prevState.includes(e.target.value)) { 
      // clone the prevState arr to prevent side effects  
       const clone = [...prevState];
     // Remove the existing id 
       clone.splice(prevState.indexOf(e.target.value), 1)
       return clone;
    } else {
       return [...prevState, e.target.value]
    }});


  setPhone([...phone, e.target.value])
  
};
//console.log(phone);


const getPhone =  (e)=> {
  e.preventDefault();
  try {
      // const uri = encodeURI(
      //   `https://api.github.com/users/njsj56&dumd23n^Fdhd/repos?per_page=5&sort=created:asc`
      // );
      // const headers = {
      //   'user-agent': 'react.js',
      //   Authorization: `token Fm4cVv9ZTVQg123xdfbCtFwU-sxxdne-EPT8YSbOxpdNwf_mIE`
      // };
  
      //  axios.get(uri, { headers })
      
      axios.put(`${apicall}/site/reset`,formData).then((res) => {
         // console.log(res?.data?.isError)
          if(res?.data?.isError){
              
              if(res?.data?.msg?.errors[0].msg ===`another profile` ){
                  swal('Oops',"Phone number does not exist in the system", {
                  icon: "error",
                  });
              }else{
                  swal('Oops',res?.data?.msg?.errors[0].msg , {
                      icon: "error",
                      });

              }

          }else{
             
            setData(res?.data)   
              setFlag(true)
              setProcessFlag(true)
             
             // console.log(res.data)
 
          }
    
      }) .catch((error) => {
         console.error(error)
       });

    } catch (err) {
      console.error(err?.message);
     // return res?.status(404).json({ msg: 'No App profile found' });
    }

 }


function getDeclare(){
		
  try {
      // const uri = encodeURI(
      //   `https://api.github.com/users/njsj56&dumd23n^Fdhd/repos?per_page=5&sort=created:asc`
      // );
      // const headers = {
      //   'user-agent': 'react.js',
      //   Authorization: `token Fm4cVv9ZTVQg123xdfbCtFwU-sxxdne-EPT8YSbOxpdNwf_mIE`
      // };
  
      //  axios.get(uri, { headers })
      
      axios.put(`${apicall}/site/declare`,{ ...formData, phones: phone }).then((res) => {
         // console.log(res?.data?.isError)
          if(res?.data?.isError){
              
              if(res?.data?.msg?.errors[0].msg ===`another profile` ){
                  swal('Oops',"Phone number or email does not exist in the system", {
                  icon: "error",
                  });
              }else{
                  swal('Oops',res?.data?.msg?.errors[0].msg , {
                      icon: "error",
                      });

              }

          }else{

            swal(res?.data?.message, {
              icon: "success",
              });
              setSentMail(true)
              //setData(res?.data)    
             // setFlag(true)
              //setProcessFlag()
//                 setFormData({})
             
              
             
          }
    
      }) .catch((error) => {
         console.error(error)
       });

//console.log(formData)


    } catch (err) {
      console.error(err?.message);
     // return res?.status(404).json({ msg: 'No App profile found' });
    }

 }

 function onSubmit(e) {
  e.preventDefault();
  let error = false;
  const errorObj = { ...errorsObj };

if (searcherphoneno === '') {
      errorObj.searcherphoneno = 'Phone number is Required';
      error = true;
swal('Oops', errorObj.searcherphoneno, "error");
  }

setErrors(errorObj);
if (error) return;

getPhone (e);



}

function onProcess(e) {
  e.preventDefault();
  let error = false;
  const errorObj = { ...errorsObj };

if (searcherphoneno === '') {
      errorObj.searcherphoneno = 'Email or Phone no is Required';
      error = true;
swal('Oops', errorObj.searcherphoneno, "error");
  }


setErrors(errorObj);
if (error) return;
console.log(phone)
//setFormData({ ...formData, phones: phone });
getDeclare ()
//
//search_result(data) 


}



function formatDate(date) {
  const dateTime = format(new Date(date), 'h:m aa, Do MMM, YYYY')
  return dateTime;
}

function search_result (data){
  const message = `
This ${data.phonecolor} ${data.phoneName} belongs to ${data?.name},declared ${data?.phoneStatus} at ${formatDate(data.date)} contact; 05064366666, 03046789934, 06024793345
`
return message
}
//const valuesArray = JSON.parse(data);
// const ForgotPassword = ({ history }) => {
//   const onSubmit = (e) => {
//     e.preventDefault();
//     history.push("/dashboard");
//   };
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        { ''}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/dashboard">
                        <img src={logo} alt="" width='250' />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Forgot Password</h4>
                    <div className="text-center m-4 "><button className="btn btn-warning" onClick={() => navigate(-1)}>go back</button></div>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group  mb-4">
                        <label className="">
                          <strong>Phone</strong>
                        </label>
                        <input
                                     name="searcherphoneno"
                                     value={formData.searcherphoneno}
                                     onChange={onChange}
                                     placeholder="phone number"
                          type="text"
                          className="form-control"
                          
                        />
                      </div>
              
                      {/* { flag && (
                      
                     
                      
                      <div className="col">
                        <div className="form-check custom-checkbox mb-3 checkbox-warning check-xl"> 
                            <div className="text-center">    
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheckBox9"
                                      >if you are on emergency situation to log in and declare your phone missing or stolen but forgot your password, please check on the below box for the system to automatically declare your phone missing even before you reset your account</label>
                                
                                      <input
                                        type="checkbox"
                                        onClick={ontncChanged}
                                        className="p-10"
                                        id="customCheckBox9"
                                        name={formData.tnc}
                                        value={formData.tnc}
                                        required
                                      />
                                    { showPhone && 
                                      <Card>
                                        <Card.Header>
                                          <Card.Title>Select phones to declare missing</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                          <ListGroup as="ul" variant="flush">
                                            {(data.phone_detail).map((list, i) => (
                                              <Fragment key={i}>
                                                <ListGroup.Item as="li"><b>{`${i+1}. `} {`${list.phoneName} - [ ${list.phonecolor} ]`}</b> <input
                                        type="checkbox"
                                      onClick={ontncChanged2}
                                        className="p-10"
                                        id="customCheckBox9"
                                        name={`phone_${list._id}`}
                                        value={list._id}
                                        required
                                      /></ListGroup.Item>
                                              </Fragment>
                                            ))}
                                          </ListGroup>
                                        </Card.Body>
                                    </Card>
                                    }


                            </div>
                
                         </div>
                 
                       </div>)} */}

                { !processFlag && (<div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-secondary btn-block"
                          onClick={onSubmit}
                      //  disabled= { flag?'disabled':''} 
                        >
                          SUBMIT
                        </button>
                      </div>)}
                      
                { flag && (<div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-warning btn-block"
                          onClick={onProcess}
                          // disabled= { sentMail?'disabled':''} 
                        >
                          Process Reset
                        </button>
                      </div>)}
                      


                    </form>
                    <div className="text-center mt-6">
                    <p>
                           
               
                  </p>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate}  from "react-router-dom";
import DownloadContent from "./DownloadContent";
import Tnc from "./Tnc";

import swal from "sweetalert";
import PageTitle from "../layouts/PageTitle";
import pic1 from './../../images/profile/small/pic1.jpg';

import { connect } from 'react-redux';

  import PropTypes from "prop-types";
  import Spinner from '../layouts/Spinner';
  //import useAuth from '../../config/hooks/useAuth';
  import {

	
  } from "../../store/actions/profileAction";


  const initialState ={
	
	password: '',
	phoneid: '',
	prof_id:null


}
const DeletePhone = ({
	

	profile: { profile, loading },
	
	getCurrentProfile,

  
  }) =>{
	

	const navigate = useNavigate();




	useEffect(() => {
		// if there is no profile, attempt to fetch one
		!profile && getCurrentProfile();
		 
	  }, [loading, profile]);

	


	  
	  const recheck = async()=>{

		const phone_ = profile?.phone_detail.find((element) => {
		  return element.regStatus === true;
		  })
		  
		  return phone_.regStatus ===true

		// if(!phone_)
  
		//   swal("Please validate at least one added phone to activate these links to use them..\n\n Thank you.", {
		// 	icon: "warning",   });
  
		//   else	setAddCard3(true)
  
	  }
	  const alertMe = async()=>{
		!recheck() && 
		swal("Please validate at least one added phone to activate these links to use them..\n\n Thank you.", {
			icon: "warning",   }) 

			// setTimeout(()=>{
			// 	navigate('/my-phones')
			// },2000)
			
		
	  }
    

	

	return(
		<>
			{/* <PageTitle activeMenu="" motherMenu="Services" /> */}
		
				
			<div className="col-12">
					<div className='col-lg-4 mb-2'>
							<button className="btn btn-warning" onClick={() => navigate(-1)}>go back</button>
					</div>
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">Download Page</h4>
					</div>
					<div className="card-body">
					
					<div className="" role="document">
						<div className="">
						

						{ recheck() &&  !profile.tnc_download? <Tnc /> : <DownloadContent />}

						</div>
					</div>
			
					</div>
				</div>
			</div>
		</>
	)
}


DeletePhone.propTypes = {

	profile: PropTypes.object.isRequired,
	
	
  };
  const mapStateToProps = (state) => ({
	profile: state.profileReducer,



  });
  const mapDispatchToProps = {  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(
	DeletePhone
  );
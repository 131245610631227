

import { useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'

export const selectCurrentToken = (state) => state.auth.auth.idToken

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isManager = false
    let isAdmin = false
    let status = "Employee"

    if (token) {
        
        const decoded = jwtDecode(token)
        const { username, roles, permission, dept, bal } = decoded.UserInfo

        isManager = roles.includes('Manager')
        isAdmin = roles.includes('Admin')

        if (isManager) status = "Manager"
        if (isAdmin) status = "Admin"
        let department = null
        let serviceDesc = null
        if(dept[0].value ==="Laundary and Dry Cleaning") {department='64825662d06fb1530b4c89e9'}
        else if(dept[0].value ==="SPA and Gym") {department='6483122c1f3a07d1b739fd89'}
        else if(dept[0].value ==="Canteen") {department='6488c8b767904e7e25752b1c'}
        else if(dept[0].value ==="Front Desk") {department='6488c8f967904e7e2575b1a5'}
        else if(dept[0].value ==="Swimming Pool") {department='6488c94567904e7e25764189'}
        else if(dept[0].value ==="Party Palace") {department='6488c9a967904e7e2576ff66'}
        else if(dept[0].value ==="Hall and Banquet") {department='6488ca0867904e7e2577aad7'}	

        serviceDesc = dept[0].value;


        return { username, roles, permission, department, status, isManager, isAdmin, serviceDesc, bal }
    }

    return { username: '', roles: [], permission:[], department:[], isManager, isAdmin, status , serviceDesc:'', bal: 0}
}
export default useAuth
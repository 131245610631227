import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate}  from "react-router-dom";
import swal from "sweetalert";
import PageTitle from "../layouts/PageTitle";
import pic1 from './../../images/profile/small/pic1.jpg';

import { connect } from 'react-redux';

  import PropTypes from "prop-types";
  import Spinner from '../layouts/Spinner';
  //import useAuth from '../../config/hooks/useAuth';
  import {
	deletePhoneAction_forsale,
	getCurrentProfile,
	
  } from "../../store/actions/profileAction";


  const initialState ={
	
	phoneStatus: 'IN USE',
	phoneid: '',
	password: '',
	
	prof_id:null,
	status:false
}
const StolenPhone = ({
	
	deletePhoneAction_forsale,
	profile: { profile, loading },
	
	getCurrentProfile,

  
  }) =>{
	

	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialState);
	const [id, setId] = useState('');
	const [displayInUse, setDisplayInUse] = useState(false);
	// delete data  
    const handleDelete = () => {

		let msg = 'Forsale'
		let msg2 = 'IN USE'
		let message = ''
		displayInUse?message=msg2:message=msg 

        swal({
			title: "Are you sure?",
				text:
			  `Once declared, the details of the declared phone changes to ${message}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {
			 
				deletePhoneAction_forsale(formData, navigate).then((res) => {
				if(res !=='Invalid Credentials'){
					swal(`Phone has been declared ${message}!`, {
					icon: "success",
					});
					navigate("/my-phones")
				}else{
					swal("Invalid Credentials!");
				}
				
			});
			} else {
				swal(`Phone is not declared ${message}!`);
			}
		  })

    }
	
	const {	password, phoneid,phoneStatus} = formData;
	let errorsObj = {
		phoneid:'',
		password:'',
		phoneStatus:''
	   };

	   useEffect(() => {
		const userData = { ...initialState };
		// if there is no profile, attempt to fetch one
		!profile && getCurrentProfile();
		id  && getPhoneStatus()
		// if(profile && !loading ) {
			
		// 	const user = profile?.phone_detail.find((element) => {
		// 		return element._id === formData.phoneid;
		// 		})
		// 		//console.log(user);
			 
		// 	  for (const key in user) {
		// 		//console.log(key);
		// 		if (key in userData) userData[key] = user[key];
		// 	  }
  		// // set local state with the userData
		//   		 setFormData(userData);
		// 		//   
			
		// }
	  }, [loading, profile, getCurrentProfile, id]);

	  const [errors, setErrors] = useState(errorsObj);
	  const onChange = (e) =>{
			  setFormData({ ...formData, [e.target.name]: e.target.value,prof_id: profile._id ,phoneStatus:'Forsale' });
	  
			  setId(e.target.value);
	  }
	  
	  const getPhoneStatus = ()=>{

		let found =''
		if(profile){
				found = profile?.phone_detail?.find(function (element) {
				return element._id === id;
			});
		}
//console.log(found)
if(found && (found.phoneStatus=='Forsale' || found.phoneStatus=='Missing or Stolen' )) {
			setDisplayInUse(true);
			//setFormData({ ...formData, phoneid: phoneID, prof_id: profile._id, phoneStatus:'IN USE' });

		}
	

	}

	const onComCertChanged = () => 
	{
	  
	  setFormData({ ...formData, status:formData.status?false:true,phoneStatus:'IN USE'});

	}
    
     //Add Submit data
    const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
       
		 if (phoneid === '') {
            errorObj.phoneid = 'Phone is Required';
            error = true;
			swal('Oops', errorObj.phoneid, "error");
        }
		 if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
			swal('Oops', errorObj.password, "error");
        }
		



        setErrors(errorObj);
        if (error) return;
		handleDelete()
    };
	

	return(
		<>
			{/* <PageTitle activeMenu="" motherMenu="Services" /> */}
			<div className="col-12">
				
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">Declare Phone For Sale</h4>
					</div>
					<div className="card-body">
					
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Declare Phone For Sale</h4>
									
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											
											<div className="form-group mb-3">
												<label className="text-black font-w500">Phone</label>
												<div className="contact-name">
												<select name="phoneid" value={formData.phoneid} className="form-control" onChange={onChange}  >
												<option value="">* Select Phone</option>
													{ 
									
									
											   profile?.phone_detail  && profile?.phone_detail?.map(
												({
												_id,
												phoneName,
												phonesno,
												phoneStatus
													}) => (
												<option key={_id} value={_id}>{`${phoneName}- ${phoneStatus}\n Sno.:${phonesno}`}</option>
												))
										
													} 

										
          
         									 </select>
													<span className="validation-text"></span>
												</div>
											</div>
											{displayInUse && (
													<div className="form-group mb-3">
												<label className="text-black font-w500">Reverse Status to IN USE</label>
												<div className="contact-name">
												<input
                      type="checkbox"
                      
                      className="form-check-input p-3"
                      id="customCheckBox9"
                      required

                      name={formData.status}
                      value={formData.status}
                      checked ={ formData.status && 'checked' }
                      // onChange={(e) =>
                      //   setActive(e.target.value)
                      // }
                      onChange={onComCertChanged}


                    />
													<span className="validation-text"></span>
												</div>
											</div>
											)}
											<div className="form-group mb-3">
												<label className="text-black font-w500">Login Password</label>
												<div className="contact-name">
												<input type="password"  className="form-control"  autoComplete="off"
														name="password" required="required"
														onChange={onChange}
														placeholder="Password is required"
													/>
													
													<span className="validation-text"></span>
												</div>
											</div>
											
											
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="submit" className="btn btn-secondary" onClick={handleAddFormSubmit}>Declare {displayInUse?'IN USE': 'Forsale'}</button>   
									<button type="button" className="btn btn-warning" >  <Link className="btn btn-warning " to="/dashboard">
									Discard
        </Link></button>     
								</div>
							</form>
							
						</div>
					</div>
			
					</div>
				</div>
			</div>
		</>
	)
}


StolenPhone.propTypes = {
	deletePhoneAction_forsale: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	
	
  };
  const mapStateToProps = (state) => ({
	profile: state.profileReducer,



  });
  const mapDispatchToProps = { deletePhoneAction_forsale,getCurrentProfile };
    export default connect(mapStateToProps, mapDispatchToProps)(
	StolenPhone
  );
import { v4 as uuidv4 } from "uuid";
import { REMOVE_ALERT, SET_ALERT } from "../actions/AlertTypes";
export const setAlert =
  (msg, alertType, timeout = 5000) =>
  (dispatch) => {
   console.log('Action here');
    const id = uuidv4(); // to generate the unique ids
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id },
    });
 
    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };

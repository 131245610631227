
const DownloadPage = ()=>{

 

return (
<div className="col-10">

 <div className="card-content fw-bold ">
                    <p className="text-justify ">
                    The download page 
First, You Must Download the first 3 file/app for maximum security and anti theft accuracy. Once done and use without negligence, it will be very hard for thieves to steal your phone. Installing the other optional apps with consideration to read the information under each link and abiding the configurations setting guidelines will be so helpful.
                    </p>
                    <p className="text-justify">
                    The Configurations settings and the guidelines;               
                     </p>
                    <p className="text-justify">
                    a.)	Password your Main SIM connected to your bank accounts and leave the other Sim without password. it’s also strongly recommended to always/often subscribe internet data with the other SIM that you did not assign password. This is  to track your phone instantly if stolen. Ask mobile operators company such as mtn, Airtel, 9mobile etc on how to password your SIM. Phone shop agents can guide you too but Note that guiding you on how to password your Sim does not mean to allow them to know your sim password. For security reasons, the password should set by you secretly and don’t forget to  keep around your PUK Number in case you forget your SIM password. 
                     </p>
                    <p className="text-justify">
                    b)	We assumed that your data is always on or recommended to  always/often leave your data on, If your phone is having battery or charging light problem, please enable your data when entering suspicious gathering or area. It is easier to track a phone stolen with data on.


                    <p className="text-justify">
                    c)	For phones that cannot leave data on often, due to battery or frequent charging light problem, install the app in the link number 6 of this page. It’s still under review but will help to track phone that is not connected to internet.
                    </p>
                    <p className="text-justify">
                    d)	The other configurations settings will not be explained in detail here for security reason. Seek assistance of Mright phone shop agents who are purposely trained on that.                    </p>
                    </p>
                    <p className="text-justify">
                    THE DOWNLOADS / INSTALLATIONS LINKS;                     </p>
                    <p className="text-justify">
            
 
 1.	https://localhost:5005/images/mrightprotectionfiles.zip
              </p>
                    <p className="text-justify">
             
 2.	https://play.google.com/store/apps/details?id=com.argonremote.proximitysensoralarm  <br />
  This anti theft sensor app can go a long way preventing your phone and  is for Android version 10 and above. Other lower android versions may try it depending on the phone type but accuracy is not assured for some few phone types due to some factors.
                          </p>
                    <p className="text-justify">
                
 3.	https://play.google.com/store/apps/details?id=com.lsdroid.lsp  <br />
 This app prevents switching off of phone without password. Can also preventing offing of data or enabling of flight mode while the phone screen is locked.
 
                  </p>
                    <p className="text-justify">
                   
 4.	https://play.google.com/store/apps/details?id=com.ibragunduz.applockpro <br />
 This app is for phone application access restriction. For example if you give someone a video to watch in your phone. He cannot access beyond the video folder in the phone without your permission.
                  </p>
                    <p className="text-justify">
                   
 5.	https://play.google.com/store/apps/details?id=app.greyshirts.firewall <br />
  Firewall for online security and prevention of draining  data by unwanted and unused apps. If your data finishes in lesser time than usual, this app is for you. Ask phone shop agents on how to set it.
 
                     </p>
                    <p className="text-left">6. https://apkcombo.com/location-sms-offline-phone-finder/com.jamieturner.www.locationsms/download/apk#google_vignette <br />
                    </p>
                    <p className="text-justify">
                    <br />
                    This app is to track phone via sms. It’s intended to track stolen phone not connected to internet. NOTE that this app is still under review and not assured for most of the modern android versions. Therefore, use it with caution at your risk.
 
  
                  </p>
                    <p className="text-justify">
                    <b>
                    IMPORTANT INFORMATION!
                    </b>
                     </p>
                    <p className="text-justify">
                    Be informed that thieves now after your SIM Cards than the  phone hardware itself. This is to get access to your bank account, email, social media account, BVN, NIN etc. Make sure you assign Password to your main SIM card. Don’t assign password to all your 2 sims in the phone, if you do that, your lost phone could not be tracked. The best recommended  practice that works with M-right system is to assign password to your main SIM connected to your bank and be subscribing data with your other Sim.  Visit mobile operators company such as mtn, Airtel, Glo, 9mobile etc or Ask M-right phone shop agents to guide you on how to password your SIM Card. Note that guiding you on how to password your Sim does not mean to allow them to know your sim password. For security reasons, the password should set by you secretly and don’t forget to  keep around your PUK Number in case you forget your SIM password.
                         </p>
        
                    <p className="text-justify">
                    Thank you and expect to see more relevant updated important apps in this page.                          </p>
                  </div>


</div>)




}

export default DownloadPage;
import React, {  useState,useEffect } from "react";
import {  Button, Modal } from "react-bootstrap";

const StepOne = ({

  formData,
  onChange,
  changeCountry,
  changeState,
  changeService,
  creatingProfile,
  displaySocialInputs,
  toggleSocialInputs,
  stateLGA,
  province,
  serviceFlag,
  ontncChanged,
  lgovt
  
}) => {
  const [contentModal, setContentModal] = useState(false);


  
   return (
      <section>
         <div className="row">
         <div className="my-6">
          <button
           
            type="button"
            className="btn btn-dark"
          >
            {creatingProfile ? "Create Your  Personal Details" : "Edit Your  Personal Details"}
           
          </button>
          <span className="mx-4">Optional</span>
          <br />
          <br />
          <br />
        </div>
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Your FullName*</label>
                  <input
                     type="text"
                     placeholder="Your FullName"
                     className="form-control"
                     name="yourname"
                     value={formData.yourname}
                     onChange={onChange}
                     required='required'
                     readOnly
                  />
                 
          <small className="form-text">
            your fullname 
            {/* or Guardian's name whose name you bear */}
          </small>
               </div>
            </div>
            {/* <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Father Name*</label>
                  <input
                     type="text"
                     placeholder="Father's Name"
                     className="form-control"
                     name="father"
                     value={formData.father}
                     onChange={onChange}
                     required
                  />
                 
          <small className="form-text">
            your surname or Guardian's name whose name you bear
          </small>
               </div>
            </div> */}
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Sex*</label>
                  {/* <input
                     type="text"
                     placeholder="Sex"
                     className="form-control"
                     name="sex"
                     value={formData.sex}
                     onChange={onChange}
                     required
                  /> */}
                 <select name="sex"  value={formData.sex}  onChange={onChange} className="form-control" required='required'>
                    <option value=''>sex</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
               </select>    
          <small className="form-text">
            your gender
          </small>
               </div>
            </div>
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Address*</label>
                  <input
                     type="text"
                     placeholder="address"
                     className="form-control"
                     name="address"
                     value={formData.address}
                     onChange={onChange}
                     required='required'
                  />
                 
          <small className="form-text">
            your location, where you currently stay.
          </small>
               </div>
            </div>
            {/* <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Profession Status*</label>
                  <select name="status" value={formData.status}  onChange={onChange} className="form-control">
            <option>* Select Professional Status</option>
            <option value="Developer">Developer</option>
         
            <option value="Project Manager">Project Manager</option>
            <option value="Student or Learning">Student or Learning</option>
            <option value="Instructor">Instructor or Teacher</option>
            <option value="Accountant">Accountant</option>
            <option value="Lawyer">Lawyer</option>
            <option value="Intern">Intern</option>
            <option value="Other">Other</option>
          </select>
          <small className="form-text">
            Give us an idea of where you are at in your career
          </small>
               </div>
            </div> */}
            {/* <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Company*</label>
                
                  <input
               type="email"
               className="form-control"
               id="inputGroupPrepend2"
               aria-describedby="inputGroupPrepend2"
            placeholder="Company"
            name="company"
            value={formData.company}
            onChange={onChange}
          />
          <small className="form-text">
            Could be your own company or one you work for
          </small>
               </div>
            </div> */}
            {/* <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Website*</label>
                  
                  <input
            type="text"
            placeholder="Website"
            name="website"
            value={formData.website}
            onChange={onChange}
            className="form-control"
          />
          <small className="form-text">
            Could be your own or a company website
          </small>
               </div>
            </div> */}
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Nationality*</label>
                
                  <select name="country"  value={formData.country}  onChange={changeCountry} className="form-control">
    <option value=''>Country</option>
    <option value='Nigeria'>Nigeria</option>
    <option value='US'>US</option>
    <option value='UK'>UK</option>
    <option value='Canada'>Canada</option>
  
    
    </select>
          <small className="form-text">
            Select the Country where you currently reside / domicile
          </small>
               </div>
            </div>
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">State*</label>
                  
                  <select name="state" value={formData.state} className="form-control" onChange={changeState} >
    <option value={""}>* Select State</option>
    {  creatingProfile && (province==='Nigeria' && Object.entries(stateLGA)?.map(([key,val]) => (
      <option key={key} value={key}>{key}</option>
    )))}

    {  !creatingProfile && (formData.country==='Nigeria' && Object.entries(stateLGA)?.map(([key,val]) => (
      <option key={key} value={key}>{key}</option>
    )))}


    </select>
          <small className="form-text">
            City & state suggested (eg. Lagos, LA)
          </small>
       
               </div>
            </div>
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">LGA*</label>
                  
                  <select name="lga" value={formData.lga} className="form-control" onChange={onChange}  >
                  <option value={0}>* Select LGA</option>
          { 
     
     creatingProfile &&

       (   lgovt  && Object.entries(stateLGA[lgovt])?.map(([key,val]) => (
                  <option key={key} value={val}>{val}</option>
                )))
          
          } 

          
          { 
     
     !creatingProfile &&
       (   formData.state  && Object.entries(stateLGA[formData.state])?.map(([key,val]) => (
                  <option key={key} value={val}  >{val}</option>
                )))
          
          } 
          </select>
          <small className="form-text">
            Local Government Area[LGA] of the place you currently stay or leave
          </small>
       
               </div>
            </div>

            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Phone no*</label>
                  
             
        <input
            type="text"
            placeholder="* Phone Number"
            name="phoneno"
            value={formData.phoneno}
            onChange={onChange}
            className="form-control"
            required='required'
            readOnly
          />
          <small className="form-text">
           1st Phone number
          </small>
               </div>
            </div>
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">2nd Phone Number*</label>
                  
       
      <input
            type="text"
            placeholder="2nd Phone Number (optional)"
            name="phoneno2"
            value={formData.phoneno2}
            onChange={onChange}
            className="form-control"
            
          />
          <small className="form-text">
          2nd Phone number (optional)
          </small>
               </div>
            </div> 
            {/* <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Date of Birth*</label>
                  
       
      <input
            type="date"
            placeholder="Date of Birth"
            name="birthday"
            value={formData.birthday}
            onChange={onChange}
            className="form-control"
          />
          <small className="form-text">
          *Date of Birth(18 yrs and above validation)
          </small>
               </div>
            </div>  */}
            <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Referral Code*</label>
                  
    
      <input
                type="text"
                placeholder="Skip this field if you are not an agent"
                name="referralno"
                defaultChecked
                value={formData.referralno}
                onChange={onChange}
                className="form-control"
              />
               <small className="form-text">
                 Referral code is unique to an agent. Ask your agent for it (if any). 
               </small>
               </div>
            </div> 

            <div className="col-lg-8 mb-2  pt-4 mb-3 checkbox-danger check-xl">
              <div className="form-group ">
              
                  <input
                      type="checkbox"
                      
                      className="form-check-input p-4"
                      id="customCheckBox9"
                      required='required'

                      name={formData.tnc}
                      value={formData.tnc}
                      checked ={ formData.tnc && 'checked' }
                      // onChange={(e) =>
                      //   setActive(e.target.value)
                      // }
                      onChange={ontncChanged}


                    />
                    <label
                      className="form-check-label"
                      htmlFor="active"
                    >
                     <h4><strong> <Button
                  variant="primary"
                  className=" p-3"
                  onClick={() => setContentModal(true)}
                >
                  Terms & Conditions
                </Button></strong> </h4>
                    </label>
                  </div>



                  </div>


                 
                {/* <!-- Modal --> */}
                <Modal className="fade" show={contentModal}>
                  <Modal.Header>
                    <Modal.Title>Terms and Conditions</Modal.Title>
                    <Button
                      variant=""
                      className="btn-close"
                      onClick={() => setContentModal(false)}
                    >
                      
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                    This Term and Condition describes Our policies and procedures on the collection, use and disclosure of information in compliance with NDPR standard. This site and it's data privacy policy is currently under review by accredited DPCO for necessary suggestions and adjustments.

                   </p>
                    <p>
                    We use Your Personal data solemnly for the stated purpose of protecting your phones and returning of stolen but recovered phones to the actual owner and to provide an improve Services. By using the Service, You read to the end in the terms and conditions section and agree to the collection and use of your information as categorized and defined in details in the full version in accordance with this Privacy Policy.

                    </p>
                  
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="danger light"
                      onClick={() => setContentModal(false)}
                    >
                      Close
                    </Button>
                    {/* <Button variant="primary">Save changes</Button> */}
                  </Modal.Footer>
                </Modal>
            {/* <div className="col-lg-6 mb-4">
               <div className="form-group mb-3">
                  <label className="text-label">Bio*</label>
                 
                   <textarea
            placeholder="A short bio of yourself"
            name="bio"
            value={formData.bio}
            onChange={onChange}
            className="form-control"
            required
          />
          <small className="form-text">Tell us a little about yourself</small>
               </div>
            </div> */}


            {/* <div className="my-2">
          <button
            onClick={toggleSocialInputs(!displaySocialInputs)}
            type="button"
            className="btn btn-danger"
          >
            Social Network Links
          </button>
          <span className="mx-4">Optional</span>
        </div>
     
         <div className="col-lg-4 mb-4"><div className="form-group mb-3">
         <i className="fab fa-twitter fa-2x" />
              <input
                type="text"  className="form-control"
                placeholder="Twitter URL"
                name="twitter"
                value={formData.twitter}
               onChange={onChange}
              />
            </div>  </div>

               <div className="col-lg-4 mb-4"><div className="form-group mb-3">
              <i className="fab fa-facebook fa-2x" />
              <input
                type="text"  className="form-control"
                placeholder="Facebook URL"
                name="facebook"
                value={formData.facebook}
               onChange={onChange}
              />
            </div>  </div>

               <div className="col-lg-4 mb-2"><div className="form-group mb-3">
              <i className="fab fa-youtube fa-2x" />
              <input
                type="text"  className="form-control"
                placeholder="YouTube URL"
                name="youtube"
                value={formData.youtube}
               onChange={onChange}
              />
            </div>  </div>

               <div className="col-lg-4 mb-2"><div className="form-group mb-3">
              <i className="fab fa-linkedin fa-2x" />
              <input
                type="text"  className="form-control"
                placeholder="Linkedin URL"
                name="linkedin"
                value={formData.linkedin}
               onChange={onChange}
              />
            </div>  </div>

               <div className="col-lg-4 mb-2"><div className="form-group mb-3">
              <i className="fab fa-instagram fa-2x" />
              <input
                type="text"  className="form-control"
                placeholder="Instagram URL"
                name="instagram"
                value={formData.instagram}
               onChange={onChange}
              />
            </div>  </div> */}
        
         </div>
      </section>
   );
};

          

export default StepOne;

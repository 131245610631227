import React, { Fragment, useRef,useState, useEffect } from "react"
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai"
import { v4 as uuidv4 } from "uuid"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Link}  from "react-router-dom";
import swal from "sweetalert";
import {Button} from 'react-bootstrap';
import useAuth from '../../../../config/hooks/useAuth';
import { Calendar } from 'react-date-range'
import format from 'date-fns/format'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'



//Transaction component

const initialState = {
  serviceCat: '', 
  serviceNames:[]
};
function TxnComponent({
  description,
  setDescription,
  quantity,
  setQuantity,
  price,
  setPrice,
  amount,
  isEditing,
  services,
  loading,
  setServiceDesc,
  setServiceitem,

}){
  const { department,serviceDesc:departmentTitle } = useAuth()
  const [id, setId] = useState(department);
  const [serviceOptions, setServiceOptions] = useState([]);

  
  useEffect(() => {

    if (services ) {
     
        const service = services.find((element) => {
          return element._id === id;
        })
          
          setServiceOptions(service);
          setServiceDesc(departmentTitle);
       //   setServiceDesc(departmentTitle);

    }
    
  else{

    setServiceOptions([]);
   
    }  
  }, [ services, id]);

  const handleSelect = (e) => {
    const selectedarr = (e.target.value).split("-");
   const selectedService= { serviceItemId: selectedarr[0],serviceId:selectedarr[1],serviceName:selectedarr[2],serviceRate:selectedarr[3],serviceDesc:selectedarr[4] };
    setDescription(selectedService.serviceDesc)
    setServiceitem(selectedService.serviceName)
    setPrice(selectedService.serviceRate)
 
 
 
    //  console.log(selectedClients)
//key={service._id} value={`${service._id}-${serviceOptions._id}-${service.name}-${service.serviceRate}-${service.serviceDesc}`}
  
  };
  


  return (
    <Fragment>

      
<div className="row">

  <hr></hr>
  <br></br>
</div>


<div className="row">
 
     
     <div className="col mt-2 mt-sm-0">
       <label htmlFor="description"><strong>Description</strong></label>
          {/* <input
           className="form-control"
            type="text"
            name="description"
            id="description"
            placeholder="Item description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          /> */}

              <select  onChange={handleSelect}  className="form-control"
                     
                      id="description"
                      name = "description"
                      >
                   <option value='' key='768765865469878898'>Select Service</option>
                  {loading
                    ? "loading"
                    : serviceOptions &&
                      serviceOptions.serviceNames?.map((service) => (
                        <option key={service._id} value={`${service._id}-${serviceOptions._id}-${service.name}-${service.serviceRate}-${service.serviceDesc}`}>
                          {`${service.name} - ${service.serviceRate} `}
                        </option>
                      ))}
                </select>
        </div>

      
          <div className="col mt-2 mt-sm-0">
          <label htmlFor="quantity"><strong>Quantity</strong></label>
            <input
             className="form-control"
              type="text"
              name="quantity"
              id="quantity"
              placeholder="Quantity"
              min={1}
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>

          <div className="col mt-2 mt-sm-0">
          <label htmlFor="price"><strong>Price</strong></label>
            <input
             className="form-control"
              type="text"
              name="price"
              id="price"
              placeholder="Price"
              value={price}
              //onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          <div className="col mt-2 mt-sm-0">
            <label htmlFor="amount">Amount</label>
            <p>{amount}</p>
          </div>
         
          <div className="col mt-2 mt-sm-0">
        <Button
          type="submit"
          className="btn btn-success" 
        >
          {isEditing ? "Editing Row Item" : "Add Item to List"}
        </Button>
        </div>
    <br></br>
    </div>
 
   

<hr></hr>




    </Fragment>
 


  )

}; //Service Transaction component ends

function ReserveComponent({
  description,
  setDescription,
  quantity,
  setQuantity,
  price,
  setPrice,
  amount,
  isEditing,
  services,
  loading,
  setServiceDesc,
  setServiceitem,
  rooms,
  roomId,
  setRoomId,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dates, 
  setDates, 
   open, 
  setOpen,
   open2, 
  setOpen2,
  calendar, 
  setCalendar,
  calendar2, 
  setCalendar2

}){
  const { department,serviceDesc:departmentTitle } = useAuth()
  const [id, setId] = useState(department);
  const [serviceOptions, setServiceOptions] = useState([]);
   // get the target element to toggle 
 const refOne = useRef(null)
 const refTwo = useRef(null)
 
  
  const getDatesInRange = (startDate, endDate) => {


    const start = new Date(startDate);
    const end = new Date(endDate);
//
    const date = new Date(start.getTime());
    
    const dates = [];

    while (date <= end) {
      dates.push(new Date(date).getTime());
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };

  const alldates = getDatesInRange(calendar, calendar2);

  const isAvailable = (roomNumber) => {
    const dates = roomNumber.unavailableDates.map( (date1)=> new Date(date1).getTime())
    
    const isFound = dates.some((date) =>
      alldates.includes(new Date(date).getTime())
      
    );
 
    return isFound;
  };
  
  useEffect(() => {

    if (rooms ) {
  
          setServiceOptions(rooms);

          setServiceDesc(departmentTitle);


    }
    
  else{

        setServiceOptions([]);
   
    }  

    handleDates()

  }, [ rooms, id,calendar,calendar2]);


  useEffect(() => {

    
    handleDates()
    const selectedDatarre = (description).split("Booked:");
         setDescription(`${selectedDatarre[0]} Booked:[${calendar} - ${calendar2}]`)

  }, [calendar,calendar2]);


  const handleSelect = (e) => {
    const selectedarr = (e.target.value).split("-");
   const selectedService= { serviceItemId: selectedarr[0],serviceId:selectedarr[1],serviceName:selectedarr[2],serviceRate:selectedarr[3],serviceDesc:selectedarr[4] };
    setDescription(`${selectedService.serviceName} Booked:[${calendar} - ${calendar2}]`)
    setServiceitem(selectedService.serviceName)
    setPrice(selectedService.serviceRate)
    setRoomId(selectedService.serviceItemId)
    
 
 
 //{`${service._id}-${serviceOptions._id}-${serviceOptions.title}-${serviceOptions.price}`}

  
  };

  const handleDates = () => {
   

    const today = new Date();
    let Difference_In_Days = ''
    var date1 = new Date(calendar);
    var date2 = new Date(calendar2);
   var Difference_In_Time = date2.getTime() - date1.getTime();
   if(  date2 && date1 && date2.getTime()>=date1.getTime() ){
        
          Difference_In_Days = (Difference_In_Time  / (1000 * 3600 * 24))+1;
          setQuantity(Difference_In_Days)
   }else{
    setQuantity(0)
    if(  date2 && date1 && date2.getTime()<date1.getTime()) toast.error("Invalid End Date")
   }

    


  }
  
  useEffect(() => {
    // set current date on component load
    setCalendar(format(new Date(), 'MM/dd/yyyy'))
    setCalendar2(format(new Date(), 'MM/dd/yyyy'))
    handleDates()
    setDescription("")
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if( e.key === "Escape" ) {
      setOpen(false)
      setOpen2(false)
    }
  }

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if( refOne.current && !refOne.current.contains(e.target) ) {
      setOpen(false)
    }
    if( refTwo.current && !refTwo.current.contains(e.target) ) {
      setOpen2(false)
    }
  }

  // on date change, store date in state
  const handleSelect2 = (date) => {
    // console.log(date)
    // console.log(format(date, 'MM/dd/yyyy'))
    setCalendar(format(date, 'MM/dd/yyyy'))
    setStartDate(calendar)
    handleDates()
    
  }
  // on date change, store date in state
  const handleSelect3 = (date) => {
    // console.log(date)
    // console.log(format(date, 'MM/dd/yyyy'))
    setCalendar2(format(date, 'MM/dd/yyyy'))
    setEndDate(calendar2)
    handleDates()

  }

  return (
    <Fragment>

      
<div className="row">

  <hr></hr>
  <br></br>
</div>


<div className="row">
 
<div className="col mt-2 mt-sm-0 calendarWrap">
          <label htmlFor="quantity"><strong>Start Date</strong></label>
            {/* <input
             className="form-control"
              type="text"
              name="start"
              id="start"
              placeholder="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            /> */}
                     <input
                      name="start"
                      id="start"
        value={ calendar }
        readOnly
        className="form-control"
        onClick={ () => setOpen(open => !open) }
      />
        <div ref={refOne}>
                  {open && 
                    <Calendar
                      date={ new Date() }
                      onChange = { handleSelect2 }
                      className="calendarElement"
                      minDate={new Date()}
                    />
                  }
            </div>



             {/* <DateRange
                  onChange={(item) => setDates([item.selection])}
                  minDate={new Date()}
                  ranges={dates}
                /> */}
          </div>
          <div className="col mt-2 mt-sm-0 calendarWrap">
          <label htmlFor="quantity"><strong>End Date</strong></label>
            {/* <input
             className="form-control"
              type="text"
              name="end"
              id="end"
              placeholder="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            /> */}


<input
                      name="end"
                      id="end"
        value={ calendar2 }
        readOnly
        className="form-control"
        onClick={ () => setOpen2(open2 => !open2) }
      />
        <div ref={refTwo}>
                  {open2 && 
                    <Calendar
                      date={ new Date() }
                      onChange = { handleSelect3 }
                      className="calendarElement"
                      // minDate={new Date()}
                    />
                  }
            </div>
           
          </div>


 

     



     <div className="col mt-2 mt-sm-0">
       <label htmlFor="description"><strong>Room</strong></label>
          {/* <input
           className="form-control"
            type="text"
            name="description"
            id="description"
            placeholder="Item description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          /> */}

              <select  onChange={handleSelect}  className="form-control"
                     
                      id="description"
                      name = "description"
                      >
                   <option value='' key='7687658669878898'>Select Room</option>
                  {loading
                    ? "loading"
                    : serviceOptions &&


                      serviceOptions?.map(   (service) => (
                        service?.roomNumbers?.map(   (room) => {

                         return (<option key={room._id} value={`${room._id}-${service?._id}-${service?.title} | ${room.number}-${service?.price}-${service?.desc}`}
                         disabled={!isAvailable(room)?'':'disabled'}
                         >
                          {`${service?.title} Rm No.- ${room.number}  `}
                          {!isAvailable(room)?'':'Booked'}
                        </option>)
                        })


                        
                      )
                      
                      )}
                     
                </select>
        </div>

      
          <div className="col mt-2 mt-sm-0">
          <label htmlFor="quantity"><strong>Quantity</strong></label>
            <input
             className="form-control"
             type="number"
             min={1}
              name="quantity"
              id="quantity"
              placeholder="Quantity"
              value={quantity}
              readOnly='true'
              // onChange={(e) => setQuantity(e.target.value)}
            />
          </div>
          

          <div className="col mt-2 mt-sm-0">
          <label htmlFor="price"><strong>Price</strong></label>
            <input
             className="form-control"
             type="number"
              name="price"
              id="price"
              placeholder="Price"
              value={price}
              readOnly='true'
           //  onChange={handleDates}
            />
          </div>

          <div className="col mt-2 mt-sm-0">
            <label htmlFor="amount">Amount</label>
            <p>{amount}</p>
          </div>
          <div className="col mt-2 mt-sm-0">
        <Button
          type="submit"
          className="btn btn-success" 
        >
          {isEditing ? "Editing Row Item" : "Add Item to List"}
        </Button>
        </div>
    <br></br>
    </div>
 
   

<hr></hr>




    </Fragment>
 


  )

}; //Service Transaction component ends


//table form starts
export default function TableForm({
  description,
  setDescription,
  quantity,
  setQuantity,
  price,
  setPrice,
  amount,
  setAmount,
  list,
  setList,
  setSubTotal,
  subTotal,
  setDiscount,
  discount,
  setVat,
  vat,
  setTotal,
  services,
  loading,
  setServiceDesc,
  serviceDesc,
  rooms,
  roomId,
  setRoomId,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedClients,
  dates, 
  setDates,
  open, 
  setOpen,
  open2, 
  setOpen2,
  calendar, 
  setCalendar,
  calendar2, 
  setCalendar2
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [serviceitem, setServiceitem] = useState(false)

  // Submit form function
  const handleSubmit = (e) => {
    e.preventDefault()

    if (!description || !quantity || !price) {
      toast.error("Please fill in all inputs")
    } else {
      const newItems = {
        id: uuidv4(),
        description,
        item:serviceitem,
        quantity,
        price,
        amount,
        roomId,
        calendar,
        calendar2,
        clientId:selectedClients.clientId,
        noteId:selectedClients.noteId,

      }
        
      setDescription("")
      setQuantity("")
      setPrice("")
      setRoomId("")
      setAmount(0)
      setCalendar("")
      setCalendar2("")
      setServiceitem("")
      setList([...list, newItems])
      setIsEditing(false)
    //console.log(list)
    }
  }

  // Calculate items amount function
  useEffect(() => {
    const calculateAmount = (amount) => {
    setAmount(quantity * price)
      
    }

    calculateAmount(amount)
  }, [amount, price, quantity, setAmount])

  // Calculate total amount of items in table
  useEffect(() => {
    let rows = document.querySelectorAll(".amount")
    let sum = 0
  if(!rows.length) {
    setSubTotal(sum)
    setDiscount(sum*0)
    setVat(sum*0.075)
    setTotal(sum+vat-discount)
  }
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].className === "amount") {
        sum += isNaN(rows[i].innerHTML) ? 0 : parseInt(rows[i].innerHTML)
        setSubTotal(sum)
        setDiscount(sum*0)
        setVat(sum*0.075)
        setTotal(sum+vat-discount)
      }
    }
  })

  // Edit function
  const editRow = (id) => {
    const editingRow = list.find((row) => row.id === id)
    setList(list.filter((row) => row.id !== id))
    setIsEditing(true)  
  
    if(editingRow.calendar){
   setCalendar(editingRow.calendar)
    setCalendar2(editingRow.calendar2)
          const selectedDatarre = (editingRow.description).split("Booked:");
    setDescription(`${selectedDatarre[0]} Booked:[${calendar} - ${calendar2}]`)
    }else setDescription(editingRow.description)

  //  
   // setDescription(editingRow.description)
    setQuantity(editingRow.quantity)
    setPrice(editingRow.price)
 
    // setStartDate(calendar)
    // setEndDate(calendar2)

  }

  // Delete function
  const deleteRow = (id) => {

     

      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this record!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        }).then((willDelete) => {
        if (willDelete) {
          swal("Record has been deleted!", {
          icon: "success",
          });
          setList(list.filter((row) => row.id !== id))
        } else {
          swal("Record is not deleted!");
        }
        })
  
  }
  


  return (
    <>
      <ToastContainer position="top-right" theme="colored" />

      <form onSubmit={handleSubmit}>
           
           {serviceDesc==='Front Desk'?(<ReserveComponent                 
            
            description={description}
                  setDescription={setDescription}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  price={price}
                  setPrice={setPrice}
                  amount={amount}
                  setAmount={setAmount}
                  list={list}
                  setList={setList}
                  isEditing={isEditing}
                 
                  setTotal={setTotal}
                  subTotal={subTotal}
                  setSubTotal={setSubTotal}
                  discount={discount}
                  setDiscount={setDiscount}
                  vat={vat}
                  setVat={setVat}
                  services={services}
                  loading={loading}
                  setServiceDesc={setServiceDesc}
                  setServiceitem={setServiceitem}
                  rooms={rooms}
                  setRoomId={setRoomId}
                  roomId={roomId}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  dates={dates}
                  setDates={setDates}
                  calendar={calendar}
                  setCalendar={setCalendar}
                  calendar2={calendar2}
                  setCalendar2={setCalendar2}
                  open={open}
                  setOpen={setOpen}
                  open2={open2}
                  setOpen2={setOpen2}


                  />):(<TxnComponent                 
            
                    description={description}
                          setDescription={setDescription}
                          quantity={quantity}
                          setQuantity={setQuantity}
                          price={price}
                          setPrice={setPrice}
                          amount={amount}
                          setAmount={setAmount}
                          list={list}
                          setList={setList}
                          isEditing={isEditing}
                          setTotal={setTotal}
                          subTotal={subTotal}
                          setSubTotal={setSubTotal}
                          discount={discount}
                          setDiscount={setDiscount}
                          vat={vat}
                          setVat={setVat}
                          services={services}
                          loading={loading}
                          setServiceDesc={setServiceDesc}
                          setServiceitem={setServiceitem}
                    
                         
        
        
        
                          />)} 
            
      </form>
<br />
<br />
<br />
      {/* Table items */}
      {/* Table items */}
      {/* Table items */}

      {/* <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="center">#</th>
                      <th>Item</th>
                      <th>Description</th>
                      <th className="right">Unit Cost</th>
                      <th className="center">Qty</th>
                      <th className="right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="center">1</td>
                      <td className="left strong">Origin License</td>
                      <td className="left">Extended License</td>
                      <td className="right">$999,00</td>
                      <td className="center">1</td>
                      <td className="right">$999,00</td>
                    </tr>
                    <tr>
                      <td className="center">2</td>
                      <td className="left">Custom Services</td>
                      <td className="left">
                        Instalation and Customization (cost per hour)
                      </td>
                      <td className="right">$150,00</td>
                      <td className="center">20</td>
                      <td className="right">$3.000,00</td>
                    </tr>
                    <tr>
                      <td className="center">3</td>
                      <td className="left">Hosting</td>
                      <td className="left">1 year subcription</td>
                      <td className="right">$499,00</td>
                      <td className="center">1</td>
                      <td className="right">$499,00</td>
                    </tr>
                    <tr>
                      <td className="center">4</td>
                      <td className="left">Platinum Support</td>
                      <td className="left">1 year subcription 24/7</td>
                      <td className="right">$3.999,00</td>
                      <td className="center">1</td>
                      <td className="right">$3.999,00</td>
                    </tr>
                  </tbody>
                </table>
       </div> */}
 
              <div className="table-responsive">
 {/* <table width="100%" className="mb-10"> */}
 <table className="table table-striped">
 <thead>
                    <tr>
                      <th className="center">#</th>
                      {/* <th>Item</th> */}
                      <th>Description</th>
                      <th className="right">Cost / Night</th>
                      <th className="center">Qty</th>
                      <th className="right">Total</th>
                      <th colspan="2" className="right">Action</th>

                    </tr>
                  </thead>
                 <tbody>
        {list.map(({ id, description, quantity, price, amount,item },index) => {

          return (
          <React.Fragment key={id}>
            
              <tr className="h-10">
              <td className="center">{index+1}</td>

                {/* <td className="left">{item}</td> */}
                <td className="left">{description}</td>
                <td className="right">{price}</td>
                <td className="center">{quantity}</td>
                <td className="amount">{amount}</td>
                
                <td colspan='2'>
																	<div className="d-flex">
																	
																		<Link  className="btn btn-secondary	 shadow btn-xs sharp me-2"
																			onClick={() => editRow(id)}
																		>
																			<i className="fas fa-pen"></i>
																		</Link>
																		<Link  className="btn btn-danger shadow btn-xs sharp" 
																			onClick={() => deleteRow(id)}
																		> 
																			<i className="fa fa-trash"></i>
																		</Link>
																			
																	</div>												
																</td>			
              </tr>

              

           
          </React.Fragment>
        )})} 
        
        </tbody>
  </table>

              </div>
     

      {/* <div>
        <h2 className="flex items-end justify-end text-gray-800 text-4xl font-bold">
          N. {total.toLocaleString()}
        </h2>
      </div> */}

    </>
  )
}
//Table Form ends
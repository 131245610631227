 import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          {/* Copyright © IEDDV &amp; Coded by{" "}
          <a href="http://lamydas.com/" target="_blank" rel="noreferrer">
           Lamydas
          </a>{" "}
          {d.getFullYear()} */}
          <p >&copy; {`${new Date().getFullYear()} MRight DigitalTech Limited. Analysis and Design by A. M. Ali and coded by `}<a href='https://www.lamydas.com'>lamydas Solutions</a> </p>
        </p>
      </div>
    </div>
  );
};

export default Footer;

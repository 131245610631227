import React, { useMatch,Fragment,useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {ListGroup,Col,Dropdown, Tab, Nav,Card,Row} from 'react-bootstrap';
import { connect } from "react-redux";
import logo from "../../../images/logo.png";
import room4 from './../../../images/room/room4.jpg';
import room5 from './../../../images/room/room5.jpg';
import room6 from './../../../images/room/room6.jpg';
import room7 from './../../../images/room/room7.jpg';
//import GuestCarousel from './Room/GuestCarousel';
import Spinner from '../../layouts/Spinner';
//import App from '../../invoicer/App';
import { createInvoiceAction} from "../../../store/actions/InvoiceActions";
import { getOpenNoteClientsAction} from "../../../store/actions/NoteActions";
import { getServicesAction} from "../../../store/actions/ServiceActions";
import { getRoomsAction} from "../../../store/actions/RoomActions";
import Available from './Room/Available';
import Booked from './Room/Booked';
import PropTypes from "prop-types";
import { compareAsc,format } from 'date-fns'
import swal from "sweetalert";

//invoice things

import ClientDetails from "./invoicer/ClientDetails";
import Dates from "./invoicer/Dates";
import Footer from "./invoicer/Footer";
import Header from "./invoicer/Header";
import MainDetails from "./invoicer/MainDetails";
import Notes from "./invoicer/Notes";
import Table from "./invoicer/Table";
import TableForm from "./invoicer/TableForm";
import ReactToPrint from "react-to-print";



const DropdownBlog = ({setAddCard}) =>{
	return(
		<>
			<Dropdown className="dropdown">
				<Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu">
					<Dropdown.Item className="dropdown-item" as="button" >
                                <div onClick={()=> setAddCard(true)}>
                                    Sync CA
                                </div></Dropdown.Item>
					<Dropdown.Item className="dropdown-item">Delete</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}
const COMA_SEPARATED_REGEX = /^[0-9a-zA-Z]+(,[0-9a-zA-Z]+)*$/

const initialState = {
	username: '', 
	password: '', 
	sname: '', 
	fname: '', 
	email: '', 
	phone: '', 
	active: '',
	 dept: [], 
	 designation: '', 
	 permission: [],
	 roles:[],
	 active:false
  };

const initialStateNote = {
  ticket: '',
  client: {
      _id: '',
      cust_phone: '',
      cust_email: '',
      cust_fname: '',
      cust_sname: ''
  }
  };




const CreateInvoice = ({ createInvoiceAction,getOpenNoteClientsAction,getServicesAction,getRoomsAction,
  invoice: { lists:invoices2, showLoading,successMessage,errorMessage },
  note_clients:{notes:client2,showLoading:loading},service:{showLoading:loadingService, lists:services},
  room:{showLoading:loadingRoom, lists:rooms} }) =>{
	
	
	// invoice things
	
	const [name, setName] = useState("");
	const [serviceDesc, setServiceDesc] = useState("");
	const [address, setAddress] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [bankName, setBankName] = useState("");
	const [bankAccount, setBankAccount] = useState("");
	const [website, setWebsite] = useState("");
	const [clientName, setClientName] = useState("");
	const [clientAddress, setClientAddress] = useState("");
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [invoiceDate, setInvoiceDate] = useState("");
	const [dueDate, setDueDate] = useState("");
	const [notes, setNotes] = useState("");
	const [description, setDescription] = useState("");
	const [quantity, setQuantity] = useState("");
	const [price, setPrice] = useState("");
	const [amount, setAmount] = useState("");
	const [list, setList] = useState([]);
	const [total, setTotal] = useState(0);
	const [subTotal, setSubTotal] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [vat, setVat] = useState(0);
	const [width] = useState(641);
	const [invoices, setInvoices] = useState([]);
	const [showInvoice, setShowInvoice] = useState(false);

	const componentRef = useRef();
  const [noteClients, setNoteClients] = useState([]);
  const [dates, setDates] = useState([]);

 // date state
 const [calendar, setCalendar] = useState('')
 const [calendar2, setCalendar2] = useState('')

 // open close
 const [open, setOpen] = useState(false)
 const [open2, setOpen2] = useState(false)


  
  const [roomId, setRoomId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

 

  const [selectedClients, setSelectedClients] = useState({ noteId: '',clientId:'',clientFname:'',clientSname:'' });

	const handlePrint = () => {
	  window.print();
	};
  
	
	const [selectBtn, setSelectBtn] = useState("Newest");
	//const creatingInvoice = useMatch('/creatingInvoice');
	const creatingInvoice = true
	 const [id, setId] = useState();
	const [data, setData] = useState(
		document.querySelectorAll("#room_wrapper tbody tr")
	);
	const sort = 10;
	const activePag = useRef(0);
	const [test, settest] = useState(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
	const [addCard, setAddCard] = useState(false);
   // use effect
   useEffect(() => {
      setData(document.querySelectorAll("#room_wrapper tbody tr"));
      //chackboxFun();
	}, [test]);

	// useEffect(() => {


	// 	const InvoiceData = { ...initialState };
	// 	const NoteData = { ...initialStateNote };

	// 	if (!showLoading && lists && !creatingInvoice) {
			
	// 		const invoice = lists.find((element) => {
	// 		return element._id === id;
	// 	})
		

	// 	for (const key in invoice) {
	// 		//console.log(key);
	// 		if (key in InvoiceData) InvoiceData[key] = invoice[key];
	// 	  }
	


	// 	}
	//   }, [showLoading,creatingInvoice, lists, id]);



    useEffect(() => {
			getOpenNoteClientsAction()
      getServicesAction()
      getRoomsAction()
	
	  }, [getOpenNoteClientsAction,getServicesAction,getRoomsAction]);

	useEffect(() => {
		if (!loading  ) {
	      setNoteClients(client2);
		}
	  }, [loading, client2]);

// Add Room function
const handleAddFormChange = (event) => {
	event.preventDefault();    
	const fieldName = event.target.getAttribute('name');
	const fieldValue = event.target.value;
	const newFormData = {...addFormData};
	newFormData[fieldName] = fieldValue;
	setAddFormData(newFormData);
};

const handleSelect = (e) => {
  const selectedarr = (e.target.value).split("-");
  setSelectedClients({ noteId: selectedarr[1],clientId:selectedarr[0],clientFname:selectedarr[2],clientSname:selectedarr[3],clientPhone:selectedarr[4],clientEmail:selectedarr[5] });
 };

const [addFormData, setAddFormData ] = useState({
	title:'',
	desc:'',
	maxPeople:'',
	price:'',
	room:'',
	
}); 


  //Add Submit data
    const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        var error = false;
		var errorMsg = '';
        if(addFormData.title === ""){
            error = true;
			errorMsg = 'Please fill  Room title';
        }else if(addFormData.price === ""){
            error = true;
			errorMsg = 'Please fill Room Rate/Price';
        }else if(addFormData.desc === ""){
			error = true;
			errorMsg = "please fill Room Desc";
		
        
        }else if(addFormData.maxPeople === ""){
			error = true;
			errorMsg = "please fill Room MaxPeople";
		
        }else if(addFormData.room === "" ){
			error = true;
			errorMsg = "please fill Room number";
		
        }else if(!COMA_SEPARATED_REGEX.test(addFormData.room)){
			error = true;
			errorMsg = "please fill in valid COMA separated Room number e.g 201, 201A";
		}
        if(!error){

			const roomNumberarr = addFormData.room.split(",").map((room) => ({ number: room }));
           
			const newContent = {
                // id: nanoid(),
		
                title: addFormData.title,
                price:  addFormData.price,
                desc:  addFormData.desc,
                maxPeople:  addFormData.maxPeople,

				roomNumbers:[...roomNumberarr]
             
			};
			
         // console.log(newContent)  
         
		 createInvoiceAction(newContent);
			//const newList = [...list, list];
            //setList(newList);
		//	addFormData.title  = addFormData.price = addFormData.maxPeople = addFormData.room = addFormData.desc='';      
            //setAddCard(false);
            swal('Good job!', 'Room Added', "success");
              
            return false
        }else{
			swal('Oops', errorMsg, "error");
		}
    };

   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};

	const isAvailable = (unavailableDates) => {
	
		let today = new Date().toLocaleDateString();
		
		const dates = unavailableDates.map( (date1)=> new Date(date1).getTime())
	//	console.log(dates);
	//	console.log(new Date(today).getTime());

		const isFound = dates.includes(new Date(today).getTime())
	
	
		return isFound;
	  };
   
	const chackbox = document.querySelectorAll(".sorting_7 input");
	const motherChackBox = document.querySelector(".sorting_asc_7 input");
   // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);
	const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
         const element = chackbox[i];
         if (type === "all") {
            if (motherChackBox.checked) {
               element.checked = true;
            } else {
               element.checked = false;
            }
			} else {
				if (!element.checked) {
				   motherChackBox.checked = false;
				   break;
				} else {
				   motherChackBox.checked = true;
				}
			}
		}
    };
	return(
		<>
    		<div className="card-header">
						<h4 className="card-title">{('M-Right SERVICE INVOICER').toUpperCase()}</h4>
					</div>
			<Tab.Container defaultActiveKey="All">
				<div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
					{/* <div className="card-action coin-tabs mb-2">
						<Nav as="ul" className="nav nav-tabs" role="tablist">
							<Nav.Item as="li" className="nav-item">
								<Nav.Link className="nav-link" eventKey="All">All Rooms</Nav.Link>
							</Nav.Item>
							<Nav.Item as="li" className="nav-item">
								<Nav.Link className="nav-link" eventKey="Available">Available Room</Nav.Link>
							</Nav.Item>
							<Nav.Item as="li" className="nav-item">
								<Nav.Link className="nav-link" eventKey="Booked">Booked</Nav.Link>
							</Nav.Item>
						</Nav>
					</div> */}
					<div className="d-flex align-items-center mb-2"> 
						{/* <Link onClick={()=> {setAddCard(true);   }} className="btn btn-secondary">+ Add New Room</Link> */}
						<div className="newest ms-3">
							<Dropdown>
								<Dropdown.Toggle as="div" className=" btn-select-drop default-select btn i-false">
									{selectBtn} <i className="fas fa-angle-down ms-2 "></i>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={()=>setSelectBtn("Oldest")} eventKey="All">Oldest</Dropdown.Item>
									<Dropdown.Item onClick={()=>setSelectBtn("Newest")} eventKey="All">Newest</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown> 
						</div>	
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-xl-12">
					
						<div className="card">
							<div className="card-body p-0">
								<Tab.Content>	
									<Tab.Pane eventKey="All">
                                       


									
          <div className="">

            {/* name, address, email, phone, bank name, bank account number, website client name, client address, invoice number, invoice date, due date, notes */}
            
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header">
                            {" "}
                            Invoice No.:    <strong> Invoice Date:
                            {/* <input
                                    type="date"
                                    name="invoiceDate"
                                    id="invoiceDate"
                                    placeholder="Invoice Date"
                                    autoComplete="off"
                                    value={invoiceDate}
                                    onChange={(e) => setInvoiceDate(e.target.value)}
                                  /> */}
                                  </strong> {" "}
                            <span className="float-right">
                              <strong>Due date:</strong> Pending
                            </span>{" "}
                            <span className="float-right">
                              <strong>Status:</strong> Pending
                            </span>{" "}
                       </div>
                        <div className="card-body">
                           <div className="row mb-5">
                              <div className="mt-4 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                                    <h6>From:</h6>
                                <div>
                              {" "}
                              <strong>IEDDV Limited</strong>{" "}
                          </div>
                          <div>Oko Afon Extension</div>
                          <div>71-101 Badagry, Lagos</div>
                          <div>Email: info@bedrockhotel.com.ng</div>
                          <div>Phone: +234 802 378 111</div>
                       </div>
                        <div className="mt-4 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                          <h6>To:</h6>
                          <div className="form-group">
                            <select  onChange={handleSelect}  
                                className="form-control"
                                id="clientName"
                                name = "clientName"
                                >
                            <option value='' key='7687654678898'>Select Client</option>
                            {loading
                              ? "loading"
                              : noteClients &&
                                noteClients?.map((note) => (
                                  <option key={note.client._id} value={`${note.client._id}-${note._id}-${note.client.cust_sname}-${note.client.cust_fname}-${note.client.cust_phone}-${note.client.cust_email}`}>
                                    {`${note.client.cust_sname} ${note.client.cust_fname} - ${note.ticket} `}
                                  </option>
                                ))}
                          </select>
                          {" "}{(selectedClients.clientFname) && (
                       <div> <br />
           
                     <u><b>Name:</b> {`${selectedClients.clientFname} ${selectedClients.clientSname} ` }</u> <br />
                     <u><b>Phone: </b>{`${selectedClients.clientPhone} ` }</u><br />
                     <u><b>Email:</b> {`${selectedClients.clientEmail} ` }</u>
 

                      
                   
                      
                      
                      </div>)}
                    {/* <input
                     className="form-control form-control-sm"
                    type="text"
                    name="clientName"
                    id="clientName"
                    placeholder="Enter your client's name"
                    autoComplete="off"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                  />{" "} */}
                  </div>
                  
                  {" "} <div>{clientAddress}</div>
                  {/* <div className="form-group"> <input
                   className="form-control form-control-sm"
                    type="text"
                    name="clientAddress"
                    id="clientAddress"
                    placeholder="Enter your client's address"
                    autoComplete="off"
                    value={clientAddress}
                    onChange={(e) => setClientAddress(e.target.value)}
                  /></div> */}
                  {/* <div>Email: marek@daniel.com</div>
                  <div>Phone: +48 123 456 789</div> */}
                </div>
                <div className="">
                  <div className="row align-items-center">
                    <div className="col-sm-9">
                      <div className="brand-logo mb-3">
							<div className="d-flex align-items-center">
								<img className="logo-abbr me-3" src={logo} alt="" style={{width:'50px'}}  />
								<h3 className="mb-1 font-w600">M-RIGHT Service</h3>
							</div>
                        {/* <img className="logo-compact" src={logoText} alt="" style={{width:'110px'}} /> */}
                      </div>
                      <span>
                        Please send exact amount:{" "}
                        <strong className="d-block">bankAccount: BEDROCK</strong>
                        <strong>GUARANTY TRUST BANK</strong>
                      </span>
                      <br />
                      <small className="text-muted">
                       0001234567
                      </small>
                    </div>
                    <div className="col-sm-3 mt-3">
                      {" "}
                      {/* <img
                        src={qrcode}
                        className="img-fluid width110"
                        alt=""
                      />{" "} */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="center">#</th>
                      <th>Item</th>
                      <th>Description</th>
                      <th className="right">Unit Cost</th>
                      <th className="center">Qty</th>
                      <th className="right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="center">1</td>
                      <td className="left strong">Origin License</td>
                      <td className="left">Extended License</td>
                      <td className="right">$999,00</td>
                      <td className="center">1</td>
                      <td className="right">$999,00</td>
                    </tr>
                    <tr>
                      <td className="center">2</td>
                      <td className="left">Custom Services</td>
                      <td className="left">
                        Instalation and Customization (cost per hour)
                      </td>
                      <td className="right">$150,00</td>
                      <td className="center">20</td>
                      <td className="right">$3.000,00</td>
                    </tr>
                    <tr>
                      <td className="center">3</td>
                      <td className="left">Hosting</td>
                      <td className="left">1 year subcription</td>
                      <td className="right">$499,00</td>
                      <td className="center">1</td>
                      <td className="right">$499,00</td>
                    </tr>
                    <tr>
                      <td className="center">4</td>
                      <td className="left">Platinum Support</td>
                      <td className="left">1 year subcription 24/7</td>
                      <td className="right">$3.999,00</td>
                      <td className="center">1</td>
                      <td className="right">$3.999,00</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              <TableForm
                  description={description}
                  setDescription={setDescription}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  price={price}
                  setPrice={setPrice}
                  amount={amount}
                  setAmount={setAmount}
                  list={list}
                  setList={setList}
                  total={total}
                  setTotal={setTotal}
                  subTotal={subTotal}
                  setSubTotal={setSubTotal}
                  discount={discount}
                  setDiscount={setDiscount}
                  vat={vat}
                  setVat={setVat}
                  loading={loadingService}
                  services={services}
                  setServiceDesc={setServiceDesc}
                  serviceDesc={serviceDesc}
                  rooms={rooms}
                  setRoomId={setRoomId}
                  roomId={roomId}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  endDate={endDate}
                  selectedClients={selectedClients}
                  dates={dates}
                  setDates={setDates}
                  calendar={calendar}
                  setCalendar={setCalendar}
                  calendar2={calendar2}
                  setCalendar2={setCalendar2}
                  open={open}
                  setOpen={setOpen}
                  open2={open2}
                  setOpen2={setOpen2}

                  
                />
              <div className="row">
                    <div className="col-lg-4 col-sm-4 ms-auto">Additional Notes<br />
                      <textarea
                        name="notes"
                        id="notes"
                        cols="30"
                        rows="10"
                        placeholder="Additional notes to the client"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      ></textarea>
                  </div>
                <div className="col-lg-6 col-sm-4 ms-auto">
                  <table className="left table table-clear">
                    <tbody>
                      <tr>
                        <td className="left">
                          <strong>Subtotal</strong>
                        </td>
                        <td className="right">&#8358;{subTotal.toLocaleString()}</td>
                        <td colspan='2'>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="left">
                          <strong>Discount (0%)</strong>
                        </td>
                        <td className="right">&#8358;{(discount*0).toLocaleString()}</td>
                        <td colspan='2'>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="left">
                          <strong>VAT (7.5%)</strong>
                        </td>
                        <td className="right">&#8358;{vat.toLocaleString()}</td>
                        <td colspan='2'>&nbsp;</td>
                      </tr>
                      <tr>
                        <td className="left">
                          <strong>Total</strong>
                        </td>
                        <td className="right">
                          <strong>&#8358;{total.toLocaleString()}</strong>
                          {/* <br />
                          <strong>0.15050000 BTC</strong> */}
                        </td>
                        <td colspan='2'>&nbsp;</td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>

              </div>
            <div className="row"> 
         

              <button
             // onClick={() => setShowInvoice(true)}
             type="button"
              className="me-2 mt-5" variant="secondary"
            >
              Save Invoice
            </button>
           
               </div>
            </div>
          </div>
        </div>
     
                 </div>
            
            
            
            
                <div className="">
              
                    {/* <div className="flex flex-col">
                      <label htmlFor="name">Your full name</label>
                      <input
                        type="text"
                        name="text"
                        id="name"
                        placeholder="Enter your name"
                        autoComplete="off"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div> */}

                    {/* <div className="flex flex-col">
                      <label htmlFor="address">Enter your address</label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Enter your address"
                        autoComplete="off"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                */}

              
                    {/* <div className="flex flex-col">
                      <label htmlFor="email">Enter your email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div> */}

                    {/* <div className="flex flex-col">
                      <label htmlFor="website">Enter your website</label>
                      <input
                        type="url"
                        name="website"
                        id="website"
                        placeholder="Enter your website"
                        autoComplete="off"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </div> */}

                    {/* <div className="flex flex-col">
                      <label htmlFor="phone">Enter your phone</label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Enter your phone"
                        autoComplete="off"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                */}

                
                    {/* <div className="flex flex-col">
                      <label htmlFor="bankName">Enter your bank name</label>
                      <input
                        type="text"
                        name="bankName"
                        id="bankName"
                        placeholder="Enter your bank name"
                        autoComplete="off"
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                      />
                    </div> */}
    {/* 
                    <div className="flex flex-col">
                      <label htmlFor="bankAccount">
                        Enter your bank account number
                      </label>
                      <input
                        type="text"
                        name="bankAccount"
                        id="bankAccount"
                        placeholder="Enter your bank account number"
                        autoComplete="off"
                        value={bankAccount}
                        onChange={(e) => setBankAccount(e.target.value)}
                      />
                    </div>
              */}

                
                    {/* <div className="flex flex-col">
                      <label htmlFor="clientName">Enter your client's name</label>
                      <input
                        type="text"
                        name="clientName"
                        id="clientName"
                        placeholder="Enter your client's name"
                        autoComplete="off"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                      />
                    </div> */}

                    {/* <div className="flex flex-col">
                      <label htmlFor="clientAddress">
                        Enter your client's address
                      </label>
                      <input
                        type="text"
                        name="clientAddress"
                        id="clientAddress"
                        placeholder="Enter your client's address"
                        autoComplete="off"
                        value={clientAddress}
                        onChange={(e) => setClientAddress(e.target.value)}
                      />
                    </div>
                  */}

                
                    {/* <div className="flex flex-col">
                      <label htmlFor="invoiceNumber">Invoice Number</label>
                      <input
                        type="text"
                        name="invoiceNumber"
                        id="invoiceNumber"
                        placeholder="Invoice Number"
                        autoComplete="off"
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumber(e.target.value)}
                      />
                    </div> */}

                    {/* <div className="flex flex-col">
                      <label htmlFor="invoiceDate">Invoice Date</label>
                      <input
                        type="date"
                        name="invoiceDate"
                        id="invoiceDate"
                        placeholder="Invoice Date"
                        autoComplete="off"
                        value={invoiceDate}
                        onChange={(e) => setInvoiceDate(e.target.value)}
                      />
                    </div> */}

                    {/* <div className="flex flex-col">
                      <label htmlFor="dueDate">Due Date</label>
                      <input
                        type="date"
                        name="dueDate"
                        id="dueDate"
                        placeholder="Invoice Date"
                        autoComplete="off"
                        value={dueDate}
                        onChange={(e) => setDueDate(e.target.value)}
                      />
                    </div>
                  */}

                  {/* This is our table form */}
                

          

                  {/* <button
                  onClick={() => setShowInvoice(true)}
                  className="bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300"
                >
                  Preview Invoice
                </button> */}
                </div>
         
          </div>

  
									</Tab.Pane>
									{/* <Tab.Pane eventKey="Available">
										<Available lists={lists}  showLoading={showLoading} successMessage={successMessage} errorMessage= {errorMessage} />	
									</Tab.Pane>
									<Tab.Pane eventKey="Booked">
										<Booked lists={lists}  showLoading={showLoading} successMessage={successMessage} errorMessage= {errorMessage} />	
									</Tab.Pane> */}
								</Tab.Content>
							</div>	
						</div>	
					</div>	
				</div>		
			</Tab.Container>				
		</>
	)
}
//export default Rooms;
CreateInvoice.propTypes = {
  service: PropTypes.object.isRequired,
	invoice: PropTypes.object.isRequired,
	createInvoiceAction:PropTypes.func.isRequired,
	getOpenNoteClientsAction:PropTypes.func.isRequired,
	getServicesAction:PropTypes.func.isRequired,
	getRoomsAction:PropTypes.func.isRequired,


  };
  
  const mapStateToProps = (state) => ({
	invoice: state.InvoiceReducer,
	service: state.ServiceReducer,
  note_clients: state.NoteReducer,
  room: state.RoomReducer,


  });
  
  export default connect(mapStateToProps, { createInvoiceAction,getServicesAction,getOpenNoteClientsAction,getRoomsAction })(CreateInvoice);
import {
    CONFIRMED_CREATE_USER_ACTION,
    CONFIRMED_DELETE_USER_ACTION,
    CONFIRMED_EDIT_USER_ACTION,
    CONFIRMED_GET_USERS,
    CLEAR_USER,
    CONFIRMED_GET_USER,
    USER_ERROR,
    CREATE_STATUS
    // CREATE_USER_ACTION,
} from '../actions/UserTypes';

const initialState = {
    users: [],
    user: null,
    activeUsers: [],
    inActiveUsers: [],
    errorMessage: '',
    successMessage: '',
    showLoading: true,
};

export default function UsersReducer(state = initialState, actions) {
    // if (actions.type === CREATE_USER_ACTION) {
    //     const post = {
    //        // id: Math.random(),
    //         title: 'Post Title 2asdasd',
    //         description: 'Sample Description 2asdasdas',
    //     };

    //     const posts = [...state.posts];
    //     posts.push(post);
    //     return {
    //         ...state,
    //         posts,  
    //         errorMessage: '',
    //         successMessage: 'Post Successfully Completed',
    //         showLoading: false,
    //     };
    // }

    if (actions.type === CONFIRMED_DELETE_USER_ACTION) {
        const users = [...state.users];
        const userIndex = users.findIndex(
            (user) => user.id === actions.payload,
        );

        users.splice(userIndex, 1);

        return {
            ...state,
            users
        };
    }

    if (actions.type === CONFIRMED_EDIT_USER_ACTION) {
        const users = [...state.users];
        const userIndex = users.findIndex(
            (user) => user.id === actions.payload.id,
        );

        users[userIndex] = actions.payload;
        return {
            ...state,
            users,
        };
    }

    if (actions.type === CONFIRMED_CREATE_USER_ACTION) {
        const users = [...state.users];
        users.push(actions.payload);

        return {
            ...state,
            users,
            showLoading: false, 
        };
    }

    if (actions.type === CREATE_STATUS) {


        return {
            ...state,
            successMessage: actions.payload,
        };
    }

    if (actions.type === CONFIRMED_GET_USERS) {
        return {
            ...state,
            users: actions.payload,
            activeUsers: (actions.payload).filter((user) => user.active),
            inActiveUsers: (actions.payload).filter((user) => !user.active),
            showLoading: false,
        };
    }
    if (actions.type === CONFIRMED_GET_USER) {
        return {
            ...state,
            user: actions.payload,
            showLoading: false,
        };
    }

    if (actions.type === USER_ERROR) {
        return {
            ...state,
            errorMessage: actions.payload,
        };
    }
    if (actions.type === CLEAR_USER) {
        return {
            ...state,
            user: null
          };
    }

       
    return state;
}

import React,{useState,useEffect,Fragment} from 'react';
import {Link,useNavigate}  from "react-router-dom";
import api from "../../utils/api";
import {  Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import PageTitle from "../layouts/PageTitle";
import pic1 from './../../images/profile/small/pic1.jpg';
import {

  UPDATE_PROFILE,

} from "../../store/types";
import { useDispatch } from 'react-redux'
  import Spinner from '../layouts/Spinner';
  //import useAuth from '../../config/hooks/useAuth';



  const initialState ={
	
	prac_exp: '',
	comp_cert: '',
	birthday:null,
	profileStatus:'M-RIGHT GSM UNION EXCO'


}
const Registrar = () =>{
	
  const dispatch = useDispatch()
	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialState);
  const [imgHolder, setImgHolder] = useState();


	const {	prac_exp, comp_cert,birthday,profileStatus} = formData;
	let errorsObj = {
		prac_exp:'',
		comp_cert:false,
		birthday:'',
		profileStatus:''
	   };

     const imgChnage = (e) => {
      setImgHolder(e.target.files[0]);
      //console.log(imgHolder)
    };

    // handleUpload = () => {
    //   //event.preventDefault();
    //   const data = new FormData();
    //   data.append('birthday', formData.birthday, formData.birthday.name);
    //   data.append('prac_exp', formData.prac_exp);
    //   data.append('comp_cert', formData.comp_cert);
    //   profileChangeStatus
    //   // axios.post(endpoint, data).then((res) => {
    //   //   console.log(res.statusText);
    //   // });
    // };
    const handleUpload = () => {

      swal({
      title: "Are you sure?",
      text:
        "Your application will be submmited to Admin for review",
      icon: "warning",
      buttons: true,
      dangerMode: true,
        }).then((willDelete) => {

      if (willDelete) {
  
          const data2 = new FormData();
        
        //  data2.append('file', imgHolder);
      //   data2.append('fileName', imgHolder.name);
         data2.append('profileStatus', 'M-RIGHT GSM UNION EXCO');

       //  data2.append('prac_exp', formData.prac_exp);
         // data2.append('comp_cert',false);
          
      //your application has been submitted successfully. If considered, you will be notified via email and you will see approval status in your account dashboard.\n Thank you for the application
          api.post(`/profile/smrr-apply`,data2).then((res) => {
            console.log(res.data)
      
          swal("your application has been submitted successfully. If considered, you will be notified via email and you will see approval status in your account dashboard.\n Thank you for the application", {
        icon: "success",
        });
        dispatch({
          type: UPDATE_PROFILE,
          payload: res.data
        });
        
        navigate("/dashboard")
      
      
    });
    } else {
      swal("Your Application as a GSM union exco is not submited");
    }
    })

  }

	// useEffect(() => {
	// 	// if there is no profile, attempt to fetch one
	// 	!profile && getCurrentProfile();
	// 	if(profile && !loading ) {
	// 		setFormData({ ...formData, prof_id: profile._id ,profileStatus:'M-RIGHT REGISTRAR'});

	// 	}
	//   }, [loading, profile, getCurrentProfile]);

	  const [errors, setErrors] = useState(errorsObj);

    const onChange = (e) =>
	  setFormData({ ...formData, [e.target.name]: e.target.value });


	  const onPracExpChanged = () => 
	{
	  
	  setFormData({ ...formData, prac_exp:(formData.prac_exp==='GSM UNION EEXCO')?'GSM UNION EEXCO':''});
	}
	  const onComCertChanged = () => 
	{
	  
	  setFormData({ ...formData, comp_cert:formData.comp_cert?false:true});
	}
	  
   
    
     //Add Submit data
    const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
       
		//  if (profileStatus === '') {
    //         errorObj.profileStatus = 'Profile Status is Required';
    //         error = true;
		// 	swal('Oops', errorObj.profileStatus, "error");
    //     }
        
		 if (prac_exp === '') {
            errorObj.prac_exp = 'Application Type is Required';
            error = true;
			swal('Oops', errorObj.prac_exp, "error");
        }

		//  if (comp_cert === '') {
    //         errorObj.comp_cert = 'Basic computer certificate is Required';
    //         error = true;
		// 	swal('Oops', errorObj.comp_cert, "error");
    //     }

		//  if (birthday === '') {
    //         errorObj.birthday = 'Birthday certificate is Required';
    //         error = true;
		// 	swal('Oops', errorObj.birthday, "error");
    //     }
		

        setErrors(errorObj);
        if (error) return;
        setFormData({ ...formData});
        console.log(formData)
       handleUpload()
    };
	


	return(
		<>
			{/* <PageTitle activeMenu="" motherMenu="Services" /> */}
			<section>
         <div className="row">
        
            <div className="col-lg-12 mb-4">
			<div className="card">
					<div className="card-header">
						<h2 className="card-title">APPLICATION FOR GSM UNION EXECUTIVE</h2>
					</div>
					<div className="card-body">
            <form>
               <div className="form-group mb-3">
			   <label
                      className="form-check-label text-black font-w500 px-3"
                      htmlFor="active"
                    >
                     <h3>Application Type</h3>
                    </label>
					
                    <select name="prac_exp"  value={formData.prac_exp}  onChange={onChange} className="form-control">
    <option value=''>Select Application Type</option>
  
    <option value='GSM UNION EEXCO'>GSM UNION EEXCO</option>

  
    
    </select>
                   
               </div>
               {/* <div className="form-group mb-3">
			   <label
                      className="form-check-label text-black font-w500 px-3"
                      htmlFor="active"
                    >
                     <h3>Do you have basic computer certificate?</h3>
                    </label>
					
					 <input
                      type="checkbox"
                      
                      className="form-check-input p-3"
                      id="customCheckBox9"
                      required

                      name={formData.comp_cert}
                      value={formData.comp_cert}
                      checked ={ formData.comp_cert && 'checked' }
                      // onChange={(e) =>
                      //   setActive(e.target.value)
                      // }
                      onChange={onComCertChanged}


                    />
                   
               </div>
			   <div className="form-group mb-3">
			   <label
                      className="form-check-label text-black font-w500 px-3"
                      htmlFor="active"
                    >
                     <h3>Upload your Birth Certificate </h3>
                    </label>
                 
                
                  <input
               type="file"
               className="form-control"
               id="inputGroupPrepend2"
               aria-describedby="inputGroupPrepend2"
            placeholder="Upload your Birth Certificate"
            name="file"
            // value={formData.birthday}
           onChange={(imgChnage)} 
          />
         
            </div>  */}
            </form>
     </div>
	 <div className="modal-footer">
									<button type="submit" className="btn btn-secondary" onClick={handleAddFormSubmit}>Apply</button>   
									<button type="button" className="btn btn-warning" onClick={() => navigate(-1)}> <i className="flaticon-delete-1"></i> Discard</button>      
								</div>
     </div>
     </div>
        
       
           
           
         </div>
      </section>
				
		</>
	)
}



  
  export default (
	Registrar
  );
import {
    createRoom,
    getRoom,
    getRooms,
  
    updateRoom,
    updateRoomName,
    deleteRoom
} from '../../services/RoomApi';
import {
    CONFIRMED_CREATE_ROOM_ACTION,
    CONFIRMED_CREATE_ROOM_ITEM_ACTION,
    CONFIRMED_DELETE_ROOM_ACTION,
    CONFIRMED_EDIT_ROOM_ACTION,
    CONFIRMED_GET_ROOMS,
    CONFIRMED_GET_ROOM,
    ROOM_ERROR
} from './RoomType';

import {
    setAlert 
    } from './alertAction';

// // Create or update Room
// export const createRoom =
//   (formData, navigate, edit = false) =>
//   async (dispatch) => {
//     try {
//       const res = await createRoom('/profile', formData);

//       dispatch({
//         type: GET_ROOM,
//         payload: res.data
//       });

//       dispatch(
//         setAlert(edit ? 'Profile Updated' : 'Profile Created', 'success')
//       );

//       if (!edit) {
//         navigate('/dashboard');
//       }
//     } catch (err) {
//       const errors = err.response.data.errors;

//       if (errors) {
//         errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
//       }

//       dispatch({
//         type: PROFILE_ERROR,
//         payload: { msg: err.response.statusText, status: err.response.status }
//       });
//     }
//   };

// // Add Experience
// export const addRoomItem = (formData, navigate) => async (dispatch) => {
//   try {
//     const res = await api.put('/profile/experience', formData);

//     dispatch({
//       type: UPDATE_PROFILE,
//       payload: res.data
//     });

//     dispatch(setAlert('Experience Added', 'success'));

//     navigate('/dashboard');
//   } catch (err) {
//     const errors = err.response.data.errors;

//     if (errors) {
//       errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
//     }

//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };


export function deleteRoomAction(roomId, history) {
    return (dispatch, getState) => {
        deleteRoom(roomId).then((response) => {
            dispatch(confirmedDeletetRoomAction(roomId));
            history.push('/Rooms');
        });
    };
}

export function confirmedDeletetRoomAction(roomId) {
    return {
        type: CONFIRMED_DELETE_ROOM_ACTION,
        payload: roomId,
    };
}




export function createRoomAction(roomData) {
 
	return (dispatch, getState) => {
        try {
           // console.log(roomData);
            createRoom(roomData).then((response) => {


              dispatch(
                setAlert('room Created', 'success')
              );

                dispatch(confirmedCreateRoomAction(response.data));
               // const status =   edit ? 'User Updated' : 'User Created'
             //   const msg_status = {successMessage: status}
                //   dispatch({
                //       type: CREATE_STATUS,
                //       payload: msg_status
                //     });
                //navigate('/Rooms');
            }).catch(function (error) { 
                
                dispatch(
                setAlert(error.response.data.errors[0].msg, 'error') 
                );
                
                dispatch({
                    type: ROOM_ERROR,
                    payload: { msg: error.response.statusText, status: error.response.status }
                  });
            
            });
            
        } catch (err) {
            dispatch({
                type: ROOM_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
              });
              dispatch(
                setAlert(err.response.statusText, 'error')
              );

       }
       
    };
}
export function createRoomItemAction(roomData) {
 
	return (dispatch, getState) => {
        try {
        
           updateRoomName(roomData).then((response) => {


              dispatch(
                setAlert('Room Item Created', 'success')
              );

                dispatch(confirmedCreateRoomItemAction(response.data));
            });
            
        } catch (err) {
            dispatch({
                type: ROOM_ERROR,
                payload: { msg: err.response.statusText, status: err.response.status }
              });
             

        }
       
    };
}

export function getRoomsAction() {
    return (dispatch, getState) => {
        getRooms().then((response) => {
      
        
            dispatch(confirmedGetRoomsAction(response.data));
          
        });
    };
}

export function getCurrentRoomAction(roomId) {
    return (dispatch, getState) => {
        getRoom(roomId).then((response) => {

            dispatch(confirmedGetRoomAction(response.data));
          
        });
    };
}

export function confirmedCreateRoomAction(singleRoom) {
	
    return {
        type: CONFIRMED_CREATE_ROOM_ACTION,
        payload: singleRoom,
    };
}

export function confirmedCreateRoomItemAction(singleRoom) {
	
    return {
        type: CONFIRMED_CREATE_ROOM_ITEM_ACTION,
        payload: singleRoom,
    };
}

export function confirmedGetRoomsAction(rooms) {
    return {
        type: CONFIRMED_GET_ROOMS,
        payload: rooms,
    };
}

export function confirmedGetRoomAction(room) {
    return {
        type: CONFIRMED_GET_ROOM,
        payload: room,
    };
}

export function confirmedUpdateRoomAction(room) {

    return {
        type: CONFIRMED_EDIT_ROOM_ACTION,
        payload: room,
    };
}

export function updateRoomAction(room, history) {
    return (dispatch, getState) => {
        updateRoom(room).then((response) => {
            dispatch(confirmedUpdateRoomAction(response.data));
            history.push('/Rooms');
        });
			
    };
}
import React,{useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { connect, useDispatch } from 'react-redux';
import {
    loadingToggleAction,
    signupAction,
} from '../../store/actions/AuthActions';
// image
import logo from "../../images/logo-full.png";
import Spinner from '../layouts/Spinner';
const initialState = {
  name: "",
  email: "",
  phone:"",
  password: "",
  confirmPassword: "",
};
function Register(props) {
    // (props) : we provided all actions and state props delcaration.
    const [formData, setFormData] = useState(initialState);

    const { name, email, phone, password, confirmPassword } = formData;
    // destructuring of the state object.
    // const [email, setEmail] = useState('');
    // const [name, setName] = useState('');
    let errorsObj = { name: '',phone:'', email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
   // const [password, setPassword] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      // ...formData : spread operator.
  
      //this.setState({ [e.target.name]: e.target.value });
    };

    function onSignUp(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
			swal('Oops', errorObj.email, "error");
        }
       
        if (name === '') {
            errorObj.name = 'Name is Required';
            error = true;
			swal('Oops', errorObj.name, "error");
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
			swal('Oops', errorObj.password, "error");
        }
        setErrors(errorObj);
        if (error) return;
        dispatch(loadingToggleAction(true));
        dispatch(signupAction({ name, email, phone, password }, navigate));
    }
    return (
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        <Link to="/login">
                          <img src={logo} alt="" width='250'/>
                        </Link>
                      </div>
                      <h4 className="text-center font-bold mb-4 text-blue">Sign Up </h4>
                        {props.errorMessage && (
                          <div className=''>
                            {props.errorMessage}
                          </div>
                        )}
                        {props.successMessage && (
                          <div className=''>
                            {props.successMessage}
                          </div>
                        )}
                       <div className="text-center m-4 "><button className="btn btn-warning" onClick={() => navigate(-1)}>go back</button></div>
                      <form onSubmit={onSignUp}>
                        <div className="form-group mb-3">
                          <label className="mb-1 ">
                            <strong>Full Name</strong>
                          </label>
                          <input
              type="text"
              placeholder="Name"
              className="form-control"
              name="name"
              value={name}
              required
              onChange={onChange}
            />
            <small class="form-text">
              Your fullname; Surname comes first
            </small>
                        </div>
                        {errors.name && <div>{errors.name}</div>}
                        <div className="form-group mb-3">
                          <label className="mb-1 ">
                            <strong>Primary Phone</strong>
                          </label>
                          <input
              type="text"
              placeholder="Phone Number"
              name="phone"
              value={phone}
              className="form-control"
              onChange={onChange}
            />
            <small class="form-text">
              This site uses your Phone number as your Username(e.g 08020000111 )
            </small>
                        </div>
                        {errors.name && <div>{errors.name}</div>}

                        <div className="form-group mb-3">
                          <label className="mb-1 ">
                            <strong>Email</strong>
                          </label>
                          <input 
              type="email"
              placeholder="Email Address"
              name="email"
              value={email}
              className="form-control"
             
              onChange={onChange}
            />

<div className="d-block invalid-feedback">{errors.email}</div>
            <small class="form-text">
              This site uses Gravatar so if you want a profile image, use a
              Gravatar email
            </small>
                        </div>
                        {errors.email && <div>{errors.email}</div>}
                        <div className="form-group mb-3">
                          <label className="mb-1 ">
                            <strong>Password</strong>
                          </label>
                          
                          <input
              type="password"
              placeholder="Password"
              name="password"
              minLength="6"
              className="form-control"
              value={password}
              onChange={onChange}
             
            />
            <div className="d-block invalid-feedback">{errors.password}</div>
                        </div>
                        {errors.password && <div>{errors.password}</div>}
                        <div className="form-group mb-3">
                          <label className="mb-1 ">
                            <strong>Confirm Password</strong>
                          </label>
                          
                          <input
              type="password"
              placeholder="Confirm Password"
              name="confirmPassword"
              minLength="6"
              value={confirmPassword}
              onChange={onChange}
              className="form-control"
            />
            <div className="d-block invalid-feedback">
              {errors.confirmPassword}
            </div>
                        </div>
                        {errors.confirmPassword && <div>{errors.confirmPassword}</div>}
                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            className="btn btn-secondary btn-block"
                          >
                            Sign me up
                          </button>
                        </div>
                      </form>
                      <div className="new-account mt-3">
                        <p className="">
                        {props.showLoading && (
                          <div className=''>
                            <Spinner />
                          </div>
                        )}
                          Already have an account?{" "}
                          <Link className="text-warning font-bold" to="/login">
                            Sign in 
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};

export default connect(mapStateToProps)(Register);


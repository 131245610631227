

export const selectCurrentToken = (state) => state.auth.auth.profileStatus

export const isAuthenticated = (state) => {
    if (state.auth.auth.idToken) return true;
    return false;
};
export const profileStatus = (state) => {
    if (state.auth.auth.idToken) return state.auth.auth.asg;
    return false;
};
//NONE@MMN
export const isAlert = (state) => {
    if (state.alertReducer) return true;
    return false;
};

export const isProfileStatus = (state) => {
    const status = state.auth.auth.profileStatus
    if(status) {
    // console.log(`${status}`)
        if( status==='MEMBER' )return true;

    }

    return false;
};
// export const user2 = (state) => {
//     const auth = state.auth.auth
//     if(auth) {
//     // console.log(`${auth}`)
//        return auth;

//     }

//     return {};
// };

// export function setProfileStatus() {

//     let profileStatus =''
//     if(token) {
//         const decoded = jwtDecode(token.idToken)
//         const profileStatus = decoded.user.profileStatus

//     }
//     return {
//         type: SET_PROFILE_STATUS,
//         payload: 'MEMBER',
//     };
// }


